import { memo } from "react";
import "./widget.scss";
import { FaArrowTrendUp,FaArrowTrendDown } from "react-icons/fa6";
import { formatNumberWithCommas } from '../../../utils/numbers';
import {
  PeopleSvgIcon,
  EnergyConsumedSvgIcon,
  BillRevenueSvgIcon,
  AvgRevenueSvgIcon,
} from "../../../icons/svg_icons/icons_svg";

type widgetProps = {
  type: String;

  data: any;
};

const Widget = memo((props: widgetProps) => {
  const { data, type } = props;
  let content;

  // const diff = 20;
  switch (props.type) {
    case "usage":
      content = {
        title: "Energy Consumed (kWh)",
        thisMonth: data.thisMonth.toFixed(2),
        lastMonth: data.lastMonth.toFixed(2),
        isMoney: false,
        link: "",
        icon: <EnergyConsumedSvgIcon />,
      };
      break;
    case "customers":
      content = {
        title: "Customer Enrollments",
        thisMonth: data.thisMonth,
        lastMonth: data.lastMonth,
        icon: <PeopleSvgIcon />,
      };
      break;
    case "billedRevenue":
      content = {
        title: "Billed Revenue (₹)",
        thisMonth: data.thisMonth.toFixed(2),
        lastMonth: data.lastMonth.toFixed(2),
        isMoney: false,
        link: "",
        icon: <BillRevenueSvgIcon />,
      };
      break;
    case "avRevenue":
      content = {
        title: "Avg. Revenue Per Session",
        thisMonth: data.thisMonth.toFixed(2),
        lastMonth: data.lastMonth.toFixed(2),
        isMoney: true,
        link: "",
        icon: <AvgRevenueSvgIcon />,
      };
      break;
    default:
      break;
  }

  // const formatNumberWithCommas = (num: any) => {
  //   if (num === undefined || num === null) return "-";
  //   const [integer, decimal] = num.toString().split('.');
    
  //   // Format the integer part with commas according to the Indian numbering system
  //   const lastThree = integer.slice(-3);
  //   const otherNumbers = integer.slice(0, -3);
  //   const formattedInteger = otherNumbers !== '' ? otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree : lastThree;
    
  //   return decimal ? `${formattedInteger}.${decimal}` : formattedInteger;
  // };

  return (
    <div className="widget">
      <div className="title_container">
        <span
          style={{ borderRadius: "8px", background: "#E3E8FA", padding: "7px" }}
          className="icon_bg title"
        >
          {content?.icon}
        </span>
        <span className="card_heading">
          {content?.title} 
          {Math.sign(
            ((content?.thisMonth - content?.lastMonth) / content?.lastMonth) *
              100
          ) !== -1 ? (
            <div className="percentage positive mt-1">
              <FaArrowTrendUp className="mr-2" />
              {(
                ((content?.thisMonth - content?.lastMonth) /
                  content?.lastMonth) *
                100
              ).toFixed(2)}
              %
            </div>
          ) : (
            <div className="percentage negative mt-1">
              <FaArrowTrendDown className="mr-2" />
              {Math.abs(
                ((content?.thisMonth - content?.lastMonth) /
                  content?.lastMonth) *
                  100
              ).toFixed(2)}
              %
            </div>
          )}
        </span>
      </div>
      <div className="container_month">
        <div className="left_first">
          {/* <span className="counter start">{content?.thisMonth}</span> */}
          <span className="counter start">
          
            {/* {
              content?.thisMonth == 0  ? (<Skeleton variant="rectangular" width={90} height={30} />)   :  formatNumberWithCommas(content?.thisMonth)
            } */}
           {formatNumberWithCommas(content?.thisMonth)}
           
            </span>
          <span className="item ">This month</span>
        </div>
        
        <div className="right_last">
          <span className="counter end"> 
          
          {/* {
            content?.lastMonth == 0  ? (<Skeleton variant="rectangular" width={90} height={30} />)   :  formatNumberWithCommas(content?.lastMonth)
          } */}
            {formatNumberWithCommas(content?.lastMonth)}</span>
           <span className="item">Last Month</span>
        </div>
      </div>
    </div>
  );
});

export default Widget;
