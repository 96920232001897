import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  loginData: [],
};

export const LoginSlice = createSlice({
  name: "Login",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<any>) => {
      state.loginData = action.payload;
    },
  },
});

export default LoginSlice.reducer;
export const { login } = LoginSlice.actions;
