import { z } from "zod";

export const fleetOperatorSchema = z.object({
  companyName: z
    .string()
    .min(3, "Fleet Operator name must be at least 3 characters long")
    .nonempty("Fleet Operator name is required"),
  tradeName: z
    .string()
    .min(3, "Trade name must be at least 3 characters long")
    .nonempty("Trade name is required"),
  companyAddress: z
    .string()
    .min(10, "Address must be at least 10 characters long")
    .nonempty("Address is required"),
  contactName: z
    .string()
    .min(2, "Contact name must be at least 2 characters long")
    .nonempty("Contact name is required"),
  contactNumber: z
    .string()
    .regex(/^\d{10}$/, "Enter a valid 10-digit phone number"),
    fleetCost: z
    .string()
    .min(1,"Fleet cost cannot be blank") // Ensures the string is not empty
  .regex(/^\d+$/, "Please enter a valid fleet cost (only numbers are allowed)"),
  contactEmail: z.string().email("Enter a valid email"),
  companyDescription: z
    .string()
    .min(5, "Details must be at least 5 characters long")
    .nonempty("Details are required"),
  accountName: z.string().optional(),
  accountNumber: z.string().optional(),
  ifsc: z.string().optional(),
  bankName: z.string().optional(),
  upi: z.string().optional(),
  upiCode: z.string().optional(),
  isWhiteLabel: z.string().optional(),
  id: z.string().optional(),
});

export const cpoOperatorSchema = z.object({
  companyName: z
    .string()
    .min(1, "Company Name is required")
    .min(8, "Company Name must be at least 8 characters long"),
  tradeName: z
    .string()
    .min(1, "Trade Name is required")
    .min(5, "Trade Name must be at least 5 characters long"),
  companyAddress: z
    .string()
    .min(1, "Address is required")
    .min(10, "Address must be at least 10 characters long"),
  contactName: z
    .string()
    .min(1, "Contact name is required")
    .min(5, "Contact name must be at least 5 characters long"),
  contactNumber: z
    .string()
    .min(1, "Contact number is required.")
    .regex(/^\d{10}$/, "Enter a valid 10-digit phone number"),
  contactEmail: z
    .string()
    .min(1, "Email is required")
    .email("Enter a valid email"),
  companyDescription: z
    .string()
    .min(1, "Details are required")
    .min(5, "Details must be at least 5 characters long"),
  accountName: z.string().optional(),
  accountNumber: z.string().optional(),
  ifsc: z.string().optional(),
  bankName: z.string().optional(),
  upi: z.string().optional(),
  upiCode: z.string().optional(),
  isWhiteLabel: z.string().optional(),
  id: z.string().optional(),
});

export const CarAddEditSchema = z.object({
  name: z
    .string()
    .min(3, "Model name must be at least 3 characters long")
    .nonempty("Model name is required"),

  Manufacturer: z
    .string()
    .min(2, "Manufacturer name must be at least 2 characters long")
    .nonempty("Manufacturer is required"),

  chargeRate: z.number().positive("Charging rate must be a positive number"),

  range: z.number().positive("Range must be a positive number"),

  batteryCapacity: z
    .number()
    .positive("Battery capacity must be a positive number")
    .optional(),
});

export const InvoiceSchema = z.object({
  LocationName: z.string(),
  userName: z.string(),
});

export const AddLocationSchema = z.object({
  id: z.string().optional(),
  stationName: z
    .string()
    .min(1, "Station Name is required")
    .min(5, "Station Name must be at least 5 characters long"),
  address: z
    .string()
    .min(1, "Address is required")
    .min(5, "Address must be at least 5 characters long"),
  description: z
    .string()
    .min(1, "Description is required")
    .min(5, "Description must be at least 5 characters long")
    .max(500, "Description must be 500 characters long"),
  amenities: z.array(z.string()),
  // connectorType: z.array(z.string()),
  city: z.string().min(1, "City is required"),
  state: z.string().min(1, " Please select State"),
  // country: z.string().min(2).max(50),
  // type: z.string(),
  lat: z
    .string()
    .min(1, "Latitude is required")
    .min(4, "Latitude must be at least 4 characters long")
    .optional(),
  long: z
    .string()
    .min(1, "Longitude is required")
    .min(4, "Longitude must be at least 4 characters long")
    .optional(),
  openingTime: z.string().min(1, "Opening Time is required").optional(),
  closingTime: z.string().min(1, "Closing Time is required").optional(),
  // daysOpen: z.string(),
  // chargingTariff: z.string().optional(),
  electricityTariff: z
    .string()
    .min(1, "Electricity Tariff is required")
    .optional(),
  // currency: z.string().min(1).max(3),
  // tax: z.string().optional(),
  access: z
    .string()
    .min(1, "Please select Access Type")
    .min(4, "Access must be selected")
    .optional(),
  // authType: z.string().optional(),
  // paymentReq: z.string().optional(),
  modelType: z.string().min(1, "Please select revenue model type").optional(),
  // isEnabled: z.boolean().optional(),
  // revenueAmount: z.string().optional(),
  // revenuePercent: z.string().optional(),
  // rentalAmount: z.string().optional(),
});

// Add charger Validation Schema
export const AddChargerSchema = z.object({
  cpo: z
    .object({
      id: z.string().nonempty("Please select the CPO"),
      name: z.string().nonempty("Please select the CPO"),
    })
    .optional(),
  serial: z
    .string()
    .min(1, "Serial Number is required")
    .min(5, "Serial Number must be at least 5 characters long"),
  vendor: z
    .object({
      id: z.string().nonempty("Please select the Vendor"),
      name: z.string().nonempty("Please select the Vendor"),
    })
    .optional(),
  power: z.string().min(1, "Please select Max Power"),
  connector: z
    .object({
      id: z.string().nonempty("Please select the connector"),
      name: z.string().nonempty("Please select the connector"),
      // type: z.string().nonempty("Please select the connector"),
    })
    .optional(),
  tariff: z.string().min(1, "Charging tariff is required"),
  taxRate: z.string().min(1, "Tax Rate is required"),
  chargerId: z.string().min(1, "Charger ID is required"),
});
