import React, { useEffect, useState } from "react";
import { z } from "zod";
import PropTypes from "prop-types";
import {
  FormControl,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Stack,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import { fleetOperatorSchema } from "../../../ValidationSchema/ValidationSchema";

interface PropTypes {
  show: boolean;
  handleClose: any;
  type: string;
  data: any;
  refresh: any;
}
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const upiArray = [
  "rbl",
  "idbi",
  "upi",
  "aubank",
  "axisbank",
  "bandhan",
  "dlb",
  "indus",
  "kbl",
  "federal",
  "sbi",
  "uco",
  "yesbank",
  "citi",
  "dlb",
  "dbs",
  "okicici",
  "okhdfcbank ",
  "hsbc",
  "idbi",
  "allbank",
  "indianbank",
  "kotak",
];

const allBankArray = [
  " State Bank of India",
  "Axis Bank",
  " HDFC Bank",
  "ICICI Bank",
  "Bank of Baroda",
  "Bank of Maharashtra",
  "Bandhan Bank",
  "Canara Bank",
  "Central Bank of India",
  " Citi Bank",
  "City Union Bank",
  "Cosmos Co-op Bank",
  "Dhanalaxmi Bank",
  " DBS Bank India Ltd",
  "Deutsche Bank AG",
  " Equitas Small Finance Bank",
  "Federal Bank",
  "Hongkong and Shanghai Banking Corporation (HSBC)",
  " IDBI Bank",
  "IDFC First Bank",
  "Indian Bank",
  "Indusind Bank",
  "Jana Small Finance Bank",
  " Jio Payments Bank",
  "Karnataka Bank Ltd",
  "Karur Vysya Bank",
  " Kotak Mahindra Bank",
  " Oriental Bank of Commerce",
  " Paytm Payments Bank",
  "Punjab National Bank",
  " RBL Bank",
  "Standard Charted Bank",
  "South Indian Bank",
  "Syndicate Bank",
  "Tamilnadu Mercantile Bank",
  "Ujjivan Small Finance Bank",
  " Union Bank of India",
  "Yes Bank",
  "Karnataka Vikas Grameena Bank",
  "DCB Bank Ltd",
  " Andhra Pragathi Grameena Bank",
  "The Varachha Co-op Bank Ltd",
  "  AU Small Finance Bank",
  " The Catholic Syrian Bank",
  " Ujjivan Small Finance Bank Ltd",
  "Airtel Payments Bank Ltd",
  " ESAF Small Finance Bank Ltd",
  " NSDL Payments Bank",
  " Suryoday Small Finance Bank Ltd",
  "Punjab & Sind Bank",
];

type Values = {
  companyName: string;
  tradeName: string;
  companyAddress: string;
  contactName: string;
  contactNumber: string;
  contactEmail: string;
  fleetCost:string;
  companyDescription: string;
  upi: string;
  accountNumber: string;
  ifsc: string;
  bankName: string;
};

interface FormErrors {
  companyName?: string;
  tradeName?: string;
  companyAddress?: string;
  contactName?: string;
  contactNumber?: string;
  contactEmail?: string;
  companyDescription?: string;
  upi?: string;
  accountNumber?: string;
  ifsc?: string;
  bankName?: string;
  fleetCost?: string;
}
function AddEditModal(props: PropTypes) {
  const initialValues = {
    id: "",
    companyName: "",
    tradeName: "",
    companyAddress: "",
    contactName: "",
    contactNumber: "",
    contactEmail: "",
    companyDescription: "",
    isWhiteLabel: "",
    fleetCost:"",
    upiCode: "",
    accountName: "",
    accountNumber: "",
    bankName: "",
    ifsc: "",
    upi: "",
  };
  const [showBillingInfo, setShowBillingInfo] = useState(false);
  const [values, setValues] = useState(initialValues);
  useEffect(() => {
    if (!props.show) return;
    setValues(props.data);
  }, [props.show]);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  function isValid_Bank_Acc_Number(bank_account_number: any) {
    // Regex to check valid
    // BANK ACCOUNT NUMBER CODE
    let regex = new RegExp(/^[0-9]{9,18}$/);

    // bank_account_number CODE
    // is empty return false
    if (bank_account_number == null) {
      return "false";
    }

    // Return true if the bank_account_number
    // matched the ReGex
    if (regex.test(bank_account_number) == true) {
      return "true";
    } else {
      alert("Enter Valid Acc Number");
      return "false";
    }
  }

  function isValid_IFSC_Code(ifsc_Code: any) {
    // Regex to check valid
    // ifsc_Code
    let regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);

    // if ifsc_Code
    // is empty return false
    if (ifsc_Code == null) {
      return "false";
    }

    // Return true if the ifsc_Code
    // matched the ReGex
    if (regex.test(ifsc_Code) == true) {
      return "true";
    } else {
      alert("Please Enter Valid IFSC Code");
      return "false";
    }
  }

  const [errors, setErrors] = useState<FormErrors>({});

  const validateField = (name: keyof Values, value: string): string => {
    const partialSchema = z.object({
      [name]: fleetOperatorSchema.shape[name],
    });
    try {
      partialSchema.parse({ [name]: value });
      return "";
    } catch (e) {
      if (e instanceof z.ZodError) {
        return e.errors[0]?.message || "Invalid input";
      }
      return "Invalid input";
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const error = validateField(name as keyof Values, value);
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const validateForm = (): boolean => {
    let valid = true;
    const newErrors: FormErrors = {};
    for (const key in values) {
      const field = key as keyof Values;
      const error = validateField(field, values[field]);
      if (error) valid = false;
      newErrors[field] = error;
    }
    setErrors(newErrors);

    return valid;
  };
  console.log("Errors", errors);
  console.log("Value of FleetCost",values?.fleetCost);
  
  const logUser = Parse.User.current();
  const loggedCPO = logUser?.get("CPO")?.id;
  console.log("Parse.User.current()", loggedCPO);

  const addFleetOperator = () => {
    if (validateForm()) {
      let myNewObject = new Parse.Object("FleetOperator");

      myNewObject.set("CompanyName", values.companyName);
      myNewObject.set("Name", values.tradeName);
      myNewObject.set("Address", values.companyAddress);
      myNewObject.set("ContactName", values.contactName);
      myNewObject.set("FleetCost", values.fleetCost);
      myNewObject.set("Phone", values.contactNumber);
      myNewObject.set("Email", values.contactEmail);
      myNewObject.set("Description", values.companyDescription);
      myNewObject.set("AccountNumber", parseFloat(values.accountNumber));
      myNewObject.set("IFSC", values.ifsc);
      myNewObject.set("BankName", values.bankName);
      myNewObject.set("UPI", values.upi);
      myNewObject.set("UPICode", values.upiCode);
      myNewObject.set(
        "isWhiteLabel",
        values.isWhiteLabel === "true" ? true : false
      );
      myNewObject.set("Admin", logUser);
      myNewObject.set("CPO", {
        __type: "Pointer",
        className: "ChargePointOperators",
        objectId: loggedCPO,
      });
      // myNewObject.set("CPO", loggedCPO);
      myNewObject.save().then((res) => {
        alert("Fleet Operator added successfully");
        props.refresh();
        props.handleClose();
      });
    } else {
      alert("Not working");
    }
  };

  console.log("If (e)=> Add Days . to()Change-mode() # User need to ");

  const editFleetOperator = () => {
    if (
      values.companyName &&
      values.companyAddress &&
      values.contactName &&
      values.contactNumber &&
      values.contactEmail &&
      (values.ifsc === "" || isValid_IFSC_Code(values.ifsc) === "true") &&
      (values.accountNumber === "" ||
        isValid_Bank_Acc_Number(values.accountNumber) === "true")
    ) {
      let myNewObject: Parse.Object = new Parse.Object("FleetOperator");
      myNewObject.set("objectId", values.id);
      myNewObject.set("CompanyName", values.companyName);
      myNewObject.set("Name", values.tradeName);
      myNewObject.set("Address", values.companyAddress);
      myNewObject.set("ContactName", values.contactName);
      myNewObject.set("FleetCost", values.fleetCost);
      myNewObject.set("Phone", values.contactNumber);
      myNewObject.set("Email", values.contactEmail);
      myNewObject.set("Description", values.companyDescription);
      myNewObject.set("AccountNumber", parseFloat(values.accountNumber));
      myNewObject.set("IFSC", values.ifsc);
      myNewObject.set("BankName", values.bankName);
      myNewObject.set("UPI", values.upi);
      myNewObject.set("UPICode", values.upiCode);
      myNewObject.set(
        "isWhiteLabel",
        values.isWhiteLabel === "true" ? true : false
      );
      myNewObject.save().then((res) => {
        alert("Fleet Edited successfully");
        props.refresh();
        props.handleClose();
      });
    } else {
      alert("Please Enter All Mandatory Details");
    }
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: 900,
          height: 700,
        },
      }}
      maxWidth={"lg"}
      fullScreen={fullScreen}
      open={props.show}
      onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <div className="headItem">
          <span className="head1">
            {" "}
            {props.type == "add" ? (
              <span className="font-lexend font-bold">Add Fleet</span>
            ) : (
              <span className="font-lexend font-bold">Edit Fleet</span>
            )}
          </span>{" "}
          <span className="head2" onClick={props.handleClose}>
            <CloseIcon />
          </span>
        </div>
      </DialogTitle>

      <DialogContent>
        <Stack direction="column" spacing={1}>
          <div className="flex justify-between mb-4">
            <div
              style={{ display: "flex", flexDirection: "column", width: "48%" }}
            >
              <label
                className="flex items-center mb-2 mt-5"
                htmlFor="station name"
              >
                <span>
                  <svg
                    className="mr-2"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.75 14.75V9.5L5 5.75L1.25 9.5V14.75H5M8.75 14.75H5M8.75 14.75H14.75V2C14.75 1.80109 14.671 1.61032 14.5303 1.46967C14.3897 1.32902 14.1989 1.25 14 1.25H6.5C6.30109 1.25 6.11032 1.32902 5.96967 1.46967C5.82902 1.61032 5.75 1.80109 5.75 2V6.5M5 14.75V11.75M8.75 4.25V4.2575M11.75 4.25V4.2575M11.75 7.25V7.2575M11.75 10.25V10.2575"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>{" "}
                <span className="font-lexend">
                  Fleet Name <span className="text-red-500 text-l">*</span>{" "}
                </span>
              </label>
              <TextField
                id="input-with-sx"
                variant="standard"
                placeholder="Enter Name"
                value={values.companyName}
                name="companyName"
                onChange={handleInputChange}
                error={!!errors.companyName}
                helperText={errors.companyName}
              />
            </div>
            {/* Trade Name Form Code */}
            <div
              style={{ display: "flex", flexDirection: "column", width: "48%" }}
            >
              <label
                className="flex items-center mb-2 mt-5"
                htmlFor="station name"
              >
                <span>
                  <svg
                    className="mr-2"
                    width="12"
                    height="16"
                    viewBox="0 0 12 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.75 4.25H8.25M3.75 7.25H8.25M3.75 10.25H6.75M0.75 2.75C0.75 2.35218 0.908035 1.97064 1.18934 1.68934C1.47064 1.40804 1.85218 1.25 2.25 1.25H9.75C10.1478 1.25 10.5294 1.40804 10.8107 1.68934C11.092 1.97064 11.25 2.35218 11.25 2.75V13.25C11.25 13.6478 11.092 14.0294 10.8107 14.3107C10.5294 14.592 10.1478 14.75 9.75 14.75H2.25C1.85218 14.75 1.47064 14.592 1.18934 14.3107C0.908035 14.0294 0.75 13.6478 0.75 13.25V2.75Z"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>{" "}
                <span className="font-lexend">
                  Trade Name <span className="text-red-500 text-l">*</span>
                </span>
              </label>
              <TextField
                id="input-with-sx"
                variant="standard"
                placeholder="Enter Trade Name"
                value={values.tradeName}
                name="tradeName"
                onChange={handleInputChange}
                error={!!errors.tradeName}
                helperText={errors.tradeName}
              />
            </div>
          </div>
          {/* Company Name Form Code */}

          <h2 className="font-lexend font-semibold text-gray-400 text-xl mt-8 pt-4">
            Basic Contact Details
          </h2>
          <div className="flex justify-between">
            {/* Contact Name Form Code */}
            <div
              style={{ display: "flex", flexDirection: "column", width: "48%" }}
            >
              <label className="flex items-center mb-2 " htmlFor="station name">
                <span>
                  <svg
                    className="mr-2"
                    width="15"
                    height="17"
                    viewBox="0 0 15 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 14.75V13.25C1.5 12.4544 1.81607 11.6913 2.37868 11.1287C2.94129 10.5661 3.70435 10.25 4.5 10.25H7.125M3 4.25C3 5.04565 3.31607 5.80871 3.87868 6.37132C4.44129 6.93393 5.20435 7.25 6 7.25C6.79565 7.25 7.55871 6.93393 8.12132 6.37132C8.68393 5.80871 9 5.04565 9 4.25C9 3.45435 8.68393 2.69129 8.12132 2.12868C7.55871 1.56607 6.79565 1.25 6 1.25C5.20435 1.25 4.44129 1.56607 3.87868 2.12868C3.31607 2.69129 3 3.45435 3 4.25ZM10.815 10.7075C10.9613 10.5612 11.1349 10.4452 11.326 10.3661C11.5171 10.2869 11.7219 10.2462 11.9288 10.2462C12.1356 10.2462 12.3404 10.2869 12.5315 10.3661C12.7226 10.4452 12.8962 10.5612 13.0425 10.7075C13.1888 10.8537 13.3048 11.0274 13.3839 11.2185C13.4631 11.4096 13.5038 11.6144 13.5038 11.8212C13.5038 12.0281 13.4631 12.2329 13.3839 12.424C13.3048 12.6151 13.1888 12.7887 13.0425 12.935L10.5 15.5H8.25V13.25L10.815 10.7075Z"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>{" "}
                <span className="font-lexend">
                  Contact Name <span className="text-red-500 text-l">*</span>
                </span>
              </label>
              <TextField
                id="input-with-sx"
                variant="standard"
                placeholder="Enter Contact Name"
                value={values.contactName}
                name="contactName"
                onChange={handleInputChange}
                error={!!errors.contactName}
                helperText={errors.contactName}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "48%",
              }}
            >
              <label className="flex items-center mb-2 " htmlFor="station name">
                <span>
                  <svg
                    className="mr-2"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.75 1H5.75L7.25 4.75L5.375 5.875C6.17822 7.50365 7.49635 8.82178 9.125 9.625L10.25 7.75L14 9.25V12.25C14 12.6478 13.842 13.0294 13.5607 13.3107C13.2794 13.592 12.8978 13.75 12.5 13.75C9.57445 13.5722 6.81512 12.3299 4.74262 10.2574C2.67013 8.18489 1.42779 5.42555 1.25 2.5C1.25 2.10218 1.40804 1.72064 1.68934 1.43934C1.97064 1.15804 2.35218 1 2.75 1Z"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>{" "}
                <span className="font-lexend">
                  Contact Number <span className="text-red-500 text-l">*</span>
                </span>
              </label>
              <TextField
                id="input-with-sx"
                variant="standard"
                placeholder="Enter Contact Number"
                value={values.contactNumber}
                name="contactNumber"
                onChange={handleInputChange}
                error={!!errors.contactNumber}
                helperText={errors.contactNumber}
              />
            </div>
          </div>

          {/* Contact Number and Email */}
          <div className="flex justify-between">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "48%",
                marginTop: "9px",
              }}
            >
              <label className="flex items-center mb-2" htmlFor="station name">
                <span>
                  <svg
                    className="mr-2"
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.25 2.25C1.25 1.85218 1.40804 1.47064 1.68934 1.18934C1.97064 0.908035 2.35218 0.75 2.75 0.75H13.25C13.6478 0.75 14.0294 0.908035 14.3107 1.18934C14.592 1.47064 14.75 1.85218 14.75 2.25M1.25 2.25V9.75C1.25 10.1478 1.40804 10.5294 1.68934 10.8107C1.97064 11.092 2.35218 11.25 2.75 11.25H13.25C13.6478 11.25 14.0294 11.092 14.3107 10.8107C14.592 10.5294 14.75 10.1478 14.75 9.75V2.25M1.25 2.25L8 6.75L14.75 2.25"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>{" "}
                <span className="font-lexend">
                  Contact Email <span className="text-red-500 text-l">*</span>
                </span>
              </label>
              <TextField
                id="input-with-sx"
                variant="standard"
                placeholder="Enter Contact Email"
                value={values.contactEmail}
                name="contactEmail"
                onChange={handleInputChange}
                error={!!errors.contactEmail}
                helperText={errors.contactEmail}
              />
            </div>

            {/* Address Form Code */}
            <div
              style={{ display: "flex", flexDirection: "column", width: "48%" }}
            >
              <label
                className="flex items-center mb-2 mt-2"
                htmlFor="station name"
              >
                <span>
                  <svg
                    className="mr-2"
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.75 7.24978C4.75 7.84652 4.98705 8.41882 5.40901 8.84077C5.83097 9.26273 6.40326 9.49978 7 9.49978C7.59674 9.49978 8.16903 9.26273 8.59099 8.84077C9.01295 8.41882 9.25 7.84652 9.25 7.24978C9.25 6.65305 9.01295 6.08075 8.59099 5.65879C8.16903 5.23684 7.59674 4.99978 7 4.99978C6.40326 4.99978 5.83097 5.23684 5.40901 5.65879C4.98705 6.08075 4.75 6.65305 4.75 7.24978Z"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.2428 11.4925L8.0605 14.6748C7.77924 14.9558 7.39794 15.1136 7.00038 15.1136C6.60282 15.1136 6.22151 14.9558 5.94025 14.6748L2.75725 11.4925C1.91817 10.6534 1.34675 9.58431 1.11527 8.42043C0.883782 7.25655 1.00262 6.05017 1.45676 4.95383C1.91089 3.85749 2.67993 2.92044 3.66661 2.26116C4.6533 1.60189 5.81333 1.25 7 1.25C8.18668 1.25 9.3467 1.60189 10.3334 2.26116C11.3201 2.92044 12.0891 3.85749 12.5433 4.95383C12.9974 6.05017 13.1162 7.25655 12.8847 8.42043C12.6533 9.58431 12.0818 10.6534 11.2428 11.4925Z"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>{" "}
                <span className="font-lexend">
                  Address <span className="text-red-500 text-l">*</span>
                </span>
              </label>
              <TextField
                id="input-with-sx"
                variant="standard"
                placeholder="Enter Address"
                value={values.companyAddress}
                name="companyAddress"
                onChange={handleInputChange}
                error={!!errors.companyAddress}
                helperText={errors.companyAddress}
              />
            </div>
          </div>

          <div className="flex justify-between">
 {/* Description */}


          <FormControl  style={{ width: "48%" }}>
            <label
              className="flex items-center mb-2 mt-1"
              htmlFor="station name"
            >
              <span>
                <svg
                  className="mr-2"
                  width="16"
                  height="15"
                  viewBox="0 0 16 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 4.75H11M5 7.75H9.5M5.75 11.5H3.5C2.90326 11.5 2.33097 11.2629 1.90901 10.841C1.48705 10.419 1.25 9.84674 1.25 9.25V3.25C1.25 2.65326 1.48705 2.08097 1.90901 1.65901C2.33097 1.23705 2.90326 1 3.5 1H12.5C13.0967 1 13.669 1.23705 14.091 1.65901C14.5129 2.08097 14.75 2.65326 14.75 3.25V9.25C14.75 9.84674 14.5129 10.419 14.091 10.841C13.669 11.2629 13.0967 11.5 12.5 11.5H10.25L8 13.75L5.75 11.5Z"
                    stroke="#111111"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>{" "}
              <span className="font-lexend">
                Details <span className="text-red-500 text-l">*</span>
              </span>
            </label>

            <TextField
              variant="standard"
              sx={{ width: "100%", marginTop: "8px" }}
              placeholder="Enter Details..."
              multiline
              value={values.companyDescription}
              name="companyDescription"
              onChange={handleInputChange}
              error={!!errors.companyDescription}
              helperText={errors.companyDescription}
              minRows={1}
              size="small"
            />
          </FormControl>
         
       
          {/* Fleet Cost */}
          <div
              style={{ display: "flex", flexDirection: "column", width: "48%" }}
            >
              <label
                className="flex items-center mb-2 mt-2"
                htmlFor="station name"
              >
                <span>
              
<svg className="mr-2" width="17" height="17" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 6H7H8C8.79565 6 9.55871 6.31607 10.1213 6.87868C10.6839 7.44129 11 8.20435 11 9C11 9.79565 10.6839 10.5587 10.1213 11.1213C9.55871 11.6839 8.79565 12 8 12H7L10 15M7 9H13M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 8.8181 18.7672 7.64778 18.3149 6.55585C17.8626 5.46392 17.1997 4.47177 16.364 3.63604C15.5282 2.80031 14.5361 2.13738 13.4442 1.68508C12.3522 1.23279 11.1819 1 10 1C8.8181 1 7.64778 1.23279 6.55585 1.68508C5.46392 2.13738 4.47177 2.80031 3.63604 3.63604C2.80031 4.47177 2.13738 5.46392 1.68508 6.55585C1.23279 7.64778 1 8.8181 1 10Z" stroke="#354052" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                </span>{" "}
                <span className="font-lexend">
                  Fleet Charging Tariff <span className="text-red-500 text-l">*</span>
                </span>
              </label>
              <TextField
                id="input-with-sx"
                variant="standard"
                placeholder="Enter Fleet Cost"
                value={values.fleetCost}
                name="fleetCost"
                onChange={handleInputChange}
                error={!!errors.fleetCost}
                helperText={errors.fleetCost}
              />
            </div>
          </div>
         

          {/* <div className="pt-5 pb-5">
          <label className="flex items-center mb-2" htmlFor="station name">
            <span>
              <svg
                className="mr-2"
                width="16"
                height="15"
                viewBox="0 0 16 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 4.75H11M5 7.75H9.5M5.75 11.5H3.5C2.90326 11.5 2.33097 11.2629 1.90901 10.841C1.48705 10.419 1.25 9.84674 1.25 9.25V3.25C1.25 2.65326 1.48705 2.08097 1.90901 1.65901C2.33097 1.23705 2.90326 1 3.5 1H12.5C13.0967 1 13.669 1.23705 14.091 1.65901C14.5129 2.08097 14.75 2.65326 14.75 3.25V9.25C14.75 9.84674 14.5129 10.419 14.091 10.841C13.669 11.2629 13.0967 11.5 12.5 11.5H10.25L8 13.75L5.75 11.5Z"
                  stroke="#111111"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>{" "}
            <span className="font-lexend">White Labeled</span>
          </label>
          <div className="flex">
            <div className="flex mt-1 mr-5">
              <input
                type="radio"
                style={{
                  background: "black",
                  width: "20px",
                  height: "20px",
                }}
                checked={values.isWhiteLabel === "true"}
                onChange={() =>
                  setValues({ ...values, isWhiteLabel: "true" })
                }
              />
              <label htmlFor="" className="font-lexend ml-2">
                True
              </label>
            </div>
            <div className="flex mt-1">
              <input
                type="radio"
                style={{
                  background: "black",
                  width: "20px",
                  height: "20px",
                }}
                checked={values.isWhiteLabel === "false"}
                onChange={() =>
                  setValues({ ...values, isWhiteLabel: "false" })
                }
              />
              <label htmlFor="" className="font-lexend ml-2">
                False
              </label>
            </div>
          </div>
        </div> */}

          {showBillingInfo ? (
            <>
              <h1 className="font-lexend font-semibold text-gray-400 text-xl mt-8 pt-4">
                Bank Details
              </h1>
              <div className="flex justify-between">
                <div className="flex" style={{ width: "48%" }}>
                  <div>
                    <label
                      className="flex items-center mb-2"
                      htmlFor="station name"
                    >
                      <span>
                        <svg
                          className="mr-2"
                          width="10"
                          height="15"
                          viewBox="0 0 10 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.42293 11.4577V8.29102L4.76908 10.666L1.11523 8.29102V11.4577L4.76908 13.8327L8.42293 11.4577Z"
                            stroke="#111111"
                            stroke-width="1.58333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.42293 4.33268V1.16602L4.76908 3.54102L1.11523 1.16602V4.33268L4.76908 6.70768L8.42293 4.33268Z"
                            stroke="#111111"
                            stroke-width="1.58333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>{" "}
                      <span className="font-lexend">UPI</span>
                    </label>
                    <TextField
                      sx={{ height: 48, marginTop: "20px" }}
                      id="input-with-sx"
                      variant="standard"
                      placeholder="Enter UPI ID"
                      value={values.upi}
                      name="upi"
                      onChange={handleInputChange}
                      error={!!errors.upi}
                      helperText={errors.upi}
                    />
                  </div>
                  <span className="mt-14 ml-4 border-b-2 border-black h-7">
                    @
                  </span>
                  <FormControl variant="standard" sx={{ minWidth: 120 }}>
                    <Select
                      sx={{ height: 36, marginTop: 6, width: 180 }}
                      inputProps={{ "aria-label": "Without label" }}
                      value={values.upiCode}
                    >
                      <MenuItem disabled value="">
                        <em>@</em>
                      </MenuItem>
                      {upiArray.map((item, idx) => (
                        <MenuItem
                          key={idx}
                          value={item}
                          onClick={() =>
                            setValues({
                              ...values,
                              upiCode: item,
                            })
                          }
                        >
                          <span className="font-lexend ml-2">{item}</span>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <FormControl variant="standard" sx={{ width: "48%" }}>
                  <label
                    className="flex items-center mb-2"
                    htmlFor="station name"
                  >
                    <span>
                      <svg
                        className="mr-2"
                        width="15"
                        height="17"
                        viewBox="0 0 15 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.5 14.75V13.25C1.5 12.4544 1.81607 11.6913 2.37868 11.1287C2.94129 10.5661 3.70435 10.25 4.5 10.25H7.125M3 4.25C3 5.04565 3.31607 5.80871 3.87868 6.37132C4.44129 6.93393 5.20435 7.25 6 7.25C6.79565 7.25 7.55871 6.93393 8.12132 6.37132C8.68393 5.80871 9 5.04565 9 4.25C9 3.45435 8.68393 2.69129 8.12132 2.12868C7.55871 1.56607 6.79565 1.25 6 1.25C5.20435 1.25 4.44129 1.56607 3.87868 2.12868C3.31607 2.69129 3 3.45435 3 4.25ZM10.815 10.7075C10.9613 10.5612 11.1349 10.4452 11.326 10.3661C11.5171 10.2869 11.7219 10.2462 11.9288 10.2462C12.1356 10.2462 12.3404 10.2869 12.5315 10.3661C12.7226 10.4452 12.8962 10.5612 13.0425 10.7075C13.1888 10.8537 13.3048 11.0274 13.3839 11.2185C13.4631 11.4096 13.5038 11.6144 13.5038 11.8212C13.5038 12.0281 13.4631 12.2329 13.3839 12.424C13.3048 12.6151 13.1888 12.7887 13.0425 12.935L10.5 15.5H8.25V13.25L10.815 10.7075Z"
                          stroke="#111111"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>{" "}
                    <span className="font-lexend"> Account Number</span>
                  </label>

                  <TextField
                    id="input-with-sx"
                    variant="standard"
                    placeholder="Enter Bank Account Number"
                    type="number"
                    sx={{
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                      height: 48,
                      marginTop: "20px",
                    }}
                    name="accountNumber"
                    value={values.accountNumber}
                    onChange={handleInputChange}
                    error={!!errors.accountNumber}
                    helperText={errors.accountNumber}
                  />
                </FormControl>
              </div>

              <div className="flex justify-between">
                <FormControl
                  variant="standard"
                  sx={{ width: "48%", marginTop: "5px" }}
                >
                  <label
                    className="flex items-center mb-2"
                    htmlFor="station name"
                  >
                    <span>
                      <svg
                        className="mr-2"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.25 14.75H14.75M1.25 6.5H14.75M2.75 3.5L8 1.25L13.25 3.5M2 6.5V14.75M14 6.5V14.75M5 9.5V11.75M8 9.5V11.75M11 9.5V11.75"
                          stroke="#111111"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>{" "}
                    <span className="font-lexend">Bank Name</span>
                  </label>
                  <Select
                    inputProps={{ "aria-label": "Without label" }}
                    value={values.bankName}
                    error={!!errors.bankName}
                  >
                    <FormHelperText>{errors.bankName}</FormHelperText>
                    <MenuItem value="">
                      <em>State</em>
                    </MenuItem>
                    {allBankArray.map((item, idx) => (
                      <MenuItem
                        key={idx}
                        value={item}
                        onClick={() =>
                          setValues({
                            ...values,
                            bankName: item,
                          })
                        }
                      >
                        <span className="font-lexend">{item}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl variant="standard" sx={{ width: "48%" }}>
                  <label
                    className="flex items-center mb-2"
                    htmlFor="station name"
                  >
                    <span>
                      <svg
                        className="mr-2"
                        width="12"
                        height="16"
                        viewBox="0 0 12 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.75 2.75H2.25C1.85218 2.75 1.47064 2.90804 1.18934 3.18934C0.908035 3.47064 0.75 3.85218 0.75 4.25V13.25C0.75 13.6478 0.908035 14.0294 1.18934 14.3107C1.47064 14.592 1.85218 14.75 2.25 14.75H9.75C10.1478 14.75 10.5294 14.592 10.8107 14.3107C11.092 14.0294 11.25 13.6478 11.25 13.25V4.25C11.25 3.85218 11.092 3.47064 10.8107 3.18934C10.5294 2.90804 10.1478 2.75 9.75 2.75H8.25M3.75 2.75C3.75 2.35218 3.90804 1.97064 4.18934 1.68934C4.47064 1.40804 4.85218 1.25 5.25 1.25H6.75C7.14783 1.25 7.52936 1.40804 7.81066 1.68934C8.09197 1.97064 8.25 2.35218 8.25 2.75M3.75 2.75C3.75 3.14782 3.90804 3.52936 4.18934 3.81066C4.47064 4.09197 4.85218 4.25 5.25 4.25H6.75C7.14783 4.25 7.52936 4.09197 7.81066 3.81066C8.09197 3.52936 8.25 3.14782 8.25 2.75M3.75 8H8.25M3.75 11H8.25"
                          stroke="#111111"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>{" "}
                    <span className="font-lexend"> IFSC</span>
                  </label>

                  <TextField
                    sx={{ height: 48, marginTop: "20px" }}
                    id="input-with-sx"
                    variant="standard"
                    placeholder="Enter Your IFSC Code"
                    name="ifsc"
                    value={values.ifsc}
                    onChange={handleInputChange}
                    error={!!errors.ifsc}
                    helperText={errors.ifsc}
                  />
                </FormControl>
              </div>
            </>
          ) : (
            <></>
          )}
        </Stack>
      </DialogContent>

      <div className="flex justify-between items-center mx-4 my-4">
        <div className={"flex items-center"}>
          {showBillingInfo ? (
            <svg
              className="mr-2"
              width="16"
              height="16"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 10H13M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 8.8181 18.7672 7.64778 18.3149 6.55585C17.8626 5.46392 17.1997 4.47177 16.364 3.63604C15.5282 2.80031 14.5361 2.13738 13.4442 1.68508C12.3522 1.23279 11.1819 1 10 1C8.8181 1 7.64778 1.23279 6.55585 1.68508C5.46392 2.13738 4.47177 2.80031 3.63604 3.63604C2.80031 4.47177 2.13738 5.46392 1.68508 6.55585C1.23279 7.64778 1 8.8181 1 10Z"
                stroke="#C41A1A"
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ) : (
            <svg
              className="mr-2"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.75 8H10.25M8 5.75V10.25M1.25 8C1.25 8.88642 1.42459 9.76417 1.76381 10.5831C2.10303 11.4021 2.60023 12.1462 3.22703 12.773C3.85382 13.3998 4.59794 13.897 5.41689 14.2362C6.23583 14.5754 7.11358 14.75 8 14.75C8.88642 14.75 9.76417 14.5754 10.5831 14.2362C11.4021 13.897 12.1462 13.3998 12.773 12.773C13.3998 12.1462 13.897 11.4021 14.2362 10.5831C14.5754 9.76417 14.75 8.88642 14.75 8C14.75 7.11358 14.5754 6.23583 14.2362 5.41689C13.897 4.59794 13.3998 3.85382 12.773 3.22703C12.1462 2.60023 11.4021 2.10303 10.5831 1.76381C9.76417 1.42459 8.88642 1.25 8 1.25C7.11358 1.25 6.23583 1.42459 5.41689 1.76381C4.59794 2.10303 3.85382 2.60023 3.22703 3.22703C2.60023 3.85382 2.10303 4.59794 1.76381 5.41689C1.42459 6.23583 1.25 7.11358 1.25 8Z"
                stroke="#1AAD70"
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}

          <h4
            className="font-lexend"
            style={{ cursor: "pointer", color: "#1AAD70" }}
            onClick={() => setShowBillingInfo(!showBillingInfo)}
          >
            {showBillingInfo ? (
              <span style={{ color: "#C41A1A" }} className="font-lexend">
                Skip billing info
              </span>
            ) : (
              "Add Billing info for Payouts"
            )}
          </h4>
        </div>

        <button
          className="flex justify-around items-center px-3 py-2 rounded-full mr-3"
          style={{ background: "#1AC47D", border: "2px solid #1AAD70" }}
          onClick={() => {
            props.type === "add" ? addFleetOperator() : editFleetOperator();
          }}
        >
          <span className="mr-2">
            <svg
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.75 5L5.5 8.75L13 1.25"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span style={{ color: "white" }}>Submit</span>
        </button>
      </div>
    </Dialog>
  );
}

export default AddEditModal;
