export {};  // This makes the file a module

// Your format function remains the same
export const formatNumberWithCommas = (num: any) => {
    if (num === undefined || num === null) return "-";
    const [integer, decimal] = num.toString().split('.');
    
    // Format the integer part with commas according to the Indian numbering system
    const lastThree = integer.slice(-3);
    const otherNumbers = integer.slice(0, -3);
    const formattedInteger = otherNumbers !== '' ? otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree : lastThree;
    
    return decimal ? `${formattedInteger}.${decimal}` : formattedInteger;
  };