import React, { useEffect, useState, useMemo, memo, useCallback } from "react";
import "./databaseTable.scss";
import { DataGrid } from "@mui/x-data-grid";
import Parse from "parse";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import {
  TextField,
  Autocomplete,
  Chip,
  Checkbox,
  Tooltip,
  Modal,
  Button,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from '@mui/material/CircularProgress';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
interface optionObj {
  id: string;
  label: string;
}

interface UserFilter {
  id: string;
  label: string;
}

const DatabaseTable = memo(() => {
  const [dataRow, setDataRow] = useState<any>([]);
  const [isLoading, setLoading] = useState(false);
  // Filter Visible Code
  const [filteredRow, setFilteredRow] = useState<any>([]);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  // User Filter
  const [allUsers, setAllUsers] = useState<any | null>([]);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [userFilter, setUserFilter] = useState<UserFilter[]>([]);
  const [filterChips, setFilterChips] = useState<
    { id: string; label: string }[]
  >([]);
  const [userIds, setUserIds] = useState<any[]>([]);
  const [PhoneFilter, setPhoneFilter] = useState<any>("");
  const [RFID, setRFID] = useState<any>("");
  const [open, setOpen] = React.useState(false);
 
  const getAllUsers = useMemo(
    () => async () => {
      
      setLoadingUsers(true);
      try {
        const User = Parse.Object.extend("User");
        const parseQuery = new Parse.Query(User);
        parseQuery.notEqualTo("UserType", "Cloud");
        parseQuery.limit(4500);
        const result = await parseQuery.find();

        let usersArray: optionObj[] = [];
        result.forEach((item) => {
          usersArray.push({
            id: item.id,
            label: item.get("FullName"),
          });
        });

        setAllUsers(usersArray);
        setLoadingUsers(false);
        
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoadingUsers(false);
      
      }
    },
    []
  );

  useEffect(() => {
    getAllUsers();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setAllUsers([]);
    // setOptions([]);
  };
  useEffect(() => {
    if (Array.isArray(userFilter)) {
      const userIds = userFilter.map((user: any) => user.id);
      // console.log("users Id", userIds);
      setUserIds(userIds);

      if (userIds.length > 0) {
        // Your logic when userFilter is an array
      }
    } else if (userFilter && typeof userFilter === "object") {
      const userId = (userFilter as { id?: string }).id;
      console.log("user Id", userId);
      setUserIds(userId ? [userId] : []);

      // Your logic when userFilter is an object
    } else {
      // Handle other cases when userFilter is not an array or object
    }
  }, [userFilter]);

  
  const handleDeleteUserChip = (chipId: string) => {
    // console.log("Deleting chip with id:", chipId);

    // Remove the corresponding chip from the state
    setFilterChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== chipId)
    );

    // Remove the user filter
    setUserIds((prevUserIds) => prevUserIds.filter((id) => id !== chipId));

    setUserFilter((prevUserFilter: any) =>
      prevUserFilter.filter((filter: any) => filter.id !== chipId)
    );
    // console.log("userFilter in delete", userFilter);
  };

  // This is the Function To handle
  const handleUserChange = useCallback((event: any, newValue: any) => {
    setFilterChips((prevChips) => {
      const newChips = newValue.map((user: any) => ({
        id: user.id,
        label: `User: ${user.label}`,
      }));

      const filteredChips = newChips.filter(
        (chip: any) => !prevChips.some((prevChip) => prevChip.id === chip.id)
      );

      return [...prevChips, ...filteredChips];
    });

    setUserFilter(newValue);
    // setUserFilterOn(true);
  }, []);

  // Modal Code Start form here
  const [openModal, setOpenModal] = useState(false);
  const [selectedUserId, setSelectedUserObjId] = useState(null);
  const [selectedUser, setSelectedUserId] = useState(null);
  const [selectedUserRFID, setSelectedUserRFId] = useState("-");
  const [blockRfid, setBlock] = useState(false);
  const handleOpenModal = (rowId: any, name: any, RFID: any) => {
    console.log("RFID", RFID);
    let selectedRFID = RFID;
    setSelectedUserObjId(rowId);
    setSelectedUserId(name);
    if (selectedRFID == "undefined") {
      setSelectedUserRFId("");
    } else {
      setSelectedUserRFId(selectedRFID);
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedUserObjId(null);
    setOpenModal(false);
  };

const [loader,setLoader]= useState(false);
  const handleSaveRfId = async () => {
    setLoader(true);
    const objId = selectedUserId; // selected User ID
  
    // Validate that selectedUserId and RFID are not null or undefined
    if (!objId || !RFID) {
      alert("Selected User ObjectID or RFID cannot be null or undefined.");
      return; // Exit early if validation fails
    }
  
    try {
      const tag_id = objId; // Assume that objId is now validated and non-null
      const rfid_id = RFID;
      
      const add_Tag_Url = `${process.env.REACT_APP_OCPP_BASE_URL}/add_tag?tag_id=${encodeURIComponent(rfid_id)}`;
      
      const add_Tag_Url_response = await fetch(add_Tag_Url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log("add_Tag_Url_response ",add_Tag_Url_response);



      const url = `${process.env.REACT_APP_OCPP_BASE_URL}/connect_rfid?tag_id=${encodeURIComponent(tag_id)}&rfid_id=${encodeURIComponent(rfid_id)}`;
  
      // Make the first API call to save the RFID data
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log("response O",response);
      
      if (response.ok) {
       
         const params = {
        userId: objId,
        rfid: RFID,
        isenabled: blockRfid, // For blocking RFID.
      };

    Parse.Cloud.run("add_rfid", params).then(
        (status) => {
          alert("RFID Has Been Updated");
          handleCloseModal();
          getAllUsers();
        },
        (error: any) => {
          alert("Failed to Update RFID: " + error.message);
        }
      );
      } else {
        alert('First API call failed.');
      }

  setLoader(false);
    } catch (error: any) {
      alert("Failed to Update RFID: " + error.message);
    }
  };
  

  const [userCount, setCountUser] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage] = useState(100); // You can set the items per page here or pass it as a prop
  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNextPage = () => {
    // In a real scenario, you would need to know the total number of pages
    // to prevent going beyond the last page. For simplicity, I'll omit it here.
    setPageNumber(pageNumber + 1);
  };
  // For Fetching data Function

  const email = async () => {
    const parseQuery = new Parse.Query("_User");

    // Returns unique emails
    try {
      const results = await parseQuery.distinct("email");
      console.log("email", results);

      console.log(`Unique emails: ${JSON.stringify(results)}`);
    } catch (error: any) {
      console.log(`Error: ${JSON.stringify(error)}`);
    }
  };

  useEffect(() => {
    email();
  }, []);
  function decimalToHexadecimalRevInPair(input:any) {
    let result = '';
  
    // Traverse the input string in reverse, two characters at a time
    for (let i = input.length - 2; i >= 0; i -= 2) {
      result += input[i] + input[i + 1];
    }
    
    return result;
}
  const handleInputChangeRFID = (event:React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    // const decimalNumber = parseInt(inputValue, 10);

    // // Check if the input is a valid number before converting
    // if (!isNaN(decimalNumber)) {
    //     const hexValue = decimalToHexadecimal(decimalNumber);
    //     setRFID(hexValue);
    // } else {
    //     setRFID('');
    // }

    if(inputValue){
      const hexValueRevInPair = decimalToHexadecimalRevInPair(inputValue);
          setRFID(hexValueRevInPair);
    }

};
console.log("RFID DESIRED",RFID);

  const getUsers = async (pageNumber: number, itemsPerPage: number) => {
    const parseQuery = new Parse.Query("_User");
    parseQuery.descending("createdAt");
    parseQuery.include("EV", "RFID", "email","Fleet");

    parseQuery.limit(5000);
    parseQuery.notEqualTo("UserType", "Cloud");

    // parseQuery.find({ userMasterKey: true }).then((result) => {
    // User Filter For Multiple Search Results
    if (userIds && userIds.length > 0) {
      parseQuery.containedIn("objectId", userIds);
    }
    // Phone Filter
    if (PhoneFilter) {
      parseQuery.matches("Phone", new RegExp(PhoneFilter, "i"));
    }

    const skip = (pageNumber - 1) * itemsPerPage;
    // Set skip and limit for pagination
    parseQuery.skip(skip);
    parseQuery.limit(itemsPerPage);
    const ChargeSessionsCount = await parseQuery.count();
    setCountUser(ChargeSessionsCount);
    
    parseQuery.find().then((result) => {
      setLoading(false);
      let newRow: any[] = [];
      const startIndex = (pageNumber - 1) * itemsPerPage + 1;
      console.log("results", result);

      result.forEach((user, index) => {
        const email = user.get("email");
        if (email === undefined) {
          console.warn(`User with ID ${user.id} has undefined email`);
        } else {
          console.log(`User Email: ${email}`);
        }

        newRow.push({
          id: startIndex + index,
          objectId: `${user.id}`,
          name: `${user.get("FullName")}`,
          phone: `${user.get("Phone")}`,
          RegNum: `${user.get("RegistrationNumber")}`,
          email: `${user.get("email")}`,
          credit: `${user.get("Credit")}`,
          RFID: `${user.get("RFID")?.get("RFID")}`,
          Fleet: `${user.get("Fleet")?.get("Name") }`,
          garage: `${
            user.get("EV")?.length
              ? user
                  .get("EV")
                  .map((item: any) => item.get("Name"))
                  .join(", ")
              : "-"
          }`,
          date:
            `${user
              .get("createdAt")
              .toLocaleDateString("en-US", { day: "numeric" })}` +
            " - " +
            `${user
              .get("createdAt")
              .toLocaleDateString("en-US", { month: "short" })}` +
            " - " +
            `${user
              .get("createdAt")
              .toLocaleDateString("en-US", { year: "numeric" })}`,
          obj: user,
          rfid: user,
        });
      });
      console.log("Data", newRow);

      setDataRow(newRow);
      setFilteredRow(newRow);
    });
  };

  useEffect(() => {
    getUsers(pageNumber, itemsPerPage);
  }, [userIds, PhoneFilter, pageNumber]);

  // Toggle filter visibility Code
  const handleFilterButtonClick = () => {
    setIsFilterVisible((prev) => !prev);
  };

  const userColumns = [
    {
      field: "id",

      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          S.NO
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.id}</span>;
      },
      width: 60,
    },
    {
      field: "objectId",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Object ID
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.objectId}</span>;
      },
      width: 150,
    },
    {
      field: "name",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Name
        </span>
      ),
      width: 200,
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.name}</span>;
      },
    },
    {
      field: "RegNum",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Reg.Num
        </span>
      ),
      width: 200,
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.RegNum}</span>;
      },
    },
    {
      field: "phone",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Phone
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.phone}</span>;
      },
      width: 150,
    },
    {
      field: "email",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Email
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.email}</span>;
      },
      width: 200,
    },
    {
      field: "credit",
      headerName: "Credit",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Credits
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.credit}</span>;
      },
      width: 150,
      editable: false,
    },
    {
      field: "garage",
      headerName: "Vehicles in Garage",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Vehicles In Garage
        </span>
      ),
      width: 200,
      renderCell: (params: any) => {
        return (
          <span className="font-lexend flex">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.75 12.75C3.75 13.1478 3.90804 13.5294 4.18934 13.8107C4.47064 14.092 4.85218 14.25 5.25 14.25C5.64782 14.25 6.02936 14.092 6.31066 13.8107C6.59196 13.5294 6.75 13.1478 6.75 12.75M3.75 12.75C3.75 12.3522 3.90804 11.9706 4.18934 11.6893C4.47064 11.408 4.85218 11.25 5.25 11.25C5.64782 11.25 6.02936 11.408 6.31066 11.6893C6.59196 11.9706 6.75 12.3522 6.75 12.75M3.75 12.75H2.25V8.25M6.75 12.75H11.25M11.25 12.75C11.25 13.1478 11.408 13.5294 11.6893 13.8107C11.9706 14.092 12.3522 14.25 12.75 14.25C13.1478 14.25 13.5294 14.092 13.8107 13.8107C14.092 13.5294 14.25 13.1478 14.25 12.75M11.25 12.75C11.25 12.3522 11.408 11.9706 11.6893 11.6893C11.9706 11.408 12.3522 11.25 12.75 11.25C13.1478 11.25 13.5294 11.408 13.8107 11.6893C14.092 11.9706 14.25 12.3522 14.25 12.75M14.25 12.75H15.75V9.75C15.75 9.35218 15.592 8.97064 15.3107 8.68934C15.0294 8.40804 14.6478 8.25 14.25 8.25H13.5M2.25 8.25L3.75 4.5H10.5L13.5 8.25M2.25 8.25H13.5M9 8.25V4.5"
                stroke="#111111"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span className="ml-2">{params?.row?.garage}</span>
          </span>
        );
      },
      editable: false,
    },

    {
      field: "date",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Created At
        </span>
      ),
      width: 130,
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.date}</span>;
      },
      editable: false,
    },
    {
      field: "Fleet",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
         Fleet
        </span>
      ),
      width: 70,
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.Fleet != "undefined" ? <Tooltip title={params?.row?.Fleet} ><CheckBoxIcon sx={{color:'#1AC47D'}}/></Tooltip>  : '-' }</span>;
      },
      editable: false,
    },
    {
      field: "RFID",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
         RFID
        </span>
      ),
      width: 130,
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.RFID != "undefined" ? <Tooltip title={params?.row?.RFID} ><CreditCardOutlinedIcon sx={{color:'#1AC47D'}}/></Tooltip>  : '-' }</span>;
      },
      editable: false,
    },
    {
      field: "Assign RFID",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Assign RFID
        </span>
      ),
      width: 220,
      renderCell: (params: any) => {
        return (
          <>
            <Tooltip title="RFId">
              <button
                style={{
                  background: "#1AC47D",
                  width: "35px",
                  height: "35px",
                  padding: "5px",
                  borderRadius: "50%",
                  border: "3px solid #1AAD70",
                  marginLeft: "9px",
                }}
                onClick={() =>
                  handleOpenModal(
                    params?.row?.objectId,
                    params?.row?.name,
                    params?.row?.RFID
                  )
                }
              >
                <svg
                  className="ml-1"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.33268 2.66635L9.33268 6.66635M0.666016 8.66635L8.46602 0.866353C8.59063 0.744201 8.75818 0.675781 8.93268 0.675781C9.10718 0.675781 9.27473 0.744201 9.39935 0.866353L11.1327 2.59969C11.2548 2.72431 11.3233 2.89185 11.3233 3.06635C11.3233 3.24085 11.2548 3.4084 11.1327 3.53302L3.33268 11.333H0.666016V8.66635Z"
                    stroke="white"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const userRows = filteredRow;
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: () => (
        <div className="cellAction">
          <div className="viewButton">View</div>
          <div className="deleteButton">Delete</div>
        </div>
      ),
    },
  ];
  const [filter, setFilter] = useState("");
  const [numFilter, setNumFilter] = useState("");
  useEffect(() => {
    if (!filter) setFilteredRow(dataRow);

    setFilteredRow(
      dataRow.filter((item: any) =>
        item.name.toLowerCase().includes(filter.toLowerCase())
      )
    );
  }, [filter]);
  useEffect(() => {
    if (!numFilter) setFilteredRow(dataRow);

    setFilteredRow(
      dataRow.filter((item: any) =>
        item.phone.toLowerCase().includes(numFilter)
      )
    );
  }, [numFilter]);

  return (
    <div className="transaction-container">
      <div className="transaction_table_container flex">
        <div style={{ width: "100%" }}>
          <div className="flex justify-between mx-6 my-5 ">
            <h2 className="transaction_heading mt-2">Users</h2>
            <button
              className="filter_button rounded-full p-3 border-2 border-black-600"
              onClick={handleFilterButtonClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M3 3H15V4.629C14.9999 5.02679 14.8418 5.40826 14.5605 5.6895L11.25 9V14.25L6.75 15.75V9.375L3.39 5.679C3.13909 5.40294 3.00004 5.0433 3 4.67025V3Z"
                  stroke="#111111"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>

          <div className="filter-chips-container flex flex-wrap ml-4">
            {/* User Filter */}
            {filterChips.map((chip) => (
              <Chip
                key={chip.id}
                label={chip.label}
                onDelete={() => handleDeleteUserChip(chip.id)}
                variant="outlined"
                sx={{ marginLeft: "5px", marginBottom: "10px" }}
              />
            ))}
          </div>

          <div className="table_Users mx-3">
            <DataGrid
              rows={userRows}
              columns={userColumns}
              pageSize={100}
              rowsPerPageOptions={[5]}
              checkboxSelection={false}
              disableSelectionOnClick
              autoHeight
              loading={isLoading}
              hideFooterPagination
              hideFooter
            />
            <div className="flex justify-end  mb-6 px-4 py-5 border border-gray-300">
              <span className="bold font-lexend mr-5">
                {" "}
                {pageNumber * 100 - 99}–{Math.min(pageNumber * 100, userCount)}{" "}
                of {userCount}
              </span>
              <button
                disabled={pageNumber == 1}
                style={{ color: `${pageNumber == 1 ? "lightgray" : "black"}` }}
                onClick={handlePreviousPage}
              >
                {" "}
                <KeyboardArrowLeftIcon />{" "}
              </button>
              <button className="ml-8" onClick={handleNextPage}>
                <ChevronRightIcon />
              </button>
            </div>
          </div>
        </div>
        {/* Filter Code */}
        {isFilterVisible && (
          <div className="filters lg:w-1/3 flex flex-col  border-2 border-black-400">
            <div className="filter_header z-50 sticky top-0 right-0 flex justify-between px-4 py-5 mb-8">
              <h1 className="font-semibold font-lexend text-lg">Filters</h1>
              <button
                style={{
                  background: "#1AC47D",
                  width: "35px",
                  height: "35px",
                  padding: "5px",
                  borderRadius: "50%",
                  border: "3px solid #1AAD70",
                }}
                onClick={handleFilterButtonClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M3.33301 8.00033L6.66634 11.3337L13.333 4.66699"
                    stroke="white"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>

            <div className="filter_main_container px-4">
              <h1 className="font-semibold font-lexend text-lg mb-2">Users</h1>
              <Autocomplete
                  //  open={open}
                  //  onOpen={getAllUsers}
                  //  onClose={handleClose}
                className="mb-4"
                ListboxProps={{ style: { maxHeight: 210 } }}
                id="checkboxes-tags-demo"
                disableClearable
                multiple
                limitTags={-1}
                options={allUsers}
                autoHighlight
                size="small"
                value={userFilter}
                filterOptions={(options, { inputValue }) => {
                  const inputPattern = new RegExp(`^${inputValue.split(' ').map(word => `(?=.*\\b${word})`).join('')}`, 'i'); 
          
                  // Filter options based on the inputValue and ensure selected options are handled properly
                  return options.filter(option => {
                 
                    const label = option.label.toLowerCase();
                    const matchesInput = inputPattern.test(label);
                    const isDisabled = filterChips.some(chip => chip.id === option.id);
          
                    return matchesInput && !isDisabled;
                  });
                }}
                onChange={(event: any, newValue: any) => {
                  setFilterChips(
                    (prevChips: { id: string; label: string }[]) => {
                      const newChips: { id: string; label: string }[] =
                        newValue.map((user: any) => ({
                          id: user.id,
                          label: `User: ${user.label}`,
                        }));

                      const filteredChips = newChips.filter(
                        (chip) =>
                          !prevChips.some((prevChip) => prevChip.id === chip.id)
                      );

                      return [...prevChips, ...filteredChips];
                    }
                  );
                  setUserFilter(newValue);
                }}
                
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option: any) => option.label}
                getOptionDisabled={(option) =>
                  filterChips.some((chip) => chip.id === option.id)
                }

                renderOption={(props, option:any,index) => {
                 const key = `listItem-${index}-${option.id}`;
                  const selected = filterChips.some(
                    (chip) => chip.id === option.id
                  );
                  return (
                    <MenuItem {...props} key={key}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={
                          selected ||
                          filterChips.some((chip) => chip.id === option.id)
                        }
                      />
                      <Typography sx={{ fontFamily: "lexend" ,fontSize:'16px'}}>
                        {/* Formatted name function call */}
                      {/* {capitalizeFirstLetterOfEachWord(option.label)}  */}
                       {option.label}
                      </Typography>
                    </MenuItem>
                  );
                }}
                
                renderInput={(params: any) => (
                  <TextField {...params} placeholder="Type to search...."  
                  // InputProps={{
                  //   ...params.InputProps,
                  //   endAdornment: (
                  //     <>
                  //       {loadingUsers && <CircularProgress color="inherit" size={20} />}
                  //       {params.InputProps.endAdornment}
                  //     </>
                  //   ),
                  // }}
                />
                )}
              />
              <hr style={{ height: "2px" }} />
              <h1 className="font-semibold font-lexend text-lg mb-2">
                Number{" "}
              </h1>

              <TextField
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                  width: "100%",
                  marginBottom: 5,
                }}
                id="outlined-search"
                label="Mobile Search"
                type="number"
                size="small"
                onChange={(event: any) => {
                  setPhoneFilter(event.target.value);
                }}
              />
            </div>
          </div>
        )}
      </div>
      {/* Modal */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <div
          style={{
            background: "white",
            position: "absolute",
            top: "20%",
            left: "20%",
            width: "700px",
            height: "250px",
          }}
        >
          {/* Add your modal content here */}
          <h3 className="font-lexend text-2xl font-bold my-4 ml-4">
            Assign RFID
          </h3>
          <span className="flex content-center mt-4">
            <h1 className="font-lexend mr-4 mt-2 ml-4"> User :</h1>
            <TextField
              variant="standard"
              id="outlined-search"
              type="text"
              size="small"
              sx={{ width: "62%" }}
              value={selectedUser}
              disabled
              // onChange={(event: any) => {
              //   setPhoneFilter(event.target.value);
              // }}
            />
          </span>
          <span className="flex items-center  content-center mt-4">
            <h1 className="font-lexend mr-4  ml-4">RFID:</h1>
            <TextField
              variant="standard"
              id="outlined-search"
              // placeholder="Please Ender RFID.."
              placeholder={selectedUserRFID}
              type="text"
              defaultValue={selectedUserRFID || ""}
              size="small"
              sx={{ width: "63%", marginBottom: 2 }}
              // onChange={(event: any) => {
              //   setRFID(event.target.value);
              // }}
              onChange={handleInputChangeRFID}
            />

            <button
              className="font-lexend rounded-md py-1 px-4 flex items-center"
              style={{
                background: `${loader ? "#8f9190" : "#1AAD70"}`,
                cursor: `${loader ? "not-allowed" : "pointer"}`,
                marginLeft: "20px",
                color: "white",
              }}
              disabled={loader}
              onClick={handleSaveRfId}
            >
             

              <span className="font-lexend"> {loader ? (<div className="flex"><CircularProgress color="inherit" size={20} /> <p className="ml-2 font-lexend">Assigning</p> </div>  ) : (<div className="flex">  <span className="mr-2">
                <svg
                  width="17"
                  height="19"
                  viewBox="0 0 17 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 19V17C1 15.9391 1.42143 14.9217 2.17157 14.1716C2.92172 13.4214 3.93913 13 5 13H8M11 20L16 15M16 15V19.5M16 15H11.5M3 5C3 6.06087 3.42143 7.07828 4.17157 7.82843C4.92172 8.57857 5.93913 9 7 9C8.06087 9 9.07828 8.57857 9.82843 7.82843C10.5786 7.07828 11 6.06087 11 5C11 3.93913 10.5786 2.92172 9.82843 2.17157C9.07828 1.42143 8.06087 1 7 1C5.93913 1 4.92172 1.42143 4.17157 2.17157C3.42143 2.92172 3 3.93913 3 5Z"
                    stroke="#ffffff"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span> <p className="font-lexend">Assign</p> </div>)} </span>
            </button>
          </span>

          <div className="flex items-center justify-between mx-4 mt-8">
            <span className="flex items-center">
              <input
                style={{ width: "20px", height: "20px" }}
                type="checkbox"
                onChange={() => setBlock(!blockRfid)}
              />
              <label className="font-lexend ml-2 mb-1" htmlFor="">
                Block
              </label>
            </span>

            <button
              className="font-lexend rounded-md py-1 px-4 text-white mr-6"
              style={{ background: "#eb0808", marginLeft: "20px" }}
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default DatabaseTable;
