import { useState, useEffect } from "react";
interface ChargerInfo {
  serial: string;
  locationName: string;
  gunId?:number;
  connectors: {
    connectorNum: number;
    status: string;
    
  }[];
}

const ChargerStatusWidget = () => {
    let userDetail: any = sessionStorage.getItem("user-details");
    let isSuperAdmin: any = sessionStorage.getItem("isSuperAdmin");
    let currentUserCPO: any = sessionStorage.getItem("Current-User-CPOId");
    console.log("currentUserCPO",currentUserCPO);
    
    const currentUser: any = Parse.User.current();
  const [currentHeading, setCurrentHeading] = useState(0);
  const [animationClass, setAnimationClass] = useState("fade-in");
  const [chargersInfo, setChargersInfo] = useState<ChargerInfo[]>([]);

  // useEffect(() => {
  //   console.log("isSuperAdmin cs",isSuperAdmin);
    
  //   if (isSuperAdmin === "false" && currentUserCPO !== "B7fRhfkxRg") {
  //     console.log("User is not Super Admin, skipping API call");
  //     return; // Exit the useEffect, skipping the API call
  //   }

  //   const fetchApiData = async () => {
  //     console.log("API is Active Clients");
      
  //     try {
  //       // Fetch data from the API
  //       const response = await fetch(
  //         "https://ocpp.chargecity.co.in/api/v1/active_clients"
  //       );
  //       if (!response.ok) {
  //         throw new Error(`Error fetching API: ${response.statusText}`);
  //       }
  //       const apiData = await response.json();

  //       // Define the allowed statuses
  //       const allowedStatuses = [
  //         "Charging",
  //         "Preparing",
  //         "Connected",
  //         "SuspendedEV",
  //         "Faulted",
  //         'Finishing'
  //       ];
        
  //       // Extract serial numbers and connectors info
  //       const chargers = apiData.reduce((acc: ChargerInfo[], item: any) => {
  //         const chargerAttributes = item?.charger_attributes?.[0];
  //         const serial =
  //           chargerAttributes?.charge_box_serial_number ||
  //           chargerAttributes?.charge_point_serial_number ||
  //           null;

  //         if (serial) {
  //           // Find the connector with connector_num 1
  //           const connector = item.connectors.find(
  //             (connector: any) => connector.connector_num === 1
  //           );

  //           // If connector is found, save its status
  //           if (connector && allowedStatuses.includes(connector.status)) {
  //             const status = connector.status;
  //             acc.push({ serial, connectors: status, locationName: "" }); // Placeholder for locationName
  //           }
  //         }

  //         return acc;
  //       }, []);

  //       // Query Parse for Chargers and include location pointer
  //       const chargersQuery = new Parse.Query("Chargers");
  //       if (currentUser && !JSON.parse(userDetail).isSuperAdmin) {
  //         chargersQuery.equalTo("CPO", currentUser.get("CPO"));
  //       }
  //       chargersQuery.include("Location"); // Include the location pointer

  //       const chargeResult = await chargersQuery.find();

  //       // Create a map for quick lookup of serial to location name
  //       const serialToLocationMap = chargeResult.reduce(
  //         (map: Record<string, string>, chargePoint: any) => {
  //           const serial = chargePoint.get("Serial");
  //           const location = chargePoint.get("isEnabled")
  //             ? chargePoint.get("Location")
  //             : null;
  //           if (serial && location) {
  //             map[serial] = location.get("Name") || "Unknown Location";
  //           }
  //           return map;
  //         },
  //         {}
  //       );

  //       // Map location names to the corresponding chargers
  //       const updatedChargers = chargers.map((charger: any) => {
  //         charger.locationName =
  //           serialToLocationMap[charger.serial] || "Unknown Location";
  //         return charger;
  //       });

  //       // Update state with the combined charger info
  //       setChargersInfo(updatedChargers);
  //     } catch (error) {
  //       console.error("Error fetching API data:", error);
  //     }
  //   };

  //   fetchApiData();
  // }, []);

  useEffect(() => {
    console.log("isSuperAdmin cs", isSuperAdmin);
  
    if (isSuperAdmin === "false" && currentUserCPO !== "B7fRhfkxRg") {
      console.log("User is not Super Admin, skipping API call");
      return; // Exit the useEffect, skipping the API call
    }
  
    const fetchApiData = async () => {
      console.log("API is Active Clients");
  
      try {
        // Fetch data from the API
        const response = await fetch(
          "https://ocpp.chargecity.co.in/api/v1/active_clients"
        );
        if (!response.ok) {
          throw new Error(`Error fetching API: ${response.statusText}`);
        }
        const apiData = await response.json();
  
        // Define the allowed statuses
        const allowedStatuses = [
          "Charging",
          "Preparing",
          "Connected",
          "SuspendedEV",
          "Faulted",
          "Finishing",
          "Offline"
        ];
  
        // Extract serial numbers and connectors info
        const chargers = apiData.reduce((acc: ChargerInfo[], item: any) => {
          const chargerAttributes = item?.charger_attributes?.[0];
          const serial =
            chargerAttributes?.charge_box_serial_number ||
            chargerAttributes?.charge_point_serial_number ||
            null;
  
          if (serial) {
            // Find the connector with connector_num 1
            const connector = item.connectors.find(
              (connector: any) => connector.connector_num === 1 ||  connector.connector_num === 2 
            );
           
            
  
            // If connector is found, save its status
            if (connector && allowedStatuses.includes(connector.status)) {
              console.log("connector data:",connector);
              const gunId = connector?.connector_num
              const status = connector.status;
              acc.push({ serial, gunId,  connectors: status, locationName: "" }); // Placeholder for locationName
            }
          }
  
          return acc;
        }, []);
  
        // Query Parse for Chargers, include location pointer and filter by CPO
        const chargersQuery = new Parse.Query("Chargers");
        if (currentUser && !JSON.parse(userDetail).isSuperAdmin) {
          chargersQuery.equalTo("CPO", currentUser.get("CPO")); // Filter by CPO
        }
        chargersQuery.include("Location"); // Include the location pointer
  
        const chargeResult = await chargersQuery.find();
  
        // Create a map for quick lookup of serial to location name
        const serialToLocationMap = chargeResult.reduce(
          (map: Record<string, string>, chargePoint: any) => {
            const serial = chargePoint.get("Serial");
            const location = chargePoint.get("isEnabled")
              ? chargePoint.get("Location")
              : null;
            if (serial && location) {
              map[serial] = location.get("Name") || "Unknown Location";
            }
            return map;
          },
          {}
        );
  
        // Map location names to the corresponding chargers
        const updatedChargers = chargers.map((charger: any) => {
          charger.locationName =
            serialToLocationMap[charger.serial] || "Unknown Location";
          return charger;
        });
  
        // Update state with the combined charger info
        setChargersInfo(updatedChargers);
      } catch (error) {
        console.error("Error fetching API data:", error);
      }
    };
  
    fetchApiData();
  }, []);
  

  console.log("chargersInfo", chargersInfo);

  useEffect(() => {
    // Set an interval to update the current heading every 5 seconds
    const interval = setInterval(() => {
      setAnimationClass("fade-out");
      setTimeout(() => {
        setCurrentHeading(
          (prevHeading) => (prevHeading + 1) % chargersInfo.length
        );
        setAnimationClass("fade-in");
      }, 500); // Adjust this to match the duration of the fade-out
    }, 5000);

    return () => clearInterval(interval);
  }, [chargersInfo.length]);

  console.log("ChargerInfo",chargersInfo);
  
  return (
    <>
      <div className={`heading-container ${animationClass}`}>
                {chargersInfo.length === 0 ? (
                  <div className="no-data-message">
                    <div style={{ height: "44px" }} className="w-full"></div>
                  </div>
                ) : (
                  chargersInfo.map((charger: any, index: number) => (
                    <div
                      key={index}
                      className={`heading-container-item ${charger.serial}`}
                    >
                      {currentHeading === index && (
                        <>
                        {/* Finishing widget Message */}
                          {charger.connectors === "Finishing" && (
                            <div
                              style={{ height: "44px" }}
                              className="flex items-center"
                            >
                              <span className="font-lexend ml-4 mr-2">
                                {charger.serial} session is
                              </span>{" "}
                              <span className="flex items-center">
                                <span
                                  className="font-lexend mr-2"
                                  style={{ color: "#fd9610" }}
                                >
                                  Finishing
                                </span>{" "}
                              </span>
                              <span className="font-lexend">
                                at {charger.locationName}
                              </span>
                            </div>
                          )}
                        {/* Charging widget Message */}
                          {charger.connectors === "Charging" && (
                            <div
                              style={{ height: "44px" }}
                              className="flex items-center"
                            >
                              <span className="font-lexend ml-4 mr-2">
                                {charger.serial}
                              </span>{" "}
                              <span className="flex items-center">
                                {" "}
                                <span>
                                  <svg
                                    className="mr-1"
                                    width="15"
                                    height="14"
                                    viewBox="0 0 15 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.78906 2.75C1.78906 2.35218 1.9471 1.97064 2.2284 1.68934C2.50971 1.40804 2.89124 1.25 3.28906 1.25H11.5391C11.9369 1.25 12.3184 1.40804 12.5997 1.68934C12.881 1.97064 13.0391 2.35218 13.0391 2.75V3.125C13.0391 3.22446 13.0786 3.31984 13.1489 3.39017C13.2192 3.46049 13.3146 3.5 13.4141 3.5C13.5135 3.5 13.6089 3.53951 13.6792 3.60983C13.7496 3.68016 13.7891 3.77554 13.7891 3.875V6.125C13.7891 6.22446 13.7496 6.31984 13.6792 6.39016C13.6089 6.46049 13.5135 6.5 13.4141 6.5C13.3146 6.5 13.2192 6.53951 13.1489 6.60983C13.0786 6.68016 13.0391 6.77554 13.0391 6.875V7.25C13.0391 7.64782 12.881 8.02935 12.5997 8.31066C12.3184 8.59196 11.9369 8.75 11.5391 8.75H8.16406M3.28906 12.5V10.25M1.78906 7.25V5.375M4.78906 7.25V5.375M1.03906 7.25H5.53906V8.75C5.53906 9.14782 5.38103 9.52935 5.09972 9.81066C4.81842 10.092 4.43689 10.25 4.03906 10.25H2.53906C2.14124 10.25 1.75971 10.092 1.4784 9.81066C1.1971 9.52935 1.03906 9.14782 1.03906 8.75V7.25Z"
                                      stroke="#1AC47D"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </span>{" "}
                                <span
                                  className="font-lexend"
                                  style={{ color: "#1AC47D" }}
                                >
                                  Charging 
                                </span>{" "}
                                {/* {charger?.gunId} */}
                                <span className="mx-1">
                                {charger?.gunId == "1" ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.333 0C18.293 0 19.893 1.537 19.995 3.472L20 3.667V16.333C20 18.293 18.463 19.893 16.528 19.995L16.333 20H3.667C2.72818 20 1.82509 19.64 1.1438 18.9941C0.462517 18.3481 0.0549239 17.4655 0.00500011 16.528L0 16.333V3.667C0 1.707 1.537 0.107 3.472 0.00500011L3.667 0H16.333ZM10.994 5.886C10.911 5.109 9.986 4.726 9.377 5.216L9.293 5.293L7.293 7.293L7.21 7.387C7.07393 7.56237 7.00008 7.77803 7.00008 8C7.00008 8.22197 7.07393 8.43763 7.21 8.613L7.293 8.707L7.387 8.79C7.56237 8.92607 7.77803 8.99992 8 8.99992C8.22197 8.99992 8.43763 8.92607 8.613 8.79L8.707 8.707L9 8.414V14L9.007 14.117C9.03591 14.3601 9.15296 14.5841 9.33597 14.7466C9.51897 14.9091 9.75524 14.9989 10 14.9989C10.2448 14.9989 10.481 14.9091 10.664 14.7466C10.847 14.5841 10.9641 14.3601 10.993 14.117L11 14V6L10.994 5.886Z"
                  fill="#676767"
                />
              </svg>
            )  : charger?.gunId === "2" ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.333 0C18.293 0 19.893 1.537 19.995 3.472L20 3.667V16.333C20 18.293 18.463 19.893 16.528 19.995L16.333 20H3.667C2.72818 20 1.82509 19.64 1.1438 18.9941C0.462517 18.3481 0.0549239 17.4655 0.00500011 16.528L0 16.333V3.667C0 1.707 1.537 0.107 3.472 0.00500011L3.667 0H16.333ZM11 5H8L7.883 5.007C7.63995 5.03591 7.41594 5.15296 7.25341 5.33596C7.09088 5.51897 7.00111 5.75524 7.00111 6C7.00111 6.24476 7.09088 6.48103 7.25341 6.66403C7.41594 6.84704 7.63995 6.96409 7.883 6.993L8 7H11V9H9L8.85 9.005C8.37383 9.04078 7.92617 9.24576 7.58797 9.58286C7.24977 9.91996 7.04334 10.3669 7.006 10.843L7 11V13L7.005 13.15C7.04078 13.6262 7.24576 14.0738 7.58286 14.412C7.91996 14.7502 8.36695 14.9567 8.843 14.994L9 15H12L12.117 14.993C12.36 14.9641 12.5841 14.847 12.7466 14.664C12.9091 14.481 12.9989 14.2448 12.9989 14C12.9989 13.7552 12.9091 13.519 12.7466 13.336C12.5841 13.153 12.36 13.0359 12.117 13.007L12 13H9V11H11L11.15 10.995C11.6262 10.9592 12.0738 10.7542 12.412 10.4171C12.7502 10.08 12.9567 9.63305 12.994 9.157L13 9V7L12.995 6.85C12.9592 6.37383 12.7542 5.92617 12.4171 5.58797C12.08 5.24977 11.6331 5.04334 11.157 5.006L11 5Z"
                  fill="#676767"
                />
              </svg>
            ): (<span>-</span>)}
                                </span>
                               
                              </span>
                              <span className="font-lexend">
                                at {charger.locationName}
                              </span>
                            </div>
                          )}
                          {/* Charger Faulted Message */}
                          {charger.connectors === "Faulted" && (
                           
                            <div
                              style={{ height: "44px" }}
                              className="flex items-center"
                            >
                              <span className="font-lexend ml-4 mr-2">
                                {charger.serial}
                              </span>{" "}
                              <span className="flex items-center">
                                <span>
                                  <svg
                                    className="mr-1"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M10.55 14.733C9.8012 14.9687 9.0124 15.0501 8.23126 14.9724C7.45011 14.8946 6.69286 14.6592 6.00527 14.2804C5.31768 13.9017 4.71404 13.3874 4.23083 12.7688C3.74763 12.1501 3.39491 11.4399 3.19398 10.681C2.93136 9.69083 2.93521 8.64883 3.20514 7.66063C3.47506 6.67243 4.00146 5.77317 4.73098 5.054L6.78498 3L13.95 10.165M1 17L4.5 13.5M12 1L8.5 4.5M17 6L13.5 9.5M13 13L17 17M17 13L13 17"
                                      stroke="#981818"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </span>
                                <span className="text-red-900 font-lexend mr-1">Faulted</span>
                              </span>
                              <span className="font-lexend">
                                at {charger.locationName}
                              </span>
                            </div>
                          )}
                          {/* Charger Preparing Message */}
                          {charger.connectors === "Preparing" && (
                            <div
                              style={{ height: "44px" }}
                              className="flex items-center"
                            >
                              <span className="font-lexend ml-4 mr-2">
                                {charger.serial} Charger is
                              </span>{" "}
                              <span className="flex items-center">
                                {" "}
                                <span>
                                  <svg
                                    className="mr-1"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1 13L3.625 10.375M9.25 1L6.625 3.625M13 4.75L10.375 7.375M5.33877 2.5L11.5 8.66125L9.95952 10.2017C9.55791 10.6194 9.07704 10.9528 8.54504 11.1824C8.01304 11.412 7.44059 11.5332 6.86119 11.5389C6.28179 11.5445 5.70708 11.4346 5.17067 11.2155C4.63427 10.9964 4.14695 10.6725 3.73724 10.2628C3.32752 9.85306 3.00363 9.36575 2.78452 8.82934C2.56541 8.29294 2.45548 7.71822 2.46116 7.13882C2.46685 6.55942 2.58803 5.98698 2.81762 5.45497C3.0472 4.92297 3.38059 4.4421 3.79827 4.0405L5.33877 2.5Z"
                                      stroke="#52ec6c"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </span>{" "}
                                <span className="font-lexend mr-1" style={{ color: "#52ec6c" }}>
                                  Preparing
                                </span>{" "}
                              </span>
                              <span className="font-lexend">
                                at {charger.locationName}
                              </span>
                            </div>
                          )}
                          {/* Suspended Ev Message */}
                          {charger.connectors === "SuspendedEV" && (
                            <div
                              style={{ height: "44px" }}
                              className="flex items-center"
                            >
                              <span style={{fontSize:'13px'}} className="font-lexend mx-2">
                                {charger.serial} 
                              </span>{" "}
                              <span className="flex items-center">
                               <span className="font-lexend">Charger has</span>
                                <span className="text-red-900 font-lexend mx-1 font-semibold">
                                  Suspended EV
                                </span>
                              </span>
                              <span className="font-lexend">
                                at {charger.locationName}
                              </span>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ))
                )}
              </div>
    </>
  );
};

export default ChargerStatusWidget;
