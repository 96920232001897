import "./home.scss";
import Widget from "./widget";
import Chart from "../../components/chart";
import XChart from "./pieChart";
import moment from "moment";
import DatabaseTable from "./databasetable";
import { useState, useEffect } from "react";
import ChargerStatusWidget from "./widget/ChargerStatus";
interface ChargerInfo {
  serial: string;
  locationName: string;
  connectors: {
    connectorNum: number;
    status: string;
  }[];
}
const Home = () => {
  const [usage, setUsage] = useState({ thisMonth: 0, lastMonth: 0, total: 0 });
  const [customerEnrollment, setCustomerEnrollment] = useState({
    thisMonth: 0,
    lastMonth: 0,
    total: 0,
  });
  const [upComingBookingCount,SetUpcomingBookingCount] = useState(0);
  
  const getAllBookingsData = () => {
    setTableLoading(true);
    const parseQuery = new Parse.Query("Bookings");
    parseQuery.descending("createdAt");
    parseQuery.include("User");
    parseQuery.include("ChargePoint");
    parseQuery.limit(100);
    if (currentUser) {
      var innerQuery = new Parse.Query("Chargers");

      innerQuery.equalTo("CPO", currentUser.get("CPO"));
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }

    parseQuery.find().then((result: any[]) => {
      let newRow: any[] = [];
      let upComingBooking = 0;
      let cancelledBooking = 0;
      let expiredBooking = 0;
      const currentDate = moment();
      result.forEach((item, index) => {
        const cancelled = item.get("isCancelled");
        if (cancelled) {
          cancelledBooking++;
        }

        const bookingDate = new Date(item.get("Date"));
        const startTimes = item.get("StartTime");
        const formattedDate = bookingDate.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });

        // Convert startTime to 24-hour format for proper parsing
        const formattedTime = moment(startTimes, ["h:mm A"]).format("HH:mm");
        const formattedDateTime = `${formattedDate} ${formattedTime}`;

        const bookingStartTime = moment(formattedDateTime, "MMM D, YYYY HH:mm");
        if (bookingStartTime.isBefore(currentDate) && cancelled == false) {
          expiredBooking++;
        }

        if (bookingStartTime.isAfter(currentDate) && cancelled == false) {
          upComingBooking++;
        }

        const Duration = item.get("Duration");
        const hoursMatch = Duration.match(/(\d+)\s*Hours?/i);
        const minutesMatch = Duration.match(/(\d+)\s*Mins?/i);

        const removeHours = hoursMatch ? parseInt(hoursMatch[1], 10) * 60 : 0;
        const removeMinutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;

        const totalMinutes = removeHours + removeMinutes;

        const newDateTime = moment(formattedDateTime, "MMM D, YYYY HH:mm").add(
          totalMinutes,
          "minutes"
        );

        const formattedEndDateTime = moment(newDateTime, ["h:mm A"]).format(
          "MMM D, YYYY HH:mm"
        );

        // console.log(item.get("Duration"));

        // console.log("format start time ", formattedDateTime);
        // console.log("formatted end time ", formattedEndDateTime);

        let url = "/images/placeholder.png";
        let file = item.get("Image");
        if (file != null) {
          url = file.url();
        }
        let logoUrl = "/images/placeholder.png";

        let logoFile = item.get("Brand") ? item.get("Brand").get("Logo") : "";
        if (logoFile) {
          logoUrl = logoFile.url();
        }

        newRow.push({
          id: index + 1,
          name: `${item.get("User")?.get("FullName")}`,
          mobileNumber: `${
            item.get("User")?.get("Phone")
              ? item.get("User")?.get("Phone")
              : "-"
          }  `,
          startTime: `${formattedDateTime}`,
          // startTime: `${moment(item.get("Date")).format("lll")}`,
          // endTime: `${moment(item.get("updatedAt")).format("lll")}`,
          endTime: `${formattedEndDateTime}`,
          // endTime: `${endTime.format("lll")}`,
          serial: `${item.get("ChargePoint")?.get("Serial")}`,
          duration: `${item.get("Duration")}`,
          status: `${
            moment().isAfter(item.get("createdAt")) && !item.get("isCancelled")
          }`,
          upcoming: `${
            moment().isBefore(formattedDateTime) && !item.get("isCancelled")
          }`,

          expired: `${
            moment().isAfter(formattedDateTime) && !item.get("isCancelled")
          }`,
          cancelled: `${item.get("isCancelled")}`,
          obj: item,
        });
      });

      setTableLoading(false);

      console.log("Total upComingBooking",upComingBooking);

      SetUpcomingBookingCount(upComingBooking)

    
    });
  };
 
  useEffect(() => {
    getAllBookingsData();
  }, []);

  const chargeInProcessCount = sessionStorage.getItem("activateCharge");
  const ScheduledCountString = sessionStorage.getItem("ScheduledHomeCount");
  // console.log("ScheduledCountString", ScheduledCountString);

  const ScheduledCount = ScheduledCountString
    ? parseInt(ScheduledCountString, 10)
    : 0;

    useEffect(() => {
      const fetchUserEnrollmentData = async () => {
        try {
          const parseQuery = new Parse.Query("_User");
          parseQuery.descending("createdAt");
          parseQuery.include("EV");
          parseQuery.notEqualTo("UserType", "Cloud");
          parseQuery.limit(100);
    
          const result = await parseQuery.find();
          const startOfThisMonth = moment().startOf("month");
          const startOfLastMonth = moment().subtract(1, "months").startOf("month");
    
          let thisMonthCustomer = 0;
          let lastMonthCustomer = 0;
          let totalCustomer = 0;
    
          result.forEach((item) => {
            const createdAt = moment(item.get("createdAt"));
    
            if (createdAt.isSameOrAfter(startOfThisMonth)) {
              thisMonthCustomer++;
            } else if (createdAt.isSameOrAfter(startOfLastMonth)) {
              lastMonthCustomer++;
            } else {
              totalCustomer++;
            }
          });
    
          setCustomerEnrollment({
            thisMonth: thisMonthCustomer,
            lastMonth: lastMonthCustomer,
            total: totalCustomer,
          });
        } catch (error) {
          console.error("Error fetching user enrollment data:", error);
        }
      };
    
      fetchUserEnrollmentData();
    }, []);
    

  // useEffect(() => {
  //   const parseQuery = new Parse.Query("_User");
  //   parseQuery.descending("createdAt");
  //   parseQuery.include("EV");
  //   parseQuery.notEqualTo("UserType", "Cloud");
  //   parseQuery.limit(100);
  //   parseQuery.find().then((result) => {
  //     let totalCustomer = 0;
  //     let thisMonthCustomer = 0;
  //     let lastMonthCustomer = 0;

  //     result.forEach((item, index) => {
  //       if (moment(item.get("createdAt")).isAfter(moment().startOf("month"))) {
  //         thisMonthCustomer = thisMonthCustomer + 1;
  //       } else if (
  //         item.get("createdAt") >
  //         moment().subtract(1, "months").startOf("month")
  //       ) {
  //         lastMonthCustomer = lastMonthCustomer + 1;
  //       } else {
  //         totalCustomer = totalCustomer + 1;
  //       }
  //     });
  //     setCustomerEnrollment({
  //       thisMonth: thisMonthCustomer,
  //       lastMonth: lastMonthCustomer,
  //       total: totalCustomer,
  //     });
  //   });
  // }, []);
  const [billedRevenue, setBilledRevenue] = useState({
    thisMonth: 0,
    lastMonth: 0,
    total: 0,
  });
  const [avRvenue, setAvRevenue] = useState({
    thisMonth: 0,
    lastMonth: 0,
    total: 0,
  });

  useEffect(() => {
    loadSessions();
  }, []);
  const [dataRow, setDataRow] = useState<any>([]);
  const [alertData, setAlertData] = useState({
    chargingPointOnline: 0,
    chargingPointOffline: 0,
    chargingSession: 0,
    chargingFailure: 0,
    chargerConnected: 0,
    chargingFinishing: 0,
    chargerBooked: 0,
  });
  const currentUser: any = Parse.User.current();

  const [whiteLabeled, setWhiteLabeled] = useState(false);

  // const getCpoStatus = () => {
  //   const Users = Parse.Object.extend("ChargePointOperators");
  //   const parseQuery = new Parse.Query(Users);

  //   if (currentUser) {
  //     parseQuery.equalTo("objectId", currentUser.get("CPO").id);
  //   }
  //   parseQuery.limit(1);

  //   parseQuery.find().then((result: any) => {
  //     result.forEach((item: any) => {
  //       setWhiteLabeled(item.get("isWhiteLabel"));
  //     });
  //   });
  // };
  // const allClients = () => {
  //   fetch(`${process.env.REACT_APP_OCPP_BASE_URL}/clients`);
  // };
  // useEffect(() => {
  //   // getCpoStatus();
  //   // allClients();
  // }, []);
  const loadBookings = () => {
    const parseQuery = new Parse.Query("Bookings");
    parseQuery.include("User");
    parseQuery.include("ChargePoint");
    parseQuery.equalTo("isCancelled", false);
    parseQuery.greaterThan("Date", new Date());
    if (currentUser) {
      var innerQuery = new Parse.Query("Chargers");

      innerQuery.equalTo("CPO", currentUser.get("CPO"));
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }
    parseQuery.count().then((result) => {
      setAlertData({
        ...alertData,
        chargerBooked: result,
      });
    });
  };

  useEffect(() => {
    loadBookings();
  }, []);

  const getOcppData = async (id: any, item: any, start: any) => {
    await fetch(`${process.env.REACT_APP_OCPP_BASE_URL}/meter_value/${id}`)
      .then((response: any) => response.json())
      .then((res: any) => {
        setDataRow((oldArray: any) => [
          ...oldArray,
          {
            ...item,
            ocppEnergy: (res.energy_active_import_register / 1000).toFixed(2),
            ocppDuration:
              moment.duration(moment(res.timestamp).diff(start)).hours() +
              "hr" +
              " " +
              moment.duration(moment(res.timestamp).diff(start)).minutes() +
              "min",
            soc: res?.soc,
            ocppCost: (
              item.tariff *
              (res.energy_active_import_register / 1000)
            ).toFixed(2),
          },
        ]);
      });
  };
  const [tableLoading, setTableLoading] = useState(false);

  const loadSessions = () => {
    setTableLoading(true);
    const parseQuery = new Parse.Query("ChargeSession");
  
    
    parseQuery.include(["ChargePoint", "Location", "Vehicle", "User", "User.Fleet"]);
    parseQuery.descending("createdAt");
    parseQuery.limit(5000); // This can handle 5000 CC 2 months data 👍 
  
    const startOfCurrentMonth = moment().startOf("month").toDate();
  const endOfCurrentMonth = moment().endOf("month").toDate();
  const startOfLastMonth = moment().subtract(1, "months").startOf("month").toDate();
  const endOfLastMonth = moment().subtract(1, "months").endOf("month").toDate();
  
  console.log("startOfCurrentMonth",startOfCurrentMonth);
  console.log("endOfCurrentMonth",endOfCurrentMonth);
  console.log("startOfLastMonth",startOfLastMonth);
  console.log("endOfLastMonth",endOfLastMonth);
  
  parseQuery.greaterThanOrEqualTo("createdAt", startOfLastMonth);
  parseQuery.lessThanOrEqualTo("createdAt", endOfCurrentMonth);

    if (currentUser && !JSON.parse(userDetail).isSuperAdmin) {
      const innerQuery = new Parse.Query("Chargers");
      innerQuery.equalTo("CPO", currentUser.get("CPO"));
      parseQuery.matchesQuery("ChargePoint", innerQuery);
    }
  
    parseQuery.find().then((result) => {
      // console.log("Result", result);
  
      let totalEnergy = 0;
      let thisMonthEnergy = 0;
      let lastMonthEnergy = 0;
  
      let totalMoney = 0;
      let thisMonthMoney = 0;
      let lastMonthMoney = 0;
      let totalSession = 0;
      let thisMonthSession = 0;
      let lastMonthSession = 0;
      let newRow: any[] = [];
  
      result.forEach((item, index) => {
        const fleet = item?.get("User")?.get("Fleet");
        const fleetCost = fleet ? fleet?.get("FleetCost") : null; // Access FleetCost here
          // console.log("FleetCost rate",fleetCost);
        const fleetName = fleet ? fleet?.get("Name") : null;
        if (moment(item.get("createdAt")).isAfter(moment().startOf("month"))) {
          thisMonthEnergy = item.get("TotalEnergyConsumed")
            ? item.get("TotalEnergyConsumed") + thisMonthEnergy
            : 0 + thisMonthEnergy;
          // thisMonthMoney = item.get("TotalCost")
          //   ? item.get("TotalCost") + thisMonthMoney
          //   : 0 + thisMonthMoney;
          thisMonthMoney += fleetCost
          ? (parseFloat(item.get("TotalEnergyConsumed")?.toFixed(2)) || 0) * fleetCost
          : (item.get("TotalCost") || 0);
          thisMonthSession = thisMonthSession + 1;
        } else if (
          moment(item.get("createdAt")).isBetween(
            moment().subtract(1, "months").startOf("month"),
            moment().subtract(1, "months").endOf("month")
          )
        ) {
          console.log("Last Month Data",  moment(item.get("createdAt")).isBetween(
            moment().subtract(1, "months").startOf("month"),
            moment().subtract(1, "months").endOf("month")
          ));
          
          lastMonthEnergy = item.get("TotalEnergyConsumed")
            ? item.get("TotalEnergyConsumed") + lastMonthEnergy
            : 0 + lastMonthEnergy;
          // lastMonthMoney = item.get("TotalCost")
          //   ? item.get("TotalCost") + lastMonthMoney
          //   : 0 + lastMonthMoney;
          lastMonthMoney += fleetCost
          ? (parseFloat(item.get("TotalEnergyConsumed")?.toFixed(2)) || 0) * fleetCost
          : (item.get("TotalCost") || 0);
          lastMonthSession = lastMonthSession + 1;
        } else {
          totalEnergy = item.get("TotalEnergyConsumed")
            ? item.get("TotalEnergyConsumed") + totalEnergy
            : 0 + totalEnergy;
          totalMoney = item.get("TotalCost")
            ? item.get("TotalCost") + totalMoney
            : 0 + totalMoney;
          totalSession = totalSession + 1;
        }
  
        let car = item.get("Vehicle") ? item.get("Vehicle").get("Name") : "";
  
        let name = item?.get("User")?.get("FullName") || "";
      
        // let fleetCost = item.get("User")?.get("Fleet")?.get("FleetCost") || "";
  
        let newTime = "";
        if (item.get("TotalTimeConsumed") != null) {
          let timeInSeconds = item.get("TotalTimeConsumed");
          let hours = Math.floor(timeInSeconds / 3600);
          timeInSeconds = timeInSeconds - hours * 3600;
          let minutes = Math.floor(timeInSeconds / 60);
          timeInSeconds = timeInSeconds - minutes * 60;
          newTime =
            hours === 0
              ? `${minutes}m ${timeInSeconds}s`
              : `${hours}h ${minutes}m ${timeInSeconds}s`;
        }
  
        let rangeAdded = item.get("RangeAdded") || 0.0;
  
        let unit = "";
        if (item.get("ChargeBy") === "Time") {
          unit = "Hours";
        } else if (item.get("ChargeBy") === "Units") {
          unit = "kWh";
        } else {
          unit = "₹";
        }
  
        let ab = {
          id: index + 1,
          orderId: `${item.id}`,
          isLive: `${item.get("Live")}`,
          chargeFor: `${item.get("ChargeFor")} ${unit}`,
          customer: name,
          fleetCost: fleetCost,
          fleet : fleetName,
          location: `${item.get("Location")?.get("Name")}`,
          carCharged: car,
          aid: `${item.get("AID")}`,
          startTime: `${moment(item.get("createdAt")).format("lll")}`,
          endTime: `${moment(item.get("updatedAt")).format("lll")}`,
          date: `${moment(item.get("createdAt")).format("DD MMM YYYY")}`,
          // cost: "₹" + `${item.get("TotalCost")?.toFixed(2)}`, // Previous code
          cost: "₹" + item.get("TotalEnergyConsumed") * fleetCost, // New Code for fleet diffrent Price
          Cost: "₹" + `${item.get("TotalCost")?.toFixed(2)}`,
          meterCost: `${item.get("ChargePoint")?.get("Cost")}`,
          checkTax: `${item.get("ChargePoint")?.get("inclusiveTax")}`,
          energy: `${item.get("TotalEnergyConsumed")} kWh`,
          rangeAdded: rangeAdded?.toFixed(2) + " km",
          time: newTime,
          serialNum: `${item.get("ChargePoint")?.get("Serial")}`,
          power: `${item.get("ChargePoint")?.get("Power")}`,
          connector: `${item.get("ChargePoint")?.get("Connector")}`,
          tariff: `${item.get("ChargePoint")?.get("Cost")}`,
          chargerGunID : `${item.get("ChargeGunId")}`,
          duration: `${
            moment
              .duration(
                moment(new Date(item.get("updatedAt"))).diff(
                  moment(new Date(item.get("createdAt")))
                )
              )
              .hours() +
            "hr " +
            moment
              .duration(
                moment(new Date(item.get("updatedAt"))).diff(
                  moment(new Date(item.get("createdAt")))
                )
              )
              .minutes() +
            "min"
          }`,
          obj: item,
        };
  
        if (item.get("Live") && item.get("ChargePoint").get("isOCPP")) {
          getOcppData(item.get("TransactionId"), ab, item.get("createdAt"));
        } else {
          newRow.push(ab);
        }
      });
  
      setUsage({
        thisMonth: thisMonthEnergy,
        lastMonth: lastMonthEnergy,
        total: totalEnergy,
      });
      setBilledRevenue({
        thisMonth: thisMonthMoney,
        lastMonth: lastMonthMoney,
        total: totalMoney,
      });
      setAvRevenue({
        thisMonth: thisMonthMoney / thisMonthSession,
        lastMonth: lastMonthMoney / lastMonthSession,
        total: totalMoney / totalSession,
      });
      setDataRow(newRow);
      OcppDataCircularChart();
      setTableLoading(false);
    });
  };
  
  // console.log("Data of CC",dataRow);
  
  
  let userDetail: any = sessionStorage.getItem("user-details");
  const [piChartData, setAPiChartData] = useState({
    available: 0,
    charging: 0,
    connected: 0,
    finishing:0,
    outOfOrder: 0,
    offline: 0,
    // scheduled: ScheduledCount,
    scheduled: upComingBookingCount,
    suspendedEv: 0,
  });

  const [allActiveChargerSerial, setActiveChargerSerial] = useState();

  const OcppDataCircularChart = () => {
    const chargersQuery = new Parse.Query("Chargers");
    if (currentUser && !JSON.parse(userDetail).isSuperAdmin) {
      chargersQuery.equalTo("CPO", currentUser.get("CPO"));
    }

    chargersQuery.find().then((chargeResult) => {
      let chargeCodeArray: any = [];
      let totalChargers: any = 0;
      let chargerType: any = [
        "3.3kW",
        "7.2kW",
        "11kW",
        "22kW",
        "25kW",
        "30kW",
        "60kW",
        "120kW",
        "250kW",
      ];
      let count_1: any = 0;
      let count_2: any = 0;
      let count_3: any = 0;
      let count_4: any = 0;
      let count_5: any = 0;
      let count_6: any = 0;
      let count_7: any = 0;
      let count_8: any = 0;
      let count_9: any = 0;
      let count_10: any = 0;
      chargeResult.forEach((chargePoint) => {
        if (chargePoint.get("isOCPP")) {
          switch (String(chargePoint.get("Power"))) {
            case "3.3 kW":
              count_1 = count_1 + 1;
              break;
            case "7.2 kW":
              count_2 = count_2 + 1;
              break;

            case "11 kW":
              count_4 = count_4 + 1;
              break;
            case "22 kW":
              count_5 = count_5 + 1;
              break;
            case "25 kW":
              count_6 = count_6 + 1;
              break;
            case "30 kW":
              count_7 = count_7 + 1;
              break;
            case "60 kW":
              count_8 = count_8 + 1;
              break;
            case "120 kW":
              count_9 = count_9 + 1;
              break;
            case "250 kW":
              count_10 = count_10 + 1;
              break;
            default:
              break;
          }
          chargeCodeArray.push(chargePoint.get("ChargeId"));
          totalChargers = totalChargers + 1;
        }
      });
      // console.log("chargeCodeArray", chargeCodeArray);

      let charging = 0;
      let connected = 0;
      let online = 0;
      let Faulted = 0;
      let offline = 0;
      let available = 0;
      let finishing = 0;
      let suspendedEv = 0;

      fetch(`${process.env.REACT_APP_OCPP_BASE_URL}/active_clients`)
        .then((response: any) => response.json())
        .then((res: any) => {
          res.forEach((el: any) => {
            // console.log(
            //   "ActiveClients",
            //   res.map(
            //     (ele: any) =>
            //       ele?.charger_attributes[0].charge_point_serial_number
            //   )
            // );
            // console.log(
            //   "ActiveClients",
            //   res.map(
            //     (ele: any) =>
            //       ele?.charger_attributes[0].charge_box_serial_number
            //   )
            // );

            //Connector number 1 need to check
            if (chargeCodeArray.includes(el.name)) {
              const connectorNumOne = el.connectors.find(
                (connector: any) => connector.connector_num === 1
              );

              if (connectorNumOne && connectorNumOne.status === "Faulted") {
                Faulted++;
              }
              if (el.is_active === true) {
                online = online + 1;
              }
              if (connectorNumOne && connectorNumOne.status === "Charging") {
                charging++;
              }
              if (connectorNumOne && connectorNumOne.status === "Preparing") {
                // alert("Preparing");
                connected++;
              }

              if (connectorNumOne && connectorNumOne.status === "Available") {
                available++;
              }
              if (connectorNumOne && connectorNumOne.status === "Finishing") {
                finishing++;
              }

              if (
                connectorNumOne &&
                connectorNumOne.status === "SuspendedEV"
              ) {
                suspendedEv++;
              }
            }
            let activeChargerIds: any[] = [];
            el.charger_attributes.forEach((attribute: any) => {
              const chargeBoxSerial = attribute.charge_box_serial_number;
              const chargePointSerial = attribute.charge_point_serial_number;

              if (chargeBoxSerial) {
                setActiveChargerSerial(chargeBoxSerial);
                activeChargerIds.push(chargeBoxSerial);
              } else if (chargePointSerial) {
                setActiveChargerSerial(chargePointSerial);
                activeChargerIds.push(chargePointSerial);
              }
            });
            // setActiveChargerSerial(activeChargerId)
          });

          setAlertData({
            ...alertData,
            chargingPointOnline: available,
            chargingSession: charging,
            chargerConnected: connected,
            chargingFinishing: finishing,
            chargingFailure: Faulted,
            chargingPointOffline: totalChargers - (available + charging),
          });

          setAPiChartData({
            ...piChartData,
            available: available,
            connected: connected,
            finishing: finishing,
            charging: charging,
            offline: totalChargers - (available + charging),
            // scheduled: ScheduledCount,
            scheduled: upComingBookingCount,
            suspendedEv: suspendedEv,
          });
         
          setChargerTypeData([
            { name: "AC 3.3kW", value: count_1, color: "#6AD3A7" },
            { name: "AC 7.2kW", value: count_2, color: "#FFDF8E " },

            // { name: "AC 11kW", value: count_4, color: "#5185EC" },
            // { name: "DC 15kW", value: count_4, color: "#5185EC" },
            { name: "AC 22kW", value: count_5, color: "#FFA38E" },
            // { name: "DC 25kW", value: count_6, color: "#9158A5" },
            { name: "DC 30kW", value: count_7, color: "#8EB5FF" },
            { name: "DC 60kW", value: count_8, color: "#ff3fd2" },
            // { name: "DC 120kW", value: count_9, color: "#33FFF6" },
            // { name: "DC 250kW", value: count_10, color: "#FF336E" },
          ]);
        });
    });
  };

  // console.log("allActiveChargerSerial", allActiveChargerSerial);

  const [chargerStatus, setChargerStatus] = useState<any>([
    { name: "Available", value: 0, color: "#5185EC" },
    { name: "Charging", value: 0, color: "#58A55C" },
    { name: "Connected", value: 0, color: "#6fff77" },
    { name: "Out of Order", value: 0, color: "#D85140" },
    { name: "Offline", value: 0, color: "#F1BE42" },
    { name: "Scheduled", value: 0, color: "#9158A5" },
    { name: "Suspended EV", value: 0, color: "#ff0505" },
  ]);
  useEffect(() => {
    setChargerStatus([
      { name: "Available", value: piChartData?.available, color: "#6AD3A7" },
      { name: "Charging", value: piChartData?.charging, color: "#8EB5FF" },
      { name: "Connected", value: piChartData?.connected, color: "#8EB5FF" },
      { name: "Finishing", value: piChartData?.finishing, color: "#f37430" },
      { name: "Out of Order", value: 0, color: "#8EB5FF" },
      { name: "Offline", value: piChartData.offline, color: "#FFDF8E" },
      { name: "Scheduled", value: upComingBookingCount, color: "#9E8EFF" },
      { name: "Suspended EV", value: piChartData.suspendedEv, color: "#ff0505" },
    ]);
  }, [
    piChartData.available,
    piChartData.connected,
    piChartData.charging,
    upComingBookingCount,
    piChartData.offline,
  ]);
  const [chargerTypeData, setChargerTypeData] = useState<any>([
    { name: "AC 22kW", value: 0, color: "#5185EC" },
  ]);

  const charging = alertData?.chargingSession;

  const [currentHeading, setCurrentHeading] = useState(0);
  const [animationClass, setAnimationClass] = useState("fade-in");

  const [chargersInfo, setChargersInfo] = useState<ChargerInfo[]>([]);

  useEffect(() => {
    const fetchApiData = async () => {
      try {
        // Fetch data from the API
        const response = await fetch(
          "https://ocpp.chargecity.co.in/api/v1/active_clients"
        );
        if (!response.ok) {
          throw new Error(`Error fetching API: ${response.statusText}`);
        }
        const apiData = await response.json();

        // Define the allowed statuses
        const allowedStatuses = [
          "Charging",
          "Preparing",
          "Connected",
          "SuspendedEvs",
          "Faulted",
        ];

        // Extract serial numbers and connectors info
        const chargers = apiData.reduce((acc: ChargerInfo[], item: any) => {
          const chargerAttributes = item?.charger_attributes?.[0];
          const serial =
            chargerAttributes?.charge_box_serial_number ||
            chargerAttributes?.charge_point_serial_number ||
            null;

          if (serial) {
            // Find the connector with connector_num 1
            const connector = item.connectors.find(
              (connector: any) => connector.connector_num === 1
            );

            // If connector is found, save its status
            if (connector && allowedStatuses.includes(connector.status)) {
              const status = connector.status;
              acc.push({ serial, connectors: status, locationName: "" }); // Placeholder for locationName
            }
          }

          return acc;
        }, []);

        // Query Parse for Chargers and include location pointer
        const chargersQuery = new Parse.Query("Chargers");
        if (currentUser && !JSON.parse(userDetail).isSuperAdmin) {
          chargersQuery.equalTo("CPO", currentUser.get("CPO"));
        }
        chargersQuery.include("Location"); // Include the location pointer

        const chargeResult = await chargersQuery.find();

        // Create a map for quick lookup of serial to location name
        const serialToLocationMap = chargeResult.reduce(
          (map: Record<string, string>, chargePoint: any) => {
            const serial = chargePoint.get("Serial");
            const location = chargePoint.get("isEnabled")
              ? chargePoint.get("Location")
              : null;
            if (serial && location) {
              map[serial] = location.get("Name") || "Unknown Location";
            }
            return map;
          },
          {}
        );

        // Map location names to the corresponding chargers
        const updatedChargers = chargers.map((charger: any) => {
          charger.locationName =
            serialToLocationMap[charger.serial] || "Unknown Location";
          return charger;
        });

        // Update state with the combined charger info
        setChargersInfo(updatedChargers);
      } catch (error) {
        console.error("Error fetching API data:", error);
      }
    };

    fetchApiData();
  }, []);

  // console.log("chargersInfo", chargersInfo);

  useEffect(() => {
    // Set an interval to update the current heading every 5 seconds
    const interval = setInterval(() => {
      setAnimationClass("fade-out");
      setTimeout(() => {
        setCurrentHeading(
          (prevHeading) => (prevHeading + 1) % chargersInfo.length
        );
        setAnimationClass("fade-in");
      }, 500); // Adjust this to match the duration of the fade-out
    }, 5000);

    return () => clearInterval(interval);
  }, [chargersInfo.length]);

  return (
    <>
      <div className="home_main_container">
        <div className="left_container   ">
          <div className="tab_Heading_container left">
            <h1 className="font-lexend text-xl font-semibold mb-2 mt-2">
              Dashboard
            </h1>
            <div className="detailed_heading">
           <ChargerStatusWidget />
            </div>
          </div>
          <Chart />
          <DatabaseTable data={dataRow} loading={tableLoading} />

          {/* Circular graph */}

          <div
            style={{ margin: "31px 9px", width: "97%" }}
            className="left_stats tablet_circle"
          >
            <XChart type="Charger Status and Alerts" data={chargerStatus} />
          </div>
        </div>
        <div className="right_container ">
          <div className="right_wrapper">
            <div className="tab_Heading_container right">
              <h1 className="tab_heading">Dashboard</h1>
              <div className="detailed_heading">
              <ChargerStatusWidget />
              </div>
            </div>
            <div className="cStatus_container">
              <div className="chargeProcessContainer chargeContainer">
                {/* charge in process */}
                <h2 className="card_heading">Charging in Progress</h2>
                <h1 className="chargeProcessCount">{charging}</h1>
              </div>
              <div className="faultContainer chargeContainer">
                {/* charge in process */}
                <h2 className="card_heading">Faults detected</h2>
                <h1 className="faultCount">{alertData?.chargingFailure}</h1>
              </div>
            </div>
            <div className="widgets widgets_container">
              <div className="card_container_stats">
                <Widget type="usage" data={usage} />
              </div>
              <div className="card_container_stats">
                <Widget type="customers" data={customerEnrollment} />
              </div>
              <div className="card_container_stats mt-2">
                <Widget type="billedRevenue" data={billedRevenue} />
              </div>
              <div className="card_container_stats mt-2">
                <Widget type="avRevenue" data={avRvenue} />
              </div>
            </div>
            <div className="statics_container ">
              {/* <Warnings data={alertData} className='hidden' /> */}
              <div className="left_stats tablet_res">
                <XChart type="Charger Status and Alerts" data={chargerStatus} />
              </div>
              <div className="right_stats tablet_res">
                <XChart
                  type="Charger Type"
                  data={chargerTypeData.filter((item: any) => item.value)}
                />
              </div>
            </div>
            <div
              style={{ margin: "4px 9px", width: "97%" }}
              className="right_stats tablet_circle ml-5"
            >
              <XChart
                type="Charger Type"
                data={chargerTypeData.filter((item: any) => item.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
