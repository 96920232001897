import { memo, useEffect, useState } from "react";
/* 3rd Party Libraries */
import { Autocomplete, TextField, Button } from "@mui/material";
import { Checkbox, Chip } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
/* Location Table */
import DatabaseTable from "./databasetable";
/* Add Edit Location Modal */
import AddEditModal from "./addEditModal/AddEditModal";
/* Redux Toolkit */
import { useAppDispatch } from "../../store/store";
import { getAllConnectors } from "../../store/features/connectorTypeSlice";
import { getAllVendors } from "../../store/features/vendorsSlice";
import { getAllCpo } from "../../store/features/allCpoSlice";
/* Scss file */
import "./stationList.scss";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
interface dataObj {
  id: string;
  label: string;
}
interface LocationFilter {
  id: string;
  label: string;
}
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const StationList = memo(() => {
  /* -------------------------------------------------------------------------- */
  /*                            Current User Details                            */
  /* -------------------------------------------------------------------------- */
  let userDetail: any = sessionStorage.getItem("user-details");
  const currentUser: any = Parse.User.current();

  /* -------------------------------------------------------------------------- */
  /*                Prefetched Data for Add Edit Modals Dropdown                */
  /* -------------------------------------------------------------------------- */
  const dispatch = useAppDispatch();

  const [isFilterVisible, setIsFilterVisible] = useState(false); // Filter Visibility Toggle State

  // Filter Code Start
  const [locationFilter, setLocationFilter] = useState<LocationFilter[]>([]);
  const [cityFilters, setCityFilters] = useState<any[]>([]);
  const [locationIds, setLocationIds] = useState<any[]>([]);
  const [statusChips, setStatusFilterChips] = useState<any[]>([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [locationChips, setLocationChips] = useState<
    { id: string; label: string }[]
  >([]);
  const [filterChips, setFilterChips] = useState<
    { id: string; label: string }[]
  >([]);
  const handleFilterButtonClick = () => {
    // Toggle filter visibility
    setIsFilterVisible((prev) => !prev);
  };

  const getAllConnectorType = () => {
    const Locations = Parse.Object.extend("ConnectorTypes");
    const parseQuery = new Parse.Query(Locations);

    parseQuery.limit(50);
    parseQuery.find().then((result) => {
      let addressArray: any = [];
      result.forEach((item) => {
        addressArray.push({
          id: item.id,
          name: item.get("Name"),
          type: item.get("CurrentType"),
        });
      });
      dispatch(getAllConnectors(addressArray));
    });
  };

  const getAllVendorsType = () => {
    const Vendors = Parse.Object.extend("CP_Vendor");
    const parseQuery = new Parse.Query(Vendors);

    parseQuery.limit(50);
    parseQuery.find().then((result) => {
      let vendorsArray: any = [];
      result.forEach((item) => {
        let ab = {
          id: item.id,
          name: item.get("Name"),
        };
        vendorsArray.push(ab);
      });
      dispatch(getAllVendors(vendorsArray));
    });
  };

  const getAllCPO = () => {
    const Vendors = Parse.Object.extend("ChargePointOperators");
    const parseQuery = new Parse.Query(Vendors);

    parseQuery.limit(50);
    parseQuery.find().then((result) => {
      let vendorsArray: any = [];
      result.forEach((item) => {
        let ab = {
          id: item.id,
          name: item.get("Name"),
        };
        vendorsArray.push(ab);
      });
      dispatch(getAllCpo(vendorsArray));
    });
  };
  useEffect(() => {
    getAllConnectorType();
    getAllVendorsType();
    getAllCPO();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                                Filters State                               */
  /* -------------------------------------------------------------------------- */
  const [nameFilter, setNameFilter] = useState<dataObj>({ id: "", label: "" });
  const [locationTypeFilter, setLocationTypeFilter] = useState<any | null>(
    null
  );
  const [cityFilter, setCityFilter] = useState<any | null>(null);
  /* -------------------------------------------------------------------------- */
  /*               Data for Location type filter and Cities filter              */
  /* -------------------------------------------------------------------------- */
  const [allLocationType, setAllLocationType] = useState<any | null>([]);
  const [allCity, setAllCity] = useState<any | null>([]);
  const getAllLocations = () => {
    const Locations = Parse.Object.extend("Locations");

    const parseQuery = new Parse.Query(Locations);

    parseQuery.limit(50);

    parseQuery.find().then((result) => {
      let addressOptions: dataObj[] = [];
      let locationArray: any[] = [];
      let locationType: any[] = [];
      let cityType: any[] = [];
      result.forEach((item, index) => {
        if (
          !cityType.find(function (i) {
            return i === item.get("City");
          })
        ) {
          cityType.push(item.get("City"));
        }

        if (
          !locationType.find(function (i) {
            return i === item.get("LocationType");
          })
        ) {
          locationType.push(item.get("LocationType"));
        }

        let locPoint = item.get("GeoLocation");

        locationArray.push({
          id: item.id,
          stationName: `${item.get("Name")}`,
          type: `${item.get("LocationType")}`,
          city: `${item.get("City")}`,
          access: `${
            item.get("hasRestrictedAccess") ? "Restricted" : "Public"
          }`,
          accessType : `${item.get("accessType")}`,
          operator: "Charge City",
          address: `${item.get("Address")}`,
          state: `${item.get("State")}`,
          lat: `${locPoint?.latitude}`,
          long: `${locPoint?.longitude}`,
          openingTime: `${moment(item.get("OpenTime"), "hh:mm A")}`,
          closingTime: `${moment(item.get("CloseTime"), "hh:mm A")}`,
          electricityTariff: `${item.get("ElectricityTariff")}`,
          isEnabled: `${item.get("isEnabled") ? "true" : "false"}`,
          modelType: `${item.get("RevenueModel")}`,
          currency: "₹",
          revenueAmount: `${item.get("RevenueAmount")}`,
          revenuePercent: `${item.get("RevenueSharingType")}`,
          rentalAmount: `${item.get("RentalAmount")}`,
          amenities: item.get("Amenities"),
          description: `${item.get("Description")}`,
          connectorType: item.get("ConnectorType")
            ? item.get("ConnectorType")
            : [],
          currentType: item.get("CurrentType") ? item.get("CurrentType") : [],
        });

        addressOptions.push({
          id: item.id,
          label: item.get("Name"),
        });
      });

      // const allCity = cityType.map((city, index) => ({ id: index.toString(), label: city }));

      setAllCity(cityType);
      setAllLocationType(locationType);
    });
  };
  useEffect(() => {
    getAllLocations();
  }, []);

  useEffect(() => {
    if (Array.isArray(locationFilter)) {
      const locationIds = locationFilter.map(
        (location: any) => location?.label
      );

      setLocationIds(locationIds);

      if (locationIds.length > 0) {
        // Your logic when userFilter is an array
      }
    } else if (locationFilter && typeof locationFilter === "object") {
      const locationId = (locationFilter as { id?: string }).id;

      setLocationIds(locationId ? [locationId] : []);

      // Your logic when userFilter is an object
    } else {
      // Handle other cases when userFilter is not an array or object
    }
  }, [locationFilter]);

  const handleStatusChange = (value: string) => {
    // Find if the selected date is already in the chips
    const existingChip = statusChips.find((chip) => chip.label === value);

    // If the selected date is not in the chips, update the chips array
    if (!existingChip) {
      // Remove the previous date chip if it exists
      const updatedChips = statusChips.filter(
        (chip) => chip.id !== statusFilter
      );

      setStatusFilterChips([...updatedChips, { id: value, label: value }]);
    }
    setLocationTypeFilter(value);
    setStatusFilter((prevValue) => (prevValue === value ? "" : value));
  };

  // Handle Delete Chip Location
  const handleLocationDeleteChip = (chipId: string) => {
    console.log("Deleting chip with id:", chipId);

    // Remove the corresponding chip from the state
    setLocationChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== chipId)
    );

    // Remove the location filter
    setLocationIds((prevUserIds) => prevUserIds.filter((id) => id !== chipId));

    setLocationFilter((prevUserFilter: any) =>
      prevUserFilter.filter((filter: any) => filter.id !== chipId)
    );
  };
  const handleCityDeleteChip = (chipId: string) => {
    console.log("Deleting chip with id:", chipId);

    // Remove the corresponding chip from the state
    setLocationChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== chipId)
    );

    // Remove the location filter
    setLocationIds((prevUserIds) => prevUserIds.filter((id) => id !== chipId));

    setCityFilters((prevUserFilter: any) =>
      prevUserFilter.filter((filter: any) => filter !== chipId)
    );
  };

  // Chip delete Status
  const handleDeleteStatusChip = (chipId: string) => {
    // Remove the corresponding chip from the state
    setStatusFilterChips((prevChips) =>
      prevChips.filter((chip) => chip.id !== chipId)
    );

    setLocationTypeFilter("");
    // Clear the selected status
    setStatusFilter("");
  };

  console.log("LocationIDS", locationIds);
  console.log("locationFilter", locationFilter);

  /* -------------------------------------------------------------------------- */
  /*                             Location Table Data                            */
  /* -------------------------------------------------------------------------- */
  /**
   * Super Admin- All Data
   * Others- Data filtered for CPOs
   */

  const [tableData, setTableData] = useState<any>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [allLocations, setAllLocations] = useState<any | null>([]);
  const [showAddEditLocationModal, setShowAddEditLocationModal] =
    useState(false);

  const getFilteredLocation = (
    nameId: string,
    typeId: string,
    cityId: string,
    locationIds: string[],
    cityFilters: string[]
  ) => {
    setTableLoading(true);
    const chargersQuery = new Parse.Query("Chargers");
    /* All data for Super Admin */
    if (currentUser) {
      chargersQuery.equalTo("CPO", currentUser.get("CPO"));
    }
    /*<--------------------------------------------------------->*/
    chargersQuery.find().then((chargeResult) => {
      let locArray: any = [];
      chargeResult.map((chargePoint) => {
        if (!locArray.includes(chargePoint.get("Location").id)) {
          locArray.push(chargePoint.get("Location").id);
        }
      });

      const locationQuery = new Parse.Query("Locations");
      console.log("Location IDs:", locationIds);
      if (locationIds.length > 0) {
        locationQuery.containedIn("Name", locationIds);
      }
      if (cityFilters.length > 0) {
        locationQuery.containedIn("City", cityFilters);
      }
      // if (nameId) {
      //   locationQuery.equalTo("objectId", nameId);
      // }
      if (typeId) {
        locationQuery.equalTo("LocationType", typeId);
      }
      if (cityId) {
        locationQuery.equalTo("City", cityId);
      }
      !JSON.parse(userDetail).isSuperAdmin &&
        locationQuery.containedIn("objectId", locArray);

      locationQuery.find().then((result) => {
        let locationArray: any = [];
        let addressOptions: any = [];
        result.forEach((item) => {
          let locPoint = item.get("GeoLocation");
          locationArray.push({
            id: item.id,
            stationName: `${item.get("Name")}`,
            type: `${item.get("LocationType")}`,
            city: `${item.get("City")}`,
            access: `${
              item.get("hasRestrictedAccess") ? "Restricted" : "Public"
            }`,
            accessType : `${item.get("accessType")}`,
            operator: "Charge City",
            address: `${item.get("Address")}`,
            state: `${item.get("State")}`,
            lat: `${locPoint?.latitude}`,
            long: `${locPoint?.longitude}`,
            openingTime: `${moment(item.get("OpenTime"), "hh:mm A")}`,
            closingTime: `${moment(item.get("CloseTime"), "hh:mm A")}`,
            electricityTariff: `${item.get("ElectricityTariff")}`,
            isEnabled: `${item.get("isEnabled") ? "true" : "false"}`,
            modelType: `${item.get("RevenueModel")}`,
            currency: "₹",
            revenueAmount: `${item.get("RevenueAmount")}`,
            revenuePercent: `${item.get("RevenueSharingType")}`,
            rentalAmount: `${item.get("RentalAmount")}`,
            amenities: item.get("Amenities"),
            description: `${item.get("Description")}`,
            connectorType: item.get("ConnectorType")
              ? item.get("ConnectorType")
              : [],
            currentType: item.get("CurrentType") ? item.get("CurrentType") : [],
          });
          addressOptions.push({
            id: item.id,
            label: item.get("Name"),
          });
        });

        console.log("locationArray", locationArray.length);

        setTableData(locationArray);
        setTableLoading(false);
        setAllLocations(allLocations.length ? allLocations : addressOptions);
      });
    });
  };
  useEffect(() => {
    getFilteredLocation(
      nameFilter?.id,
      locationTypeFilter,
      cityFilter,
      locationIds,
      cityFilters
    );
  }, [locationIds, cityFilters, locationTypeFilter, cityFilter, nameFilter]);

  /* -------------------------------------------------------------------------- */
  /*                     Active Clients Data (Only Ocpp Chargers)                 */
  /* -------------------------------------------------------------------------- */

  /**
   * status
   * energy.
   * cost
   * duration*/

  const [allOcppStatus, setAllOcppStatus] = useState([]);
  const OcppData = () => {
    fetch(`${process.env.REACT_APP_OCPP_BASE_URL}/active_clients`)
      .then((response: any) => response.json())
      .then((res: any) => {
        let ab: any = [];
        res.forEach((el: any) => {
          ab.push({
            id:
              el.charger_attributes[0].charge_box_serial_number ||
              el.charger_attributes[0].charge_point_serial_number,
            status: el.is_active
              ? el.connectors.filter((item: any) => item.connector_num === 1)[0]
                  .status
              : "Offline",
            active: el.is_active,
            ocppName: el.name,
          });
        });
        setAllOcppStatus(ab);
      });
  };

  useEffect(() => {
    OcppData();
  }, []);

  console.log("Data table", tableData);

  return (
    <div className="station-container">
      <div className="flex">
        <div style={{ width: "100%" }}>
          <div className="flex justify-between mx-6 mt-5 ">
            <h2 className="transaction_heading mt-2 font-lexend text-2xl">
              Location
            </h2>
            <div className="button_container flex ">
              <Tooltip title="Add Location">
                <button
                  className="flex justify-around items-center px-3 py-2 rounded-full mr-3"
                  style={{ background: "#1AC47D", border: "2px solid #1AAD70" }}
                  onClick={() => setShowAddEditLocationModal(true)}
                >
                  <span className="mr-2">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.75 9H11.25M9 6.75V11.25M2.25 9C2.25 9.88642 2.42459 10.7642 2.76381 11.5831C3.10303 12.4021 3.60023 13.1462 4.22703 13.773C4.85382 14.3998 5.59794 14.897 6.41689 15.2362C7.23583 15.5754 8.11358 15.75 9 15.75C9.88642 15.75 10.7642 15.5754 11.5831 15.2362C12.4021 14.897 13.1462 14.3998 13.773 13.773C14.3998 13.1462 14.897 12.4021 15.2362 11.5831C15.5754 10.7642 15.75 9.88642 15.75 9C15.75 8.11358 15.5754 7.23583 15.2362 6.41689C14.897 5.59794 14.3998 4.85382 13.773 4.22703C13.1462 3.60023 12.4021 3.10303 11.5831 2.76381C10.7642 2.42459 9.88642 2.25 9 2.25C8.11358 2.25 7.23583 2.42459 6.41689 2.76381C5.59794 3.10303 4.85382 3.60023 4.22703 4.22703C3.60023 4.85382 3.10303 5.59794 2.76381 6.41689C2.42459 7.23583 2.25 8.11358 2.25 9Z"
                        stroke="white"
                        stroke-width="1.6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span style={{ color: "white" }}>Add Location</span>
                </button>
              </Tooltip>
              <Tooltip title="Filter">
                <button
                  className="filter_button rounded-full p-3 border-2 border-black-600"
                  onClick={handleFilterButtonClick}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M3 3H15V4.629C14.9999 5.02679 14.8418 5.40826 14.5605 5.6895L11.25 9V14.25L6.75 15.75V9.375L3.39 5.679C3.13909 5.40294 3.00004 5.0433 3 4.67025V3Z"
                      stroke="#111111"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </Tooltip>
            </div>
</div>
          <div className="filter-chips-container flex flex-wrap ml-4">
            {/* Location Chips */}
            {locationChips.map((chip) => (
              <Chip
                key={chip.id}
                label={chip.label}
                onDelete={() => handleLocationDeleteChip(chip.id)}
                variant="outlined"
                sx={{ marginLeft: "5px", marginBottom: "10px" }}
              />
            ))}
            {cityFilters.map((chip) => (
              <Chip
                key={chip}
                label={chip}
                onDelete={() => handleCityDeleteChip(chip)}
                variant="outlined"
                sx={{ marginLeft: "5px", marginBottom: "10px" }}
              />
            ))}
            {statusChips.map((chip) => (
              <Chip
                key={chip.id}
                label={chip.label}
                onDelete={() => handleDeleteStatusChip(chip.id)}
                variant="outlined"
                sx={{ marginLeft: "5px", marginBottom: "10px" }}
              />
            ))}
          </div>

          {/* Data Table  */}

          <DatabaseTable
            dataRow={tableData}
            liveOcppData={allOcppStatus}
            refreshLocation={() =>
              getFilteredLocation(
                nameFilter.id,
                locationTypeFilter,
                cityFilter,
                locationIds,
                cityFilters
              )
            }
            loading={tableLoading}
            setIsFilterVisible={setIsFilterVisible}
          />

          {/* Add Edit Modal */}
          <AddEditModal
            show={showAddEditLocationModal}
            handleClose={() => setShowAddEditLocationModal(false)}
            type="add"
            data={{
              stationName: "",
              address: "",
              city: "",
              state: "",
              country: "",
              type: "",
              lat: "",
              long: "",
              openingTime: "",
              closingTime: "",
              daysOpen: "",
              chargingTariff: "",
              electricityTariff: "",
              currency: "₹",
              tax: "",
              access: "",
              accessType:"",
              authType: "",
              paymentReq: "",
              modelType: "",
              isEnabled: "",
              revenueAmount: "",
              revenuePercent: "",
              rentalAmount: "",
              description: "",
              amenities: [],
              connectorType: [],
            }}
            refreshLocation={() =>
              getFilteredLocation(
                nameFilter.id,
                locationTypeFilter,
                cityFilter,
                locationIds,
                cityFilters
              )
            }
            allChargePointData={[]}
          />
        </div>
        {isFilterVisible && (
          <div className="filters lg:w-1/3 flex flex-col  border-2 border-black-400">
            <div className="filter_header z-50 sticky top-0 right-0 flex justify-between px-4 py-5 mb-8">
              <h1 className="font-semibold font-lexend text-lg">Filters</h1>
              <button
                style={{
                  background: "#1AC47D",
                  width: "35px",
                  height: "35px",
                  padding: "5px",
                  borderRadius: "50%",
                  border: "3px solid #1AAD70",
                }}
                onClick={handleFilterButtonClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M3.33301 8.00033L6.66634 11.3337L13.333 4.66699"
                    stroke="white"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>

            <div className="filter_main_container px-4">
              <h1 className="font-semibold font-lexend text-lg mb-2">Name</h1>
              <Autocomplete
                className="mb-4"
                freeSolo
                id="checkboxes-tags-demo"
                disableClearable
                multiple
                limitTags={-1}
                options={allLocations}
                // loading={loadingUsers}
                autoHighlight
                size="small"
                value={locationFilter} // Set the value to control the selected Location
                onChange={(event: any, newValue: any) => {
                  console.log("New Value", newValue);

                  setLocationChips(
                    (prevChips: { id: string; label: string }[]) => {
                      // Ensure that newValue is an array of objects with 'id' property
                      const newChips: { id: string; label: string }[] =
                        newValue.map((user: any) => ({
                          id: user.id,
                          label: `Location: ${user.label}`,
                        }));

                      const filteredChips = newChips.filter(
                        (chip) =>
                          !prevChips.some((prevChip) => prevChip.id === chip.id)
                      );

                      return [...prevChips, ...filteredChips];
                    }
                  );
                  setLocationFilter(newValue);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option: any) => option.label}
                getOptionDisabled={(option) =>
                  locationFilter.some((chip) => chip.id === option.id)
                }
                renderOption={(props, option) => {
                  let counter = 0;
                  const uniqueKey = `${option.id}_${option.label}_${counter++}`;
                  const selected = filterChips.some(
                    (chip) => chip.id === option.id
                  );
                  // console.log("selected", selected);

                  return (
                    <MenuItem key={uniqueKey} {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={
                          selected ||
                          locationFilter.some((chip) => chip.id === option.id)
                        }
                      />
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  );
                }}
                renderInput={(params: any) => (
                  <TextField {...params} label="Search" />
                )}
              />

              <hr className="mt-3" />

              {/* Type Filter */}
              <div className="mt-3">
                <h1 className="font-semibold font-lexend text-lg">Type</h1>
                <div className="flex">
                  <input
                    type="radio"
                    className="mr-3"
                    checked={locationTypeFilter === "Public Parking"}
                    onChange={() => handleStatusChange("Public Parking")}
                  />
                  <label htmlFor="" className="font-lexend">
                    Public Parking
                  </label>
                </div>
                <div className="flex">
                  <input
                    type="radio"
                    className="mr-3"
                    checked={locationTypeFilter === "Mall"}
                    onChange={() => handleStatusChange("Mall")}
                    />
                  <label htmlFor="" className="font-lexend">
                    Mall
                  </label>
                </div>
                <div className="flex">
                  <input
                    type="radio"
                    className="mr-3"
                    checked={locationTypeFilter === "Office Building"}
                    onChange={() => handleStatusChange("Office Building")}
                  />
                  <label htmlFor="" className="font-lexend">
                    Office Building
                  </label>
                </div>
                <div className="flex">
                  <input
                    type="radio"
                    className="mr-3"
                    checked={locationTypeFilter === "Residential"}
                    onChange={() => handleStatusChange("Residential")}
                  />
                  <label htmlFor="" className="font-lexend">
                    Residential
                  </label>
                </div>
              </div>

              <hr className="mt-3" />

              {/* City Filter */}
              <h1 className="font-semibold font-lexend text-lg mb-2 mt-3">
                City
              </h1>

              <Autocomplete
                className="mb-4"
                freeSolo
                id="checkboxes-tags-demo"
                disableClearable
                multiple
                limitTags={-1}
                options={allCity}
                autoHighlight
                size="small"
                value={cityFilters} // Set the value to control the selected Location
                onChange={(event: any, newValue: any) => {
                  console.log("New Value", newValue);
                  //   (prevChips: { id: string; label: string }[]) => {
                  //     // Ensure that newValue is an array of objects with 'id' property
                  //     const newChips: { id: string; label: string }[] =
                  //       newValue.map((user: any) => ({
                  //         id: user.id,
                  //         label: `Location: ${user.label}`,
                  //       }));

                  //     const filteredChips = newChips.filter(
                  //       (chip) =>
                  //         !prevChips.some((prevChip) => prevChip.id === chip.id)
                  //     );

                  //     return [...prevChips, ...filteredChips];
                  //   }
                  // );
                  setCityFilters(newValue);
                }}
                isOptionEqualToValue={(option, value) => option === value}
                // getOptionLabel={(option: any) => option.label}
                getOptionDisabled={(option) =>
                  cityFilters.some((chip) => chip === option)
                }
                renderOption={(props, option) => {
                  const selected = filterChips.some((chip) => chip === option);

                  return (
                    <MenuItem key={option.id} {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={
                          selected ||
                          cityFilters.some((chip) => chip === option)
                        }
                      />
                      <ListItemText primary={option} />
                    </MenuItem>
                  );
                }}
                renderInput={(params: any) => (
                  <TextField {...params} label="Search" />
                )}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default StationList;
