import React, { useEffect, useState, ChangeEvent } from "react";
import PropTypes from "prop-types";
import {
  useMediaQuery,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  alpha,
  InputBase,
  styled,
  Stack,
  Select,
  Checkbox,
  TextField,
  MenuItem,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import Diversity3Icon from "@mui/icons-material/Diversity3";

interface PropTypes {
  show: boolean;
  handleClose: any;
  type: string;
  data: any;
  refresh: any;
  allCPO: [];
  allFleet: [];
}
interface FormValues {
  id: string;
  name: string;
  email: string;
  phone: string;
  cpo: string;
  fleetId: string;
  access: string[];
  password: string;
}

const initialValues: FormValues = {
  id: "",
  name: "",
  email: "",
  phone: "",
  cpo: "",
  fleetId: "",
  access: ["FleetOperator"],
  password: "",
};

// All Access Role for User
let allRoles = sessionStorage.getItem("roles");
console.log("All Roles: ", allRoles);

function AddEditFleetRoleModal(props: PropTypes) {
  console.log("props edit data", props);

  const [values, setValues] = useState<FormValues>(initialValues);
  useEffect(() => {
    if (props.show && props.type == "edit fleet") {
      setValues(props.data);
    }
  }, [props.show]);
  useEffect(() => {
    if (props.show && props.type == "add") {
      setValues(initialValues);
    }
  }, [props.show]);
  const theme = useTheme();
  console.log("Selected USer access", values.access);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const submitAddUser = () => {
    if (
      values.name &&
      values.email &&
      values.phone &&
      values.password &&
     
      !error.phone &&
      !error.email
    ) {
      let myNewObject: Parse.Object = new Parse.Object("_User");
      myNewObject.set("CPO", {
        __type: "Pointer",
        className: "ChargePointOperators",
        objectId: values.cpo,
      });
      myNewObject.set("Fleet", {
        __type: "Pointer",
        className: "FleetOperator",
        objectId: values.fleetId,
      });
      myNewObject.set("FullName", values.name);
      myNewObject.set("email", values.email);
      myNewObject.set("password", values.password);
      myNewObject.set("Phone", values.phone);
      myNewObject.set("UserType", "Cloud");
      myNewObject.set("RoleAssigned", ["FleetOperator"]);
      myNewObject.set("username", values.email);
      myNewObject.set("allowDashboard", true);

      myNewObject
        .save()
        .then(() => {
          alert("Role Added successfully");
          props.handleClose();
          props.refresh();
        })
        .catch((error) => alert(error));
    } else {
      alert("Please Enter All Mandatory Fields");
    }
  };

  const submitEditUser = () => {
    try {
      const params = {
        userId: values.id,
        roles: values.access,
        name: values.name,
        email: values.email,
        phone: values.phone,
      };
      Parse.Cloud.run("updateUserRole", params).then(
        (status) => {
          alert("User Updated");
          props.handleClose();
          props.refresh();
        },
        (error: any) => {
          alert("Failed to add the user: " + error.message);
          props.handleClose();
          props.refresh();
        }
      );
    } catch (error: any) {
      alert("Failed to add the user " + error.message);
    }
  };

  const [error, setError] = useState({ phone: false, email: false });

  function isEmail(emailAddress: any) {
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (emailAddress.match(regex)) {
      setError({ ...error, email: false });
    } else {
      setError({ ...error, email: true });
    }
  }

  useEffect(() => {
    if (values.email) isEmail(values.email);
  }, [values.email]);

  function phoneNumber(phoneValue: any) {
    var phoneNo = /^\d{10}$/;
    if (phoneValue.match(phoneNo)) {
      setError({ ...error, phone: false });
    } else {
      setError({ ...error, phone: true });
    }
  }
  useEffect(() => {
    if (values.phone) phoneNumber(values.phone);
  }, [values.phone]);

  const handleCheckboxChange = (
    e: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    const checked = e.target.checked;
    setValues((prevValues) => {
      if (checked) {
        // If checkbox is checked, add the value to the array
        return {
          ...prevValues,
          access: [...prevValues.access, value],
        };
      } else {
        // If checkbox is unchecked, remove the value from the array
        return {
          ...prevValues,
          access: prevValues.access.filter((item) => item !== value),
        };
      }
    });
  };
  console.log("values........", values);

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: 900,
          height: 700,
        },
      }}
      maxWidth={"lg"}
      fullScreen={fullScreen}
      open={props.show}
      onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <div className="headItem">
          <span className="head1">
            {" "}
            {props.type == "add" ? (
              <span className="font-lexend font-bold ml-5">
                Add New Fleet Operator Role
              </span>
            ) : (
              <span className="font-lexend font-bold ml-5">
                Edit Fleet Operator Role
              </span>
            )}
          </span>{" "}
          <span className="head2" onClick={props.handleClose}>
            <CloseIcon />
          </span>
        </div>
      </DialogTitle>

      <DialogContent>
        <div></div>
        <Stack direction="column" spacing={1}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "17px",
            }}
          >
            <label
              className="flex items-center mb-2 mt-5"
              htmlFor="station name"
            >
              <span>
                <svg
                  width="15"
                  height="17"
                  viewBox="0 0 15 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 14.75V13.25C1.5 12.4544 1.81607 11.6913 2.37868 11.1287C2.94129 10.5661 3.70435 10.25 4.5 10.25H7.125M3 4.25C3 5.04565 3.31607 5.80871 3.87868 6.37132C4.44129 6.93393 5.20435 7.25 6 7.25C6.79565 7.25 7.55871 6.93393 8.12132 6.37132C8.68393 5.80871 9 5.04565 9 4.25C9 3.45435 8.68393 2.69129 8.12132 2.12868C7.55871 1.56607 6.79565 1.25 6 1.25C5.20435 1.25 4.44129 1.56607 3.87868 2.12868C3.31607 2.69129 3 3.45435 3 4.25ZM10.815 10.7075C10.9613 10.5612 11.1349 10.4452 11.326 10.3661C11.5171 10.2869 11.7219 10.2462 11.9288 10.2462C12.1356 10.2462 12.3404 10.2869 12.5315 10.3661C12.7226 10.4452 12.8962 10.5612 13.0425 10.7075C13.1888 10.8537 13.3048 11.0274 13.3839 11.2185C13.4631 11.4096 13.5038 11.6144 13.5038 11.8212C13.5038 12.0281 13.4631 12.2329 13.3839 12.424C13.3048 12.6151 13.1888 12.7887 13.0425 12.935L10.5 15.5H8.25V13.25L10.815 10.7075Z"
                    stroke="#111111"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>{" "}
              <span className="font-lexend ml-2">Name</span>
            </label>
            <TextField
              id="input-with-sx"
              variant="standard"
              placeholder="Enter Name"
              value={values.name}
              onChange={(e: any) =>
                setValues({
                  ...values,
                  name: e.target.value,
                })
              }
            />
          </div>

          <div className="flex">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "17px",
                width: "48%",
              }}
            >
              <label
                className="flex items-center mb-2 mt-5"
                htmlFor="station name"
              >
                <span>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.25 14.75L3.125 12.875M12.875 3.125L14.75 1.25M6.5 7.25L5 8.75M8.75 9.5L7.25 11M4.25 8L8 11.75L6.875 12.875C6.63025 13.1278 6.33763 13.3294 6.01419 13.468C5.69075 13.6066 5.34296 13.6795 4.99108 13.6824C4.6392 13.6852 4.29028 13.618 3.96463 13.4847C3.63898 13.3513 3.34313 13.1545 3.0943 12.9057C2.84548 12.6569 2.64866 12.361 2.51532 12.0354C2.38198 11.7097 2.31478 11.3608 2.31764 11.0089C2.32049 10.657 2.39335 10.3092 2.53196 9.98581C2.67057 9.66237 2.87217 9.36975 3.125 9.125L4.25 8ZM11.75 8L8 4.25L9.125 3.125C9.36975 2.87217 9.66237 2.67057 9.98581 2.53196C10.3092 2.39335 10.657 2.32049 11.0089 2.31764C11.3608 2.31478 11.7097 2.38198 12.0354 2.51532C12.361 2.64866 12.6569 2.84548 12.9057 3.0943C13.1545 3.34313 13.3513 3.63898 13.4847 3.96463C13.618 4.29028 13.6852 4.6392 13.6824 4.99108C13.6795 5.34296 13.6066 5.69075 13.468 6.01419C13.3294 6.33763 13.1278 6.63025 12.875 6.875L11.75 8Z"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>{" "}
                <span className="font-lexend ml-2">CPO</span>
              </label>
              <Select
                variant="standard"
                inputProps={{ "aria-label": "Without label" }}
                value={values.cpo}
              >
                {props.allCPO.map((item: any, idx) => (
                  <MenuItem
                    key={idx}
                    value={item.id}
                    onClick={() =>
                      setValues({
                        ...values,
                        access: ["FleetOperator", "Reports"],
                        cpo: item.id,
                      })
                    }
                  >
                    <Typography sx={{ fontFamily: "lexend" }}>
                      {console.log("item cpo....", item)}
                      {item.companyName}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </div>
            {/* Fleet Select */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "17px",
                width: "48%",
              }}
            >
              <label
                className="flex items-center mb-2 mt-5"
                htmlFor="station name"
              >
                <span>
                  <Diversity3Icon sx={{ width: "18px" }} />
                </span>{" "}
                <span className="font-lexend ml-2">Select Fleet</span>
              </label>
              <Select
                variant="standard"
                inputProps={{ "aria-label": "Without label" }}
                value={values.fleetId}
              >
                {props.allFleet.map((item: any, idx) => (
                  <MenuItem
                    key={idx}
                    value={item.id}
                    onClick={() =>
                      setValues({
                        ...values,
                        access: ["FleetOperator", "Reports"],
                        fleetId: item.id,
                      })
                    }
                  >
                    <Typography sx={{ fontFamily: "lexend" }}>
                      {console.log("item....", item)}
                      {item.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>

          <br />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "17px",
            }}
          >
            {props.type == "add" ? (
              <>
                <label className="flex items-center mb-2 " htmlFor="password">
                  <span>
                    <svg
                      width="12"
                      height="17"
                      viewBox="0 0 12 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.25 14.75H2.25C1.85218 14.75 1.47064 14.592 1.18934 14.3107C0.908035 14.0294 0.75 13.6478 0.75 13.25V8.75C0.75 8.35218 0.908035 7.97064 1.18934 7.68934C1.47064 7.40804 1.85218 7.25 2.25 7.25H9.75C10.0137 7.2499 10.2728 7.31931 10.5011 7.45125C10.7294 7.58318 10.9189 7.77298 11.0505 8.0015M3 7.25V4.25C3 3.45435 3.31607 2.69129 3.87868 2.12868C4.44129 1.56607 5.20435 1.25 6 1.25C6.79565 1.25 7.55871 1.56607 8.12132 2.12868C8.68393 2.69129 9 3.45435 9 4.25V7.25M11.25 11V13.25M11.25 15.5V15.5075M5.25 11C5.25 11.1989 5.32902 11.3897 5.46967 11.5303C5.61032 11.671 5.80109 11.75 6 11.75C6.19891 11.75 6.38968 11.671 6.53033 11.5303C6.67098 11.3897 6.75 11.1989 6.75 11C6.75 10.8011 6.67098 10.6103 6.53033 10.4697C6.38968 10.329 6.19891 10.25 6 10.25C5.80109 10.25 5.61032 10.329 5.46967 10.4697C5.32902 10.6103 5.25 10.8011 5.25 11Z"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>{" "}
                  <span className="font-lexend ml-2">Password</span>
                </label>

                <TextField
                  variant="standard"
                  name="password"
                  placeholder="Enter Password"
                  value={values.password}
                  type="password"
                  onChange={(e: any) =>
                    setValues({
                      ...values,
                      password: e.target.value,
                    })
                  }
                />
              </>
            ) : (
              ""
            )}
          </div>

          <div className="flex justify-between">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "48%",
                marginLeft: "17px",
              }}
            >
              <label className="flex items-center mb-2 mt-5" htmlFor="email">
                <span>
                  <svg
                    className="ml-2"
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.25 2.25C1.25 1.85218 1.40804 1.47064 1.68934 1.18934C1.97064 0.908035 2.35218 0.75 2.75 0.75H13.25C13.6478 0.75 14.0294 0.908035 14.3107 1.18934C14.592 1.47064 14.75 1.85218 14.75 2.25M1.25 2.25V9.75C1.25 10.1478 1.40804 10.5294 1.68934 10.8107C1.97064 11.092 2.35218 11.25 2.75 11.25H13.25C13.6478 11.25 14.0294 11.092 14.3107 10.8107C14.592 10.5294 14.75 10.1478 14.75 9.75V2.25M1.25 2.25L8 6.75L14.75 2.25"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>{" "}
                <span className="font-lexend ml-2">Email</span>
              </label>
              <TextField
                id="input-with-sx"
                variant="standard"
                placeholder="Enter Email"
                helperText={error.email && "Please enter valid email"}
                value={values.email}
                onChange={(e: any) =>
                  setValues({
                    ...values,
                    email: e.target.value,
                  })
                }
                error={error.email}
              />
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "48%" }}
            >
              <label className="flex items-center mb-2 mt-5" htmlFor="phone">
                <span>
                  <svg
                    className="ml-2"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.75 1H5.75L7.25 4.75L5.375 5.875C6.17822 7.50365 7.49635 8.82178 9.125 9.625L10.25 7.75L14 9.25V12.25C14 12.6478 13.842 13.0294 13.5607 13.3107C13.2794 13.592 12.8978 13.75 12.5 13.75C9.57445 13.5722 6.81512 12.3299 4.74262 10.2574C2.67013 8.18489 1.42779 5.42555 1.25 2.5C1.25 2.10218 1.40804 1.72064 1.68934 1.43934C1.97064 1.15804 2.35218 1 2.75 1Z"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>{" "}
                <span className="font-lexend ml-2">Phone</span>
              </label>
              <TextField
                id="input-with-sx"
                placeholder="Enter Phone Number"
                variant="standard"
                helperText={error.phone && "Please Enter Valid Number"}
                value={values.phone}
                onChange={(e: any) =>
                  setValues({
                    ...values,
                    phone: e.target.value,
                  })
                }
                error={error.phone}
              />
            </div>
          </div>

          <br />
          <div className="flex justify-between items-center">
            {/* <p className="pl-4 pb-2 font-lexend text-lg font-bold">
              Provide access from the following:
            </p> */}
            {/* Buttom */}
            <div className="mt-2">
              <button
                className="flex justify-around items-center px-3 py-2 rounded-full ml-6"
                style={{ background: "#1AC47D", border: "2px solid #1AAD70" }}
                onClick={() => {
                  props.type === "add" ? submitAddUser() : submitEditUser();
                }}
              >
                <span className="mr-2">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.75 9L7.5 12.75L15 5.25"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span className="font-lexend pr-2" style={{ color: "white" }}>
                  Submit
                </span>
              </button>
            </div>
          </div>

          {/* Access Main Container */}
          {/* <div className="flex justify-between">
            {/* 1st Column */}
            {/* <div>
              {/* Quick Menu */}
              <div className="flex flex-col">
               
                <div></div>

                {/* <div className="flex">
                  {values.access.includes("Reports") ? (
                    <div>
                      {" "}
                      <Checkbox
                        checked={values.access.indexOf("Reports") > -1}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Reports")}
                      />{" "}
                      <span className="font-lexend">Reports</span>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Checkbox
                        checked={false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "Reports")}
                      />{" "}
                      <span className="font-lexend">Reports</span>
                    </div>
                  )}
                  {/* {values.access.includes("Payout") ? (
                   <div>
                     {" "}
                     <Checkbox
                       checked={values.access.indexOf("Payout") > -1}
                       onChange={(
                         event: React.ChangeEvent<HTMLInputElement>
                       ) => handleCheckboxChange(event, "Payout")}
                     />{" "}
                     <span className="font-lexend">Payout</span>
                   </div>
                 ) : (
                   <div>
                     {" "}
                     <Checkbox
                       checked={false}
                       onChange={(
                         event: React.ChangeEvent<HTMLInputElement>
                       ) => handleCheckboxChange(event, "Payout")}
                     />
                     <span className="font-lexend">Payout</span>
                   </div>
                 )} */}

                  {/* {values.access.includes("FleetOperator") ? (
                    <div>
                      {" "}
                      <Checkbox
                        checked={values.access.indexOf("FleetOperator") > -1}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "FleetOperator")}
                      />{" "}
                      <span className="font-lexend">Fleet</span>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Checkbox
                        checked={false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckboxChange(event, "FleetOperator")}
                      />
                      <span className="font-lexend">Fleet</span>
                    </div>
                  )}
                </div> */} 
              </div>
            {/* </div>
          </div> */} 
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default AddEditFleetRoleModal;
