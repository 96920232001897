import { useState, memo } from "react";
import "./RolesTable.scss";
import { DataGrid } from "@mui/x-data-grid";
import AddEditModal from "../addEditModal";
import { Tooltip } from "@mui/material";
import AddEditFleetRoleModal from "../addEditFleetRoleModal";
import { EditIcon } from "../../../icons/svg_icons/icons_svg";

interface tableProps {
  dataRow: any;
  allCPO: [];
  allFleet:[];
  refreshTable: any;
  loading: boolean;
}

const DatabaseTable = memo((props: tableProps) => {
  const { dataRow } = props;
  console.log("edit fleet AddEditFleetRoleModal ",props);
  
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showAddEditFleetModal, setShowAddEditFleetModal] = useState(false);
  const [addEditData, setAddEditData] = useState({});
  const [addEditFleetData, setAddEditFleetData] = useState({});
  const columns = [
    {
      field: "sNo",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          S.NO
        </span>
      ),
      renderCell: (params: any) => {
        console.log("data param S.NO", params);

        return <span className="font-lexend">{params?.row?.sNo}</span>;
      },
      width: 80,
    },
    {
      field: "name",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          User Name
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.name}</span>;
      },
      width: 180,
      editable: true,
    },
    {
      field: "phone",
      headerName: "Mobile",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Mobile
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.phone}</span>;
      },
      width: 180,
      editable: true,
    },

    {
      field: "email",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Email
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.email}</span>;
      },
      width: 320,
      editable: true,
    },

    {
      field: "operator",

      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Operator
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.operator}</span>;
      },
      width: 180,
      editable: true,
    },
    {
      field: "access",
      headerName: "Role",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Role
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.access}</span>;
      },
      width: 520,
    },

    {
      field: "Edit Roles",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Edit Roles
        </span>
      ),
      width: 220,
      renderCell: (params: {
        row: {
          operatorId: any;
          email: any;
          phone: any;
          cpo: any;
          access: any;
          password: any;
          fleet: any;
          fleetId: any;
          companyName: string;
          rangeAdded: any;
          chargeRate: any;
          id: any;
          name: any;
          image: any;
          enabled: any;
          obj: any;
        };
      }) => {
        console.log("Props Data Role", params);
        const hasAccess =
          params?.row?.access.includes("FleetOperator") 
        return hasAccess ? (
          <Tooltip title="Fleet Edit">
            <button
              style={{
                background: "#1AC47D",
                width: "35px",
                height: "35px",
                padding: "5px",
                borderRadius: "50%",
                border: "3px solid #1AAD70",
                marginLeft: "9px",
              }}
              onClick={() => {
                setShowAddEditFleetModal(true);
                setAddEditFleetData({
                  id: params.row.id,
                  name: params.row.name,
                  email: params.row.email,
                  phone: params.row.phone,
                  cpo: params.row.operatorId,
                  access: params.row.access,
                  password: params.row.password,
                  fleet: params.row.fleet,
                  fleetId: params.row.fleetId,
                });
              }}
            >
              <EditIcon />
            </button>
          </Tooltip>
        ) : (
          <Tooltip title="Edit Roles">
            <button
              style={{
                background: "#1AC47D",
                width: "35px",
                height: "35px",
                padding: "5px",
                borderRadius: "50%",
                border: "3px solid #1AAD70",
                marginLeft: "9px",
              }}
              onClick={() => {
                setShowAddEditModal(true);
                setAddEditData({
                  id: params.row.id,
                  name: params.row.name,
                  email: params.row.email,
                  phone: params.row.phone,
                  cpo: params.row.operatorId,
                  access: params.row.access,
                  password: params.row.password,
                  fleet: params.row.fleet,
                  fleetId: params.row.fleetId,
                });
              }}
            >
              <EditIcon />
            </button>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <div className="booking-table">
      <DataGrid
        className="mt-6"
        rows={dataRow}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[5]}
        checkboxSelection={false}
        disableSelectionOnClick
        autoHeight
        loading={props.loading}
      />
      <AddEditModal
        show={showAddEditModal}
        type="edit"
        handleClose={() => setShowAddEditModal(false)}
        data={addEditData}
        allCPO={props.allCPO}
        refresh={props.refreshTable}
      />

      <AddEditFleetRoleModal
        show={showAddEditFleetModal}
        handleClose={() => setShowAddEditFleetModal(false)}
        type="edit fleet"
        refresh={props.refreshTable}
        data={addEditFleetData}
        allCPO={props.allCPO}
        allFleet={props.allFleet}
      />
    </div>
  );
});

export default DatabaseTable;
