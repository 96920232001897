import React, { memo, useEffect, useState } from "react";
import "./databaseTable.scss";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
interface tableProps {
  data: any;
  loading: boolean;
}
interface ChargerData {
  serialNum: string;
  isEnabled:boolean;
  costPerUnit: number;
  cost: number;
  taxInclusive: number;
}
const CustomNoRowsOverlay = () => (
  <div
    style={{
      background: "#F6F8FA",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    }}
  >
    <p>No records found</p>
  </div>
);

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Tooltip title="Vehicle SOC" placement="right">
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" {...props} />

        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    </Tooltip>
  );
}

export function CircularWithValueLabel(props: any) {
  let { SOC } = props;

  const [progress, setProgress] = React.useState(props?.Soc);

  return <CircularProgressWithLabel value={progress} />;
}

const DatabaseTable = memo((props: tableProps) => {
  const [dataRow, setDataRow] = useState([]);
  const { data } = props;
  const [chargersData, setChargersData] = useState<ChargerData[]>([]);

  useEffect(() => {
    // Fetch charger data from Parse when the component mounts
    async function fetchChargersData() {
      const Charger = Parse.Object.extend("Chargers");
      const query = new Parse.Query(Charger);
      try {
        const results = await query.find();
        // Transform Parse results into usable data
        const chargers = results.map((result: any) => ({
          serialNum: result.get("Serial"),
          cost: result.get("Cost"),
          isEnabled: result.get("isEnabled"),
          taxInclusive: result.get("inclusiveTax"),
        })) as ChargerData[];
        setChargersData(chargers);
      } catch (error) {
        console.error("Error fetching charger data:", error);
      }
    }
    fetchChargersData();
  }, []);

  useEffect(() => {
    setDataRow(data.sort((a: any, b: any) => a.id - b.id));
  }, [data]);

  useEffect(() => {
    // Update data row with total cost after chargers data is fetched
    console.log("Data of sessions",data);
    
    const updatedDataRow = data.map((item: any) => ({
      ...item,
      totalCost: calculateTotalCost(item),
    }));
    setDataRow(updatedDataRow);
  }, [data, chargersData]);

  // Calculate Cost Fleet or normal user

  const calculateTotalCost = (charger: any) => {
   
    const chargerData = chargersData.find(
      (item: ChargerData) => item?.isEnabled && item.serialNum === charger.serialNum 
    );

    if (chargerData) {
      const { cost, taxInclusive } = chargerData;
      const { obj, ocppEnergy } = charger;
       
      const fleetCost = charger?.fleetCost;
      const currentStatus = obj.get("Live");
      const totalEnergyConsumed = obj.get("TotalEnergyConsumed");
      const charge = obj.get("ChargePoint")?.get("isOCPP");
      const meterStart = obj.get("MeterStart");

      if (fleetCost != null) {
        // Calculate based on fleetCost if defined
        if (charge && currentStatus === true) {
          // Charger is live and OCPP is true
          return ((ocppEnergy - meterStart / 1000) * fleetCost).toFixed(2);
        } else if (charge && currentStatus === false) {
          // Charger is not live and OCPP is true
          return (totalEnergyConsumed * fleetCost).toFixed(2);
        }
      } else {
        if (charge && currentStatus === true) {
          // Charger is live and OCPP is true
          return ((ocppEnergy - meterStart / 1000) * cost).toFixed(2);
        } else if (charge && currentStatus === false) {
          // Charger is not live and OCPP is true
          return (totalEnergyConsumed * cost).toFixed(2);
        } else {
          return (totalEnergyConsumed * cost).toFixed(2);
        }
        // fleetCost is undefined, use `cost` instead
      }

      // If none of the conditions are met
      return parseFloat(obj.get("TotalCost")).toFixed(2);
    }

    return "N/A"; // Handle case where charger data is not found
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          ID
        </span>
      ),
      width: 60,
    },
    {
      field: "customer",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Name
        </span>
      ),
      width: 230,
      cellClassName: "custom_cell_name",
      // renderCell: (params: any) => (
      //   <>
      //     {/* <div className="custom_cell_style">{params?.row?.customer}</div> */}
      //     <div className="custom_cell_style">
      //       {params?.row?.customer}
      //       {/* {params?.row?.customer &&
      //         params.row.customer.charAt(0).toUpperCase() +
      //           params.row.customer.slice(1)} */}
      //     </div>
      //   </>
      // ),
      renderCell: (params: any) => (
        <>
          <Tooltip title={params?.row?.fleet + params?.row?.fleetCost}>
            <div
              className="custom_cell_style"
            >
              {params?.row?.customer &&
                params.row.customer.charAt(0).toUpperCase() +
                  params.row.customer.slice(1)}
            </div>
          </Tooltip>
        </>
      ),
    },
    {
      field: "location",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Location
        </span>
      ),
      width: 230,
      cellClassName: "custom-cell",
      renderCell: (params: any) => {
        let location = params;
        return (
          <div style={{ fontFamily: "lexend", fontWeight: "300" }}>
            {params.row.location}
          </div>
        );
      },
    },
    {
      field: "isLive",
      width: 200,
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Status
        </span>
      ),

      // renderCell: (params: any) => {
      //   let currentStatus = params.row.obj.get("Live");

      //   let transactionId = params.row.obj.get("TransactionId");
      //   let meterEnd = params.row.obj.get("MeterEnd");
      //   let TotalCost = params.row.obj.get("TotalCost");
      //   let isOcpp = params.row.obj.get("isOCPP");
      //   let cost = params.row.cost;
      //   let soc = params?.row?.soc;
      //   return currentStatus === false ? (
      //     <>
      //       {transactionId == undefined &&
      //         (isOcpp == false ? (
      //           <span className="flex align-middle rounded-full p-1 text-blue-600">
      //             <svg
      //               className=" mr-2 mt-2"
      //               width="19"
      //               height="18"
      //               viewBox="0 0 19 18"
      //               fill="none"
      //               xmlns="http://www.w3.org/2000/svg"
      //             >
      //               <path
      //                 d="M6.03906 7.5V10.5M8.28906 7.5V10.5M10.5391 7.5V10.5M12.7891 7.5V10.5M5.28906 5.25H13.5391C13.9369 5.25 14.3184 5.40804 14.5997 5.68934C14.881 5.97064 15.0391 6.35218 15.0391 6.75V7.125C15.0391 7.22446 15.0786 7.31984 15.1489 7.39017C15.2192 7.46049 15.3146 7.5 15.4141 7.5C15.5135 7.5 15.6089 7.53951 15.6792 7.60983C15.7496 7.68016 15.7891 7.77554 15.7891 7.875V10.125C15.7891 10.2245 15.7496 10.3198 15.6792 10.3902C15.6089 10.4605 15.5135 10.5 15.4141 10.5C15.3146 10.5 15.2192 10.5395 15.1489 10.6098C15.0786 10.6802 15.0391 10.7755 15.0391 10.875V11.25C15.0391 11.6478 14.881 12.0294 14.5997 12.3107C14.3184 12.592 13.9369 12.75 13.5391 12.75H5.28906C4.89124 12.75 4.50971 12.592 4.2284 12.3107C3.9471 12.0294 3.78906 11.6478 3.78906 11.25V6.75C3.78906 6.35218 3.9471 5.97064 4.2284 5.68934C4.50971 5.40804 4.89124 5.25 5.28906 5.25Z"
      //                 stroke="#1A72C4"
      //                 stroke-width="1.5"
      //                 stroke-linecap="round"
      //                 stroke-linejoin="round"
      //               />
      //             </svg>
      //             <p
      //               style={{
      //                 fontFamily: "lexend",
      //                 fontWeight: "400",
      //                 fontSize: "16px",
      //               }}
      //               className="mt-1"
      //             >
      //               Completed
      //             </p>{" "}
      //           </span>
      //         ) : (
      //           <span
      //             style={{ color: "#C99456" }}
      //             className="flex align-middle rounded-full p-1"
      //           >
      //             <svg
      //               className=" mr-2 mt-2"
      //               width="16"
      //               height="16"
      //               viewBox="0 0 16 16"
      //               fill="none"
      //               xmlns="http://www.w3.org/2000/svg"
      //             >
      //               <path
      //                 d="M1.03906 1.25L14.5391 14.75M7.03906 4.25H11.5391C11.9369 4.25 12.3184 4.40804 12.5997 4.68934C12.881 4.97064 13.0391 5.35218 13.0391 5.75V6.125C13.0391 6.22446 13.0786 6.31984 13.1489 6.39017C13.2192 6.46049 13.3146 6.5 13.4141 6.5C13.5135 6.5 13.6089 6.53951 13.6792 6.60983C13.7496 6.68016 13.7891 6.77554 13.7891 6.875V9.125C13.7891 9.22446 13.7496 9.31984 13.6792 9.39017C13.6089 9.46049 13.5135 9.5 13.4141 9.5C13.3146 9.5 13.2192 9.53951 13.1489 9.60983C13.0786 9.68016 13.0391 9.77554 13.0391 9.875V10.25M11.5391 11.75H3.28906C2.89124 11.75 2.50971 11.592 2.2284 11.3107C1.9471 11.0294 1.78906 10.6478 1.78906 10.25V5.75C1.78906 5.35218 1.9471 4.97064 2.2284 4.68934C2.50971 4.40804 2.89124 4.25 3.28906 4.25H4.03906"
      //                 stroke="#C99456"
      //                 stroke-width="1.5"
      //                 stroke-linecap="round"
      //                 stroke-linejoin="round"
      //               />
      //             </svg>
      //             <p
      //               style={{
      //                 fontFamily: "lexend",
      //                 fontWeight: "400",
      //                 fontSize: "16px",
      //               }}
      //               className="mt-1"
      //             >
      //               Cancelled
      //             </p>{" "}
      //           </span>
      //         ))}

      //       {transactionId !== undefined && (
      //         <span className="flex align-middle rounded-full p-1 text-blue-600">
      //           <svg
      //             className=" mr-2 mt-2"
      //             width="19"
      //             height="18"
      //             viewBox="0 0 19 18"
      //             fill="none"
      //             xmlns="http://www.w3.org/2000/svg"
      //           >
      //             <path
      //               d="M6.03906 7.5V10.5M8.28906 7.5V10.5M10.5391 7.5V10.5M12.7891 7.5V10.5M5.28906 5.25H13.5391C13.9369 5.25 14.3184 5.40804 14.5997 5.68934C14.881 5.97064 15.0391 6.35218 15.0391 6.75V7.125C15.0391 7.22446 15.0786 7.31984 15.1489 7.39017C15.2192 7.46049 15.3146 7.5 15.4141 7.5C15.5135 7.5 15.6089 7.53951 15.6792 7.60983C15.7496 7.68016 15.7891 7.77554 15.7891 7.875V10.125C15.7891 10.2245 15.7496 10.3198 15.6792 10.3902C15.6089 10.4605 15.5135 10.5 15.4141 10.5C15.3146 10.5 15.2192 10.5395 15.1489 10.6098C15.0786 10.6802 15.0391 10.7755 15.0391 10.875V11.25C15.0391 11.6478 14.881 12.0294 14.5997 12.3107C14.3184 12.592 13.9369 12.75 13.5391 12.75H5.28906C4.89124 12.75 4.50971 12.592 4.2284 12.3107C3.9471 12.0294 3.78906 11.6478 3.78906 11.25V6.75C3.78906 6.35218 3.9471 5.97064 4.2284 5.68934C4.50971 5.40804 4.89124 5.25 5.28906 5.25Z"
      //               stroke="#1A72C4"
      //               stroke-width="1.5"
      //               stroke-linecap="round"
      //               stroke-linejoin="round"
      //             />
      //           </svg>
      //           <p
      //             style={{
      //               fontFamily: "lexend",
      //               fontWeight: "400",
      //               fontSize: "16px",
      //             }}
      //             className="mt-1"
      //           >
      //             Completed
      //           </p>{" "}
      //         </span>
      //       )}
      //     </>
      //   ) : (
      //     <div className="flex items-center">
      //       <span className="flex align-middle rounded-full p-1 text-green-600">
      //         {" "}
      //         <svg
      //           className=" mr-2 mt-1"
      //           width="19"
      //           height="18"
      //           viewBox="0 0 19 18"
      //           fill="none"
      //           xmlns="http://www.w3.org/2000/svg"
      //         >
      //           <g clip-path="url(#clip0_3937_2347)">
      //             <path
      //               d="M3.78906 6.75C3.78906 6.35218 3.9471 5.97064 4.2284 5.68934C4.50971 5.40804 4.89124 5.25 5.28906 5.25H13.5391C13.9369 5.25 14.3184 5.40804 14.5997 5.68934C14.881 5.97064 15.0391 6.35218 15.0391 6.75V7.125C15.0391 7.22446 15.0786 7.31984 15.1489 7.39017C15.2192 7.46049 15.3146 7.5 15.4141 7.5C15.5135 7.5 15.6089 7.53951 15.6792 7.60983C15.7496 7.68016 15.7891 7.77554 15.7891 7.875V10.125C15.7891 10.2245 15.7496 10.3198 15.6792 10.3902C15.6089 10.4605 15.5135 10.5 15.4141 10.5C15.3146 10.5 15.2192 10.5395 15.1489 10.6098C15.0786 10.6802 15.0391 10.7755 15.0391 10.875V11.25C15.0391 11.6478 14.881 12.0294 14.5997 12.3107C14.3184 12.592 13.9369 12.75 13.5391 12.75H10.1641M5.28906 16.5V14.25M3.78906 11.25V9.375M6.78906 11.25V9.375M3.03906 11.25H7.53906V12.75C7.53906 13.1478 7.38103 13.5294 7.09972 13.8107C6.81842 14.092 6.43689 14.25 6.03906 14.25H4.53906C4.14124 14.25 3.75971 14.092 3.4784 13.8107C3.1971 13.5294 3.03906 13.1478 3.03906 12.75V11.25Z"
      //               stroke="#1AC47D"
      //               stroke-width="1.5"
      //               stroke-linecap="round"
      //               stroke-linejoin="round"
      //             />
      //           </g>
      //           <defs>
      //             <clipPath id="clip0_3937_2347">
      //               <rect
      //                 width="18"
      //                 height="18"
      //                 fill="white"
      //                 transform="translate(0.789062)"
      //               />
      //             </clipPath>
      //           </defs>
      //         </svg>
      //         <p
      //           style={{
      //             fontFamily: "lexend",
      //             fontWeight: "400",
      //             fontSize: "16px",
      //           }}
      //         >
      //           Charging
      //         </p>
      //       </span>
      //       <span className="ml-4 mt-2">
      //         {/* Live Charging Percentage of Vehicle Battery */}
      //         {soc != undefined ? (
      //           <CircularWithValueLabel Soc={soc} />
      //         ) : (
      //           <span className="ml-4">-</span>
      //         )}
      //       </span>
      //     </div>
      //   );
      // },     
      // ❌🔸Cancelled Status🔸❌
      renderCell: (params: any) => {
        let currentStatus = params.row.obj.get("Live");
        let cost = params.row.cost;
        let soc = params?.row?.soc;
        return currentStatus === false ? (
          <>
            {cost == "₹NaN" || cost == "₹ NaN" || cost == "₹undefined" ? (
              <span
                style={{ color: "#C99456" }}
                className="flex align-middle rounded-full p-1"
              >
                <svg
                  className=" mr-2 mt-2"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.03906 1.25L14.5391 14.75M7.03906 4.25H11.5391C11.9369 4.25 12.3184 4.40804 12.5997 4.68934C12.881 4.97064 13.0391 5.35218 13.0391 5.75V6.125C13.0391 6.22446 13.0786 6.31984 13.1489 6.39017C13.2192 6.46049 13.3146 6.5 13.4141 6.5C13.5135 6.5 13.6089 6.53951 13.6792 6.60983C13.7496 6.68016 13.7891 6.77554 13.7891 6.875V9.125C13.7891 9.22446 13.7496 9.31984 13.6792 9.39017C13.6089 9.46049 13.5135 9.5 13.4141 9.5C13.3146 9.5 13.2192 9.53951 13.1489 9.60983C13.0786 9.68016 13.0391 9.77554 13.0391 9.875V10.25M11.5391 11.75H3.28906C2.89124 11.75 2.50971 11.592 2.2284 11.3107C1.9471 11.0294 1.78906 10.6478 1.78906 10.25V5.75C1.78906 5.35218 1.9471 4.97064 2.2284 4.68934C2.50971 4.40804 2.89124 4.25 3.28906 4.25H4.03906"
                    stroke="#C99456"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p
                  style={{
                    fontFamily: "lexend",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  className="mt-1"
                >
                  Cancelled
                </p>{" "}
              </span>
            ) : (
              <span className="flex align-middle rounded-full p-1 text-blue-600">
                <svg
                  className=" mr-2 mt-2"
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.03906 7.5V10.5M8.28906 7.5V10.5M10.5391 7.5V10.5M12.7891 7.5V10.5M5.28906 5.25H13.5391C13.9369 5.25 14.3184 5.40804 14.5997 5.68934C14.881 5.97064 15.0391 6.35218 15.0391 6.75V7.125C15.0391 7.22446 15.0786 7.31984 15.1489 7.39017C15.2192 7.46049 15.3146 7.5 15.4141 7.5C15.5135 7.5 15.6089 7.53951 15.6792 7.60983C15.7496 7.68016 15.7891 7.77554 15.7891 7.875V10.125C15.7891 10.2245 15.7496 10.3198 15.6792 10.3902C15.6089 10.4605 15.5135 10.5 15.4141 10.5C15.3146 10.5 15.2192 10.5395 15.1489 10.6098C15.0786 10.6802 15.0391 10.7755 15.0391 10.875V11.25C15.0391 11.6478 14.881 12.0294 14.5997 12.3107C14.3184 12.592 13.9369 12.75 13.5391 12.75H5.28906C4.89124 12.75 4.50971 12.592 4.2284 12.3107C3.9471 12.0294 3.78906 11.6478 3.78906 11.25V6.75C3.78906 6.35218 3.9471 5.97064 4.2284 5.68934C4.50971 5.40804 4.89124 5.25 5.28906 5.25Z"
                    stroke="#1A72C4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p
                  style={{
                    fontFamily: "lexend",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  className="mt-1"
                >
                  Completed
                </p>{" "}
              </span>
            )}
          </>
        ) : (
          <div className="flex items-center">
            <span className="flex align-middle rounded-full p-1 text-green-600">
              {" "}
              <svg
                className=" mr-2 mt-1"
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3937_2347)">
                  <path
                    d="M3.78906 6.75C3.78906 6.35218 3.9471 5.97064 4.2284 5.68934C4.50971 5.40804 4.89124 5.25 5.28906 5.25H13.5391C13.9369 5.25 14.3184 5.40804 14.5997 5.68934C14.881 5.97064 15.0391 6.35218 15.0391 6.75V7.125C15.0391 7.22446 15.0786 7.31984 15.1489 7.39017C15.2192 7.46049 15.3146 7.5 15.4141 7.5C15.5135 7.5 15.6089 7.53951 15.6792 7.60983C15.7496 7.68016 15.7891 7.77554 15.7891 7.875V10.125C15.7891 10.2245 15.7496 10.3198 15.6792 10.3902C15.6089 10.4605 15.5135 10.5 15.4141 10.5C15.3146 10.5 15.2192 10.5395 15.1489 10.6098C15.0786 10.6802 15.0391 10.7755 15.0391 10.875V11.25C15.0391 11.6478 14.881 12.0294 14.5997 12.3107C14.3184 12.592 13.9369 12.75 13.5391 12.75H10.1641M5.28906 16.5V14.25M3.78906 11.25V9.375M6.78906 11.25V9.375M3.03906 11.25H7.53906V12.75C7.53906 13.1478 7.38103 13.5294 7.09972 13.8107C6.81842 14.092 6.43689 14.25 6.03906 14.25H4.53906C4.14124 14.25 3.75971 14.092 3.4784 13.8107C3.1971 13.5294 3.03906 13.1478 3.03906 12.75V11.25Z"
                    stroke="#1AC47D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3937_2347">
                    <rect
                      width="18"
                      height="18"
                      fill="white"
                      transform="translate(0.789062)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <p
                style={{
                  fontFamily: "lexend",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                Charging
              </p>
            </span>
            <span className="ml-4 mt-2">
              {soc != undefined ? (
                <CircularWithValueLabel Soc={soc} />
              ) : (
                <span className="ml-4">-</span>
              )}
            </span>
          </div>
        );
      },
    },
    {
      field: "chargerGunID",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          GunID
        </span>
      ),
      width: 70,
      renderCell: (params: any) => {
        return (
          <div
            className="ml-2"
            style={{ fontFamily: "lexend", fontWeight: "300" }}
          >
            {params.row.chargerGunID == "1" ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.333 0C18.293 0 19.893 1.537 19.995 3.472L20 3.667V16.333C20 18.293 18.463 19.893 16.528 19.995L16.333 20H3.667C2.72818 20 1.82509 19.64 1.1438 18.9941C0.462517 18.3481 0.0549239 17.4655 0.00500011 16.528L0 16.333V3.667C0 1.707 1.537 0.107 3.472 0.00500011L3.667 0H16.333ZM10.994 5.886C10.911 5.109 9.986 4.726 9.377 5.216L9.293 5.293L7.293 7.293L7.21 7.387C7.07393 7.56237 7.00008 7.77803 7.00008 8C7.00008 8.22197 7.07393 8.43763 7.21 8.613L7.293 8.707L7.387 8.79C7.56237 8.92607 7.77803 8.99992 8 8.99992C8.22197 8.99992 8.43763 8.92607 8.613 8.79L8.707 8.707L9 8.414V14L9.007 14.117C9.03591 14.3601 9.15296 14.5841 9.33597 14.7466C9.51897 14.9091 9.75524 14.9989 10 14.9989C10.2448 14.9989 10.481 14.9091 10.664 14.7466C10.847 14.5841 10.9641 14.3601 10.993 14.117L11 14V6L10.994 5.886Z"
                  fill="#676767"
                />
              </svg>
            ) : params.row.chargerGunID === "2" ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.333 0C18.293 0 19.893 1.537 19.995 3.472L20 3.667V16.333C20 18.293 18.463 19.893 16.528 19.995L16.333 20H3.667C2.72818 20 1.82509 19.64 1.1438 18.9941C0.462517 18.3481 0.0549239 17.4655 0.00500011 16.528L0 16.333V3.667C0 1.707 1.537 0.107 3.472 0.00500011L3.667 0H16.333ZM11 5H8L7.883 5.007C7.63995 5.03591 7.41594 5.15296 7.25341 5.33596C7.09088 5.51897 7.00111 5.75524 7.00111 6C7.00111 6.24476 7.09088 6.48103 7.25341 6.66403C7.41594 6.84704 7.63995 6.96409 7.883 6.993L8 7H11V9H9L8.85 9.005C8.37383 9.04078 7.92617 9.24576 7.58797 9.58286C7.24977 9.91996 7.04334 10.3669 7.006 10.843L7 11V13L7.005 13.15C7.04078 13.6262 7.24576 14.0738 7.58286 14.412C7.91996 14.7502 8.36695 14.9567 8.843 14.994L9 15H12L12.117 14.993C12.36 14.9641 12.5841 14.847 12.7466 14.664C12.9091 14.481 12.9989 14.2448 12.9989 14C12.9989 13.7552 12.9091 13.519 12.7466 13.336C12.5841 13.153 12.36 13.0359 12.117 13.007L12 13H9V11H11L11.15 10.995C11.6262 10.9592 12.0738 10.7542 12.412 10.4171C12.7502 10.08 12.9567 9.63305 12.994 9.157L13 9V7L12.995 6.85C12.9592 6.37383 12.7542 5.92617 12.4171 5.58797C12.08 5.24977 11.6331 5.04334 11.157 5.006L11 5Z"
                  fill="#676767"
                />
              </svg>
            ) : (
              <span>-</span>
            )}
          </div>
        );
      },
    },
    {
      field: "carCharged",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Vehicle
        </span>
      ),
      width: 120,
      renderCell: (params: any) => {
        return (
          <div style={{ fontFamily: "lexend", fontWeight: "300" }}>
            {params.row.carCharged}
          </div>
        );
      },
    },

    {
      field: "startTime",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Start Time
        </span>
      ),
      width: 200,
      renderCell: (params: any) => {
        return (
          <div style={{ fontFamily: "lexend", fontWeight: "300" }}>
            {params.row.startTime}
          </div>
        );
      },
    },
    {
      field: "endTime",

      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          End Time
        </span>
      ),
      width: 200,
      renderCell: (params: any) => {
        let currentStatus = params.row.obj.get("Live");

        return currentStatus === true ? (
          <div>-</div>
        ) : (
          <span style={{ fontFamily: "lexend", fontWeight: "300" }}>
            {params.row.endTime}
          </span>
        );
      },
    },

    {
      field: "totalCost",

      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Cost
        </span>
      ),
      width: 100,
      renderCell: (params: any) => (
        <>
          <div style={{ fontFamily: "lexend", fontWeight: "300" }}>
            {params.value}
          </div>
        </>
      ),
    },

    {
      field: "energy",

      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Energy
        </span>
      ),
      width: 120,
      renderCell: (params: any) => {
        let meterStart = params.row.obj.get("MeterStart");
        let ocpp = params.row.time;

        let currentStatus = params.row.obj.get("Live");
        let charge = params.row.obj.get("ChargePoint")?.get("isOCPP");

        return charge && currentStatus === true ? (
          <div style={{ fontFamily: "lexend", fontWeight: "300" }}>
            {(params.row.ocppEnergy - meterStart / 1000).toFixed(2)}
          </div>
        ) : (
          <span style={{ fontFamily: "lexend", fontWeight: "300" }}>
            {parseFloat(params.row.obj.get("TotalEnergyConsumed")).toFixed(2)}
          </span>
        );
      },
    },
    {
      field: "duration",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Duration
        </span>
      ),
      width: 120,
      renderCell: (params: {
        row: {
          ocppDuration: any;
          duration: string;
          obj: { get: (arg0: string) => any };
        };
      }) => {
        let currentStatus = params.row.obj.get("Live");
        let charge = params.row.obj.get("ChargePoint")?.get("isOCPP");
        return charge && currentStatus === true ? (
          <div style={{ fontFamily: "lexend", fontWeight: "300" }}>
            {params.row.ocppDuration}
          </div>
        ) : (
          <span style={{ fontFamily: "lexend", fontWeight: "300" }}>
            {params.row.duration}
          </span>
        );
      },
    },
    {
      field: "serialNum",
      headerName: "Serial Number",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Serial Number
        </span>
      ),
      width: 150,
      renderCell: (params: any) => {
        return (
          <div style={{ fontFamily: "lexend", fontWeight: "300" }}>
            {params.row.serialNum}
          </div>
        );
      },
    },
    {
      field: "power",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Power
        </span>
      ),
      width: 120,
      renderCell: (params: any) => {
        return (
          <div style={{ fontFamily: "lexend", fontWeight: "300" }}>
            {params.row.power}
          </div>
        );
      },
    },
    {
      field: "connector",

      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Connector
        </span>
      ),
      width: 110,
      renderCell: (params: any) => {
        return (
          <div style={{ fontFamily: "lexend", fontWeight: "300" }}>
            {params.row.connector}
          </div>
        );
      },
    },
  ];

  return (
    <div
      // style={{ height: 600, width: "90%" }}
      className="cs-databaseTable dataGridContainer"
    >
      <DataGrid
        rows={dataRow}
        columns={columns}
        pageSize={100}
        hideFooterPagination
        hideFooter
        checkboxSelection={false}
        disableSelectionOnClick
        autoHeight // comment if we need sticky header
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        loading={props.loading}
      />
    </div>
  );
});

export default DatabaseTable;
