import React, { useState, memo, useEffect } from "react";
import "./cpoTable.scss";
import { DataGrid } from "@mui/x-data-grid";
import AddEditModal from "../addEditModal";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RemoveIcon from "@mui/icons-material/Remove";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import "react-datepicker/dist/react-datepicker.css";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, Chip, Modal, Skeleton, TextField, Tooltip,  Button, } from "@mui/material";
import Widget from "../widget/index";
import DatePicker from "react-datepicker";
import * as XLSX from "xlsx";
import {
  AddIcon,
  CloseFilterIcon,
  EditIcon,
  FilterIcon,
} from "../../../icons/svg_icons/icons_svg";
import { string } from "zod";
import { log } from "node:console";

interface ChargerData {
  serialNum: string;
  costPerUnit: number;
  cost: number;
  taxInclusive: number;
}
interface tableProps {
  dataRow: any;
  refresh: any;
  loading: boolean;
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {

  return (
    <Tooltip title="Vehicle SOC" placement="right">
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
         
          {...props}
        />

        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{ fontFamily: "lexend" }}
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    </Tooltip>
  );
}

export function CircularWithValueLabel(props: any) {
  let { SOC } = props;
  console.log("SOC", SOC);

  const [progress, setProgress] = React.useState(parseInt(props.Soc));

  return <CircularProgressWithLabel value={progress} />;
}

const DatabaseTable = memo((props: tableProps) => {
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [addEditData, setAddEditData] = useState({});
  const { dataRow } = props;
  const [openModal, setOpenModal] = useState(false);
  const [openModalDriverList, setOpenModalDriverList] = useState(false);
  const [fleetCost,setFleetCost] = useState(0)
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedFleetId, setSelectedFleetId] = useState(null);
  const [allUsers, setAllUsers] = useState<any | null>([]);
  const [activeMembers, setActiveMembers] = useState<any | null>([]);
  // const [selectedFleetId, setSelectedFleetId] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [tableData, setTableData] = useState<any>([]);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [dateFilter, setDateFilter] = useState("This Week");
  const [dateChips, setDateFilterChips] = useState<any[]>([]);
  const [startDateFilter, setStartDateFilter] = useState<any>("");
  const [endDateFilter, setEndDateFilter] = useState<any>("");
  const [fleetAddData, setFleetAddData] = useState({
    selectedFleetId: "",
    userIds: [],
  });
  // Widget code
  const [usage, setUsage] = useState({ thisMonth: 0, lastMonth: 0, total: 0 });
  const [billedRevenue, setBilledRevenue] = useState({
    thisMonth: 0,
    lastMonth: 0,
    total: 0,
  });
  const [chargeSession, setChargeSession] = useState({
    thisMonth: 0,
    lastMonth: 0,
    total: 0,
  });

  const [totalChargeSessions, setTotalChargeSessions] = useState(0);
  const handleFilterButtonClick = () => {
    // Toggle filter visibility
    setIsFilterVisible((prev) => !prev);
  };
  const [userIds, setUserIds] = useState<string[]>([]);

  const handleOpenModal = async (rowId: any) => {
    console.log("rowId data open", rowId?.id);

    // Fetch modal id active members-
    const parseQuery = new Parse.Query("FleetOperator");
    parseQuery.include("ActiveMembers");
    const CurrentUserFleetId = rowId?.id;
    parseQuery.equalTo("objectId", CurrentUserFleetId);

    try {
      const result = await parseQuery.first(); // Assuming you are querying a single fleet operator
      if (result) {
        const activeMembers = result.get("ActiveMembers") || [];
        console.log("Active Driver List", activeMembers);

        const activeMemberIds = activeMembers.map((member: any) => member?.id);
        console.log("Active Member IDs: ", activeMemberIds);
        // You can now store activeMemberIds wherever you need
        setUserIds(activeMemberIds);
      } else {
        console.log("No fleet operator found with the given ID.");
      }
    } catch (error) {
      console.error("Error fetching fleet operator:", error);
    }
    // Uncommented Changes---------
    const ids = rowId?.row?.activeMembers.map((user: any) => user.id);
    console.log("Fleet Driver ids", ids);

    setFleetAddData((prevData) => ({
      ...prevData,
      userIds: ids,
      selectedFleetId: rowId?.id,
    }));

    // ----------------------------
    const selectedId = rowId?.id;
    setSelectedRowId(selectedId);
    setOpenModal(true);
  };

  const handleOpenDriverListModal = (rowId: any) => {
    console.log("params Driver list modal", rowId);
    getWidgetsData();
    getAllFleetData(rowId?.id);
    // Update code here---
    const ids = rowId?.row?.activeMembers.map((user: any) => user.id);
    console.log("Driver modal users", ids);

    // const activeMembers = rowId?.row?.activeMembers;
    // console.log(" Active drivers",activeMembers);

    const uniqueActiveMembers = rowId?.row?.activeMembers.reduce(
      (acc: any[], current: any) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      },
      []
    );

    console.log("Unique Active Drivers", uniqueActiveMembers);

    // Map over uniqueActiveMembers to get only the unique driver IDs.
    const UserIds = uniqueActiveMembers.map((user: any) => user.id);
    console.log("UserIds", UserIds);

    const selectedFleetCost = rowId?.row?.obj?.attributes?.FleetCost;
    console.log("selectedFleetCost",selectedFleetCost);
    
    setFleetAddData({ ...fleetAddData, selectedFleetId: rowId?.id });
    setSelectedFleetId(rowId?.id);
    setFleetCost(selectedFleetCost)
    setUserIds(UserIds);
    setActiveMembers(uniqueActiveMembers);
    setOpenModalDriverList(true);
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedFleetIdToRemove, setSelectedFleetIdToRemove] = useState("");
  const handleConfirmRemoveDriver = (selectedId: any) => {
    setShowConfirmModal(true);
    console.log("selectedId", selectedId);
    setSelectedFleetIdToRemove(selectedId);
  };

  const handleCloseConfirm = () => {
    setShowConfirmModal(false);
  };

  const getWidgetsData = async () => {
    console.log("userIds activeMembers--------------------", activeMembers);

    if (userIds.length === 0) {
      // Optionally handle the case where there are no user IDs
      console.log("No user IDs provided. No data to fetch.");
      // You might want to clear the widgets or set some default values here
      setUsage({ thisMonth: 0, lastMonth: 0, total: 0 });
      setBilledRevenue({ thisMonth: 0, lastMonth: 0, total: 0 });
      setChargeDuration({ thisMonth: 0, lastMonth: 0, total: "0" });
      setChargeSession({ thisMonth: 0, lastMonth: 0, total: 0 });
      return;
    }
    const ChargeSession = Parse.Object.extend("ChargeSession");
    const parseQuery = new Parse.Query(ChargeSession);

    parseQuery.include("ChargePoint");
    parseQuery.include("Location");
    parseQuery.include("Vehicle");
    parseQuery.include("User");
    parseQuery.descending("createdAt");
    if (userIds.length > 0) {
      parseQuery.containedIn("User", userIds);
    }
    if (dateFilter) {
      if (dateFilter === "Today") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("day").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().add(1, "day").startOf("day").toString())
        );
      } else if (dateFilter === "This Week") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("week").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().endOf("week").toString())
        );
      } else if (dateFilter === "This Month") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("month").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().endOf("month").toString())
        );
      } else if (dateFilter === "This Year") {
        parseQuery.greaterThanOrEqualTo(
          "createdAt",
          new Date(moment().startOf("year").toString())
        );
        parseQuery.lessThanOrEqualTo(
          "createdAt",
          new Date(moment().endOf("year").toString())
        );
      }
    }

    if (startDateFilter) {
      // Apply single date filter
      parseQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment(startDateFilter).startOf("day").toString())
      );
      parseQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment(startDateFilter).endOf("day").toString())
      );
    }
    if (startDateFilter && endDateFilter) {
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDateFilter));
      parseQuery.greaterThanOrEqualTo("createdAt", new Date(startDateFilter));
      const adjustedEndDate = moment(endDateFilter).endOf("day").toDate();
      parseQuery.lessThanOrEqualTo("createdAt", adjustedEndDate);
    }

    // ! ||--------------------------------------------------------------------------------||
    // ! ||                   CPO data visibility according to login type                   ||
    // ! ||--------------------------------------------------------------------------------||
    // if (currentUser && !currentUser.get("isSuperAdmin")) {
    //   var innerQuery = new Parse.Query("Chargers");

    //   innerQuery.equalTo("CPO", currentUser.get("CPO"));
    //   parseQuery.matchesQuery("ChargePoint", innerQuery);
    // }
    // if (chargerSerialFilter) {
    //   var innerQuery = new Parse.Query("Chargers");
    //   innerQuery.matches("Serial", new RegExp(chargerSerialFilter, "i"));
    //   parseQuery.matchesQuery("ChargePoint", innerQuery);
    // }

    // Check Cpo

    const count = await parseQuery.count();
    setTotalChargeSessions(count);

    parseQuery.limit(4500);
    await parseQuery.find().then((result) => {
      let totalEnergy = 0;
      let totalTimeConsumed = 0;
      let thisMonthEnergy = 0;
      let lastMonthEnergy = 0;
      let percentageEnergy = 0;
      let totalMoney = 0;
      let thisMonthMoney = 0;
      let lastMonthMoney = 0;
      let totalSession = 0;
      result.forEach((item, index) => {
        console.log("-------------------------------Item",item);    
        console.log("-------------------------------Name",item?.get("User")?.get("FullName"));    
        console.log(`😍hello${index}`,parseFloat(item.get("TotalEnergyConsumed")?.toFixed(2)));
        console.log("-------------------------------");
        
        totalEnergy = item.get("TotalEnergyConsumed")
          ? item.get("TotalEnergyConsumed") + totalEnergy
          : 0 + totalEnergy;
        totalTimeConsumed = item.get("TotalTimeConsumed")
          ? item.get("TotalTimeConsumed") + totalTimeConsumed
          : 0 + totalTimeConsumed;
          //  Fleet Cost Price 
        // totalMoney =item.get("TotalEnergyConsumed")
        // ? item.get("TotalEnergyConsumed") + totalEnergy
        // : 0 + totalEnergy;
        totalMoney = item.get("Live")
    ? 0 + totalEnergy
    : (item.get("TotalEnergyConsumed") || 0) + totalEnergy;

          // ? item.get("TotalCost") + totalMoney
          // : 0 + totalMoney;
          // totalMoney = item.get("TotalCost")
        //   ? item.get("TotalCost") + totalMoney
        //   : 0 + totalMoney;
        totalSession++;
        let bc = [
          index + 1,
          `${item?.get("User")?.get("FullName")}`,
          item.get("Location")?.get("Name"),
          `${
            moment(item?.get("createdAt")).format("lll")
              ? moment(item.get("createdAt")).format("lll")
              : "-"
          }`,
          `${
            moment(item.get("updatedAt")).format("lll")
              ? moment(item.get("updatedAt")).format("lll")
              : "-"
          }`,
          `₹ ${parseFloat(item.get("TotalCost")).toFixed(2)}`,
          `${parseFloat(item.get("TotalEnergyConsumed")).toFixed(2)}`,

          `${item.get("ChargePoint")?.get("Serial")}`,
          `${item.get("ChargePoint")?.get("Power")}`,
          `${item.get("ChargePoint")?.get("Connector")}`,
        ];
      });

      setUsage({
        thisMonth: thisMonthEnergy,
        lastMonth: lastMonthEnergy,
        total: totalEnergy,
      });
      console.log("totalEnergy",totalEnergy);
      console.log("totalMoney",totalMoney);
      
      setBilledRevenue({
        thisMonth: thisMonthMoney,
        lastMonth: lastMonthMoney,
        // total: totalMoney * fleetCost,
        total: totalEnergy * fleetCost,
      });

      setChargeDuration({
        thisMonth: thisMonthMoney,
        lastMonth: lastMonthMoney,
        total: secToMinute(totalTimeConsumed),
      });

      setChargeSession({
        thisMonth: thisMonthMoney,
        lastMonth: thisMonthMoney,
        total: count,
      });
    });
  };

  
  const getOcppData = async (transactionId: any, user: any, start: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_OCPP_BASE_URL}/meter_value/${transactionId}`
      );
      const res = await response.json();
      console.log("result", res);

      const energy = (res.energy_active_import_register / 1000).toFixed(2);
      const ocppCost = (
        user.tariff *
        (res.energy_active_import_register / 1000)
      ).toFixed(2);
      const ocppDuration =
        moment.duration(moment(res.timestamp).diff(start)).hours() +
        "hr " +
        moment.duration(moment(res.timestamp).diff(start)).minutes() +
        "min";
      const liveSoc = res?.soc;

      // Update the activeMembers state with energy and other details
      setActiveMembers((prevMembers: any) =>
        prevMembers.map((member: any) =>
          member.id === user.id
            ? {
                ...member,
                ocppEnergy: energy,
                ocppDuration: ocppDuration,
                ocppCost: ocppCost,
                liveSoc: liveSoc,
              }
            : member
        )
      );

      return { energy, ocppCost, ocppDuration, liveSoc }; // Return the values for further use
    } catch (error) {
      console.error("Error fetching OCPP data:", error);
      return {
        energy: null,
        ocppCost: null,
        ocppDuration: null,
        liveSoc: null,
      }; // Return null values in case of error
    }
  };
  const [chargersData, setChargersData] = useState<ChargerData[]>([]);

  useEffect(() => {
    // Fetch charger data from Parse when the component mounts
    async function fetchChargersData() {
      const Charger = Parse.Object.extend("Chargers");
      const query = new Parse.Query(Charger);
      try {
        const results = await query.find();
        // Transform Parse results into usable data
        const chargers = results.map((result: any) => ({
          serialNum: result.get("Serial"),
          cost: result.get("Cost"),
          taxInclusive: result.get("inclusiveTax"),
        })) as ChargerData[];
        setChargersData(chargers);
      } catch (error) {
        console.error("Error fetching charger data:", error);
      }
    }
    fetchChargersData();
  }, []);

  const getActualCost = async (
    serialNumber?: string
  ): Promise<string | undefined> => {
    try {
      const chargerQuery = new Parse.Query("Chargers");
      chargerQuery.equalTo("Serial", serialNumber);
      chargerQuery.descending("createdAt");
      chargerQuery.limit(5000);

      const chargerRateSessions = await chargerQuery.find();
      // Check if any results were returned and extract the cost
      if (chargerRateSessions.length > 0) {
        const cost = chargerRateSessions[0].get("Cost"); // Replace "Cost" with the actual key name if different
        return cost ? cost.toString() : undefined;
      }

      return undefined; // Return undefined if no matching charger or cost was found
    } catch (error) {
      console.error("Error fetching actual cost:", error);
      return undefined; // Return undefined in case of an error
    }
  };

  const fetchChargeSessionsForUser = async (
    user: any,
    dateFilter: string,
    startDateFilter?: string,
    endDateFilter?: string
  ) => {
    console.log("usess api", user);

    const chargeSessionQuery = new Parse.Query("ChargeSession");
    chargeSessionQuery.equalTo("User", user);
    chargeSessionQuery.descending("createdAt");
    chargeSessionQuery.limit(5000);

    if (dateFilter === "This Week") {
      setDateFilterChips([{ id: "This Week", label: "This Week" }]);
    }

    if (dateFilter === "This Week") {
      chargeSessionQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment().startOf("week").toString())
      );
      chargeSessionQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment().endOf("week").toString())
      );
    } else if (dateFilter === "Today") {
      chargeSessionQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment().startOf("day").toString())
      );
      chargeSessionQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment().add(1, "day").startOf("day").toString())
      );
    } else if (dateFilter === "This Month") {
      chargeSessionQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment().startOf("month").toString())
      );
      chargeSessionQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment().endOf("month").toString())
      );
    } else if (dateFilter === "This Year") {
      chargeSessionQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment().startOf("year").toString())
      );
      chargeSessionQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment().endOf("year").toString())
      );
    }

    if (startDateFilter) {
      chargeSessionQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(moment(startDateFilter).startOf("day").toString())
      );
      chargeSessionQuery.lessThanOrEqualTo(
        "createdAt",
        new Date(moment(startDateFilter).endOf("day").toString())
      );
    }
    if (startDateFilter && endDateFilter) {
      chargeSessionQuery.greaterThanOrEqualTo(
        "createdAt",
        new Date(startDateFilter)
      );
      chargeSessionQuery.lessThanOrEqualTo(
        "createdAt",
        moment(endDateFilter).endOf("day").toDate()
      );
    }

    const chargeSessions = await chargeSessionQuery.find();

    const totalEnergy = chargeSessions.reduce(
      (acc: number, session: any) =>
        acc + (session.get("TotalEnergyConsumed") || 0),
      0
    );

    const totalCost = chargeSessions.reduce(
      (acc: number, session: any) => acc + (session.get("TotalCost") || 0),
      0
    );

    let status = "Inactive";
    let liveSessionLocation = null;
    let chargingCar = "";
    let meterStart = 0;
    let chargeRate = 0;
    let serialNumber = "";
    let liveCost: string | undefined = undefined;
    let ocppEnergy = null;
    let ocppDuration = null;
    let ocppCost = null;
    let liveSoc = null;

    if (chargeSessions.length > 0) {
      const liveSession = chargeSessions.find((session) => session.get("Live"));

      if (liveSession) {
        status = "Active";
        liveSessionLocation = liveSession?.get("Location")?.get("Name");
        chargingCar = liveSession?.get("Vehicle")?.get("Name");
        meterStart = liveSession?.get("MeterStart") || 0;
        serialNumber = liveSession?.get("ChargePoint")?.get("Serial");
        liveCost = await getActualCost(
          liveSession?.get("ChargePoint")?.get("Serial")
        );

        chargeRate = liveSession?.get("ChargeRate") || 0;

        const {
          energy,
          ocppCost: cost,
          ocppDuration: duration,
          liveSoc: soc,
        } = await getOcppData(
          liveSession.get("TransactionId"),
          user,
          liveSession.get("createdAt")
        );

        ocppEnergy = energy;
        ocppDuration = duration;
        ocppCost = cost;
        liveSoc = soc;
      }
    }
    let count = 0;
    // Return both live and non-live session data
    return {
      sNO: count++,
      id: user.id,
      name: user.get("FullName") || "",
      energy: totalEnergy,
      cost: totalCost,
      status: status,
      liveSessionLocation: liveSessionLocation,
      carName: chargingCar,
      meterStart: meterStart,
      ocppEnergy: ocppEnergy,
      liveSoc: liveSoc,
      ocppDuration: ocppDuration,
      ocppCost: ocppCost,
      serialNumber: serialNumber,
      liveCost: liveCost,
      chargeRate: chargeRate,
      isLive: status === "Active" ? true : false, // New property to identify live sessions
    };
  };

  // const fetchChargeSessionsForUser = async (
  //   user: any,
  //   dateFilter: string,
  //   startDateFilter?: string,
  //   endDateFilter?: string
  // ) => {
  //   console.log("usess api", user);

  //   const chargeSessionQuery = new Parse.Query("ChargeSession");
  //   chargeSessionQuery.equalTo("User", user);
  //   chargeSessionQuery.descending("createdAt");
  //   chargeSessionQuery.limit(5000);
  //   if (dateFilter === "This Week") {
  //     setDateFilterChips([{ id: "This Week", label: "This Week" }]);
  //   }

  //   let filteredData: any[] = [];

  //   if (dateFilter !== "This Week") {
  //     chargeSessionQuery.limit(5000);
  //   }

  //   if (dateFilter === "This Week") {
  //     chargeSessionQuery.greaterThanOrEqualTo(
  //       "createdAt",
  //       new Date(moment().startOf("week").toString())
  //     );
  //     chargeSessionQuery.lessThanOrEqualTo(
  //       "createdAt",
  //       new Date(moment().endOf("week").toString())
  //     );
  //   } else if (dateFilter === "Today") {
  //     chargeSessionQuery.greaterThanOrEqualTo(
  //       "createdAt",
  //       new Date(moment().startOf("day").toString())
  //     );
  //     chargeSessionQuery.lessThanOrEqualTo(
  //       "createdAt",
  //       new Date(moment().add(1, "day").startOf("day").toString())
  //     );
  //   } else if (dateFilter === "This Month") {
  //     chargeSessionQuery.greaterThanOrEqualTo(
  //       "createdAt",
  //       new Date(moment().startOf("month").toString())
  //     );
  //     chargeSessionQuery.lessThanOrEqualTo(
  //       "createdAt",
  //       new Date(moment().endOf("month").toString())
  //     );
  //   } else if (dateFilter === "This Year") {
  //     chargeSessionQuery.greaterThanOrEqualTo(
  //       "createdAt",
  //       new Date(moment().startOf("year").toString())
  //     );
  //     chargeSessionQuery.lessThanOrEqualTo(
  //       "createdAt",
  //       new Date(moment().endOf("year").toString())
  //     );
  //   }
  //   if (startDateFilter) {
  //     chargeSessionQuery.limit(1000);
  //     chargeSessionQuery.greaterThanOrEqualTo(
  //       "createdAt",
  //       new Date(moment(startDateFilter).startOf("day").toString())
  //     );
  //     chargeSessionQuery.lessThanOrEqualTo(
  //       "createdAt",
  //       new Date(moment(startDateFilter).endOf("day").toString())
  //     );
  //   }
  //   if (startDateFilter && endDateFilter) {
  //     chargeSessionQuery.limit(5000);
  //     chargeSessionQuery.greaterThanOrEqualTo(
  //       "createdAt",
  //       new Date(startDateFilter)
  //     );
  //     chargeSessionQuery.greaterThanOrEqualTo(
  //       "createdAt",
  //       new Date(startDateFilter)
  //     );
  //     const adjustedEndDate = moment(endDateFilter).endOf("day").toDate();
  //     chargeSessionQuery.lessThanOrEqualTo("createdAt", adjustedEndDate);
  //   }
  //   const chargeSessions = await chargeSessionQuery.find();
  //   const totalEnergy = chargeSessions.reduce(
  //     (acc: number, session: any) =>
  //       acc + (session.get("TotalEnergyConsumed") || 0),
  //     0
  //   );

  //   const totalCost = chargeSessions.reduce(
  //     (acc: number, session: any) => acc + (session.get("TotalCost") || 0),
  //     0
  //   );

  //   let status = "Inactive";
  //   let liveSessionLocation = null;
  //   let chargingCar = "";
  //   let meterStart = 0;
  //   let chargeRate = 0;
  //   let serialNumber = "";
  //   let liveCost: string | undefined = undefined;

  //   let ocppEnergy = null;
  //   let ocppDuration = null;
  //   let ocppCost = null;
  //   let liveSoc = null;

  //   if (chargeSessions.length > 0) {
  //     const liveSession = chargeSessions.find((session) => session.get("Live"));

  //     if (liveSession) {
  //       status = "Active";
  //       liveSessionLocation = liveSession?.get("Location")?.get("Name");
  //       chargingCar = liveSession?.get("Vehicle")?.get("Name");
  //       meterStart = liveSession?.get("MeterStart") || 0;
  //       serialNumber = liveSession?.get("ChargePoint")?.get("Serial");
  //       liveCost = await getActualCost(
  //         liveSession?.get("ChargePoint")?.get("Serial")
  //       );

  //       chargeRate = liveSession?.get("ChargeRate") || 0;

  //       // Call getOcppData for live session and use returned values
  //       const {
  //         energy,
  //         ocppCost: cost,
  //         ocppDuration: duration,
  //         liveSoc: soc,
  //       } = await getOcppData(
  //         liveSession.get("TransactionId"),
  //         user,
  //         liveSession.get("createdAt")
  //       );
  //       console.log("soc", soc);

  //       ocppEnergy = energy;
  //       ocppDuration = duration;
  //       ocppCost = cost;
  //       liveSoc = soc;
  //     }
  //   }

  //   return {
  //     id: user.id,
  //     name: user.get("FullName") || "",
  //     energy: totalEnergy,
  //     cost: totalCost,
  //     status: status,
  //     liveSessionLocation: liveSessionLocation,
  //     carName: chargingCar,
  //     meterStart: meterStart,
  //     ocppEnergy: ocppEnergy,
  //     liveSoc: liveSoc,
  //     ocppDuration: ocppDuration,
  //     ocppCost: ocppCost,
  //     serialNumber: serialNumber,
  //     liveCost: liveCost,
  //     chargeRate: chargeRate,
  //   };
  // };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [csvState, setCsvState] = useState<any>([]);
  const getAllFleetData = async (userId: any) => {
    setIsLoading(true);
    const parseQuery = new Parse.Query("FleetOperator");
    parseQuery.include("ActiveMembers");
    parseQuery.include("ChargePoint");
    parseQuery.limit(100);

    parseQuery.equalTo("objectId", userId);
    const result = await parseQuery.find();

    let newRow: any[] = [];

    for (const item of result) {
      const activeMembers = item.get("ActiveMembers") || [];

      const activeUsers = await Promise.all(
        activeMembers.map(async (user: any) => {
          console.log("usess", user);

          return await fetchChargeSessionsForUser(
            user,
            dateFilter,
            startDateFilter,
            endDateFilter
          );
        })
      );

      activeUsers.sort((a, b) => (a.status === "Active" ? -1 : 1));
      newRow.push({
        sNo: result.indexOf(item) + 1,
        id: item.id,
        activeMembers: activeUsers,
        pastMembers: item.get("PastMembers"),
        companyName: item.get("CompanyName"),
        tradeName: item.get("Name"),
        fleetCost: item.get("FleetCost"),
        companyAddress: item.get("Address"),
        contactName: item.get("ContactName"),
        contactNumber: item.get("Phone"),
        contactEmail: item.get("Email"),
        companyDescription: item.get("Description"),
        isWhiteLabel: item.get("isWhiteLabel") ? "true" : "false",
        accountName: item.get("AccountName") || "",
        accountNumber: item.get("AccountNumber") || "",
        bankName: item.get("BankName") || "",
        ifsc: item.get("IFSC") || "",
        upi: item.get("UPI") || "",
        upiCode: item.get("UPICode") || "",
        obj: item,
      });
    }

    setTableData(newRow);
    console.log("newRow[0]?.activeMembers", newRow[0]?.activeMembers);

    const uniqueActiveMembers = newRow[0]?.activeMembers.reduce(
      (acc: any[], current: any) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      },
      []
    );

    console.log("Unique Active Drivers", uniqueActiveMembers);

    // Map over uniqueActiveMembers to get only the unique driver IDs.
    const UserIds = uniqueActiveMembers.map((user: any) => user.id);
    console.log("uniqueActiveMembers getAllFleet", uniqueActiveMembers);

    setActiveMembers(uniqueActiveMembers);
    setCsvState(uniqueActiveMembers);
    setIsLoading(false);
  };

  const customFilename = () => {
    let filename = "Fleet Report";
    const today = moment().format("YYYY-MM-DD");

    if (startDateFilter) {
      const startDate = moment(startDateFilter).format("YYYY-MM-DD");
      if (endDateFilter) {
        const endDate = moment(endDateFilter).format("YYYY-MM-DD");
        filename += `_${startDate}_to_${endDate}`;
      } else {
        filename += `_${startDate}`;
      }
    } else {
      switch (dateFilter) {
        case "Today":
          filename += `_${today}`;
          break;
        case "Yesterday":
          const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
          filename += `_${yesterday}`;
          break;
        case "This Week":
          const last7Days = moment().subtract(7, "days").format("YYYY-MM-DD");
          filename += `_${last7Days}_to_${today}`;
          break;
        case "Last Month":
          const previousMonth = moment()
            .subtract(1, "month")
            .startOf("month")
            .format("YYYY-MM-DD");
          const endOfPreviousMonth = moment()
            .subtract(1, "month")
            .endOf("month")
            .format("YYYY-MM-DD");
          filename += `_${previousMonth}_to_${endOfPreviousMonth}`;
          break;
        case "This Year":
          const startOfYear = moment().startOf("year").format("YYYY-MM-DD");
          filename += `_${startOfYear}_to_${today}`;
          break;
        default:
          filename += `_All`;
      }
    }
    return `${filename}.xlsx`;
  };

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // Prepare summary data (without adding header again)
    const summaryData = [
      {
        Revenue: "₹" + billedRevenue?.total?.toFixed(2),
        "Energy Consumed": usage.total?.toFixed(2) + " kWh",
        "Total Sessions": chargeSession.total,
        "Fleet Driver Count": activeMembers?.length,
      },
    ];

    // Add summary header manually
    const summaryHeader = [
      // {
      //   Revenue: "Revenue",
      //   "Energy Consumed": "Energy Consumed",
      //   "Total Sessions": "Total Sessions",
      //   "Fleet Driver Count":"Total Drivers"
      // },
    ];

    // Combine header and data for the summary section
    const combinedSummaryData = [...summaryData];

    // Convert combined summary data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(combinedSummaryData);

    // Style the summary worksheet (optional)
    Object.keys(worksheet).forEach((cell) => {
      if (cell[0] === "A") {
        // 'A' refers to the first column
        worksheet[cell].s = {
          font: { bold: true },
        };
      }
    });

    // Convert filtered data with headers
    const filteredData = csvState.map((item: any) => ({
      // sNO:item.sNO,
      id: item.id,
      name: item.name,
      // cost: item.cost?.toFixed(2),
      cost: item.energy?.toFixed(2) * fleetCost,
      energy: item.energy?.toFixed(2),
    }));

    // Include headers for the filtered data
    const filteredDataWithHeaders = [
      {
        // sNO:"sNO",
        id: "ID",
        name: "Name",
        cost: "Cost",
        energy: "Energy",
      },
      ...filteredData,
    ];

    // Add filtered data starting from row A5 (after the summary table)
    XLSX.utils.sheet_add_json(worksheet, filteredDataWithHeaders, {
      skipHeader: true,
      origin: "A5", // Starts after the summary data
    });

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook to an Excel file
    XLSX.writeFile(workbook, customFilename());
  };

  useEffect(() => {
    console.log("selectedFleetId", selectedFleetId);
    setIsLoading(false);
    if (selectedFleetId) {
      setFleetAddData({ ...fleetAddData, selectedFleetId: selectedFleetId });
      getAllFleetData(selectedFleetId);
      setIsLoading(true);
    }
  }, [dateFilter, startDateFilter, endDateFilter, selectedFleetId]);

  // Get all fleet driver
  const getAllFleetDriverData = () => {
    const parseQuery = new Parse.Query("FleetOperator");
    parseQuery.include("User");
    parseQuery.include("ChargePoint");
    parseQuery.limit(100);
    parseQuery.find().then((result: any[]) => {
      let newRow: any[] = [];
      result.forEach((item, index) => {
        newRow.push({
          sNo: index + 1,
          id: item.id,
          activeDrivers: item.get("ActiveMembers"),
          tradeName: item.get("Name"),
          obj: item,
        });
      });
      setTableData(newRow);
    });
  };

  useEffect(() => {
    getAllFleetDriverData();
  }, []);

  const handleCloseModal = () => {
    setSelectedRowId(null);
    setOpenModal(false);
    setAllUsers([]);
    setError(null);
  };
  const handleDriverListCloseModal = () => {
    setOpenModalDriverList(false);
    setUserIds([]);
  };

  const handleRemoveDriver = () => {
    const userId = selectedFleetIdToRemove;
    const selectedFleetID = selectedFleetId;
    try {
      const params = {
        userId: userId, // selected user id
        fleetId: selectedFleetID, // fleet id for user
      };
      Parse.Cloud.run("delete_fleet_member", params).then(
        (status) => {
          alert("Fleet driver Has Been Removed");
          getAllFleetData(selectedFleetID);
          getWidgetsData();
          handleCloseConfirm();
        },
        (error: any) => {
          alert("Failed to Remove Fleet driver : " + error.message);
        }
      );
    } catch (error: any) {
      alert("Failed to Remove Fleet driver: " + error.message);
    }
  };

  const columns = [
    {
      field: "sNo",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          S.NO
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.sNo}</span>;
      },
      width: 60,
    },
    {
      field: "companyName",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Company Name
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.companyName}</span>;
      },
      width: 290,
      editable: true,
    },
    {
      field: "tradeName",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Trade Name
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.tradeName}</span>;
      },
      width: 180,
      editable: true,
    },

    {
      field: "companyAddress",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Address
        </span>
      ),
      renderCell: (params: any) => {
        return (
          <span className="font-lexend">{params?.row?.companyAddress}</span>
        );
      },
      width: 410,
      editable: true,
    },

    {
      field: "contactName",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Contact Name
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.contactName}</span>;
      },
      width: 180,
      editable: true,
    },
    {
      field: "contactNumber",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Contact Number
        </span>
      ),
      renderCell: (params: any) => {
        return (
          <span className="font-lexend">{params?.row?.contactNumber}</span>
        );
      },
      width: 180,
      editable: true,
    },
    {
      field: "contactEmail",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Contact Email
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.contactEmail}</span>;
      },
      width: 250,
      editable: true,
    },
    {
      field: "companyDescription",
      headerName: "Company Description",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Company Description
        </span>
      ),
      renderCell: (params: any) => {
        return (
          <span className="font-lexend">{params?.row?.companyDescription}</span>
        );
      },
      width: 250,
      editable: true,
    },
    {
      field: "Edit Fleet",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Actions
        </span>
      ),
      width: 260,
      renderCell: (params: any) => {
        return (
          <>
            <Tooltip title="Edit Fleet">
              <button
                style={{
                  background: "#1AC47D",
                  width: "35px",
                  height: "35px",
                  padding: "5px",
                  borderRadius: "50%",
                  border: "3px solid #1AAD70",
                  marginLeft: "9px",
                }}
                onClick={() => {
                  setShowAddEditModal(true);
                  setAddEditData({
                    id: params.row.id,
                    companyName: params.row.companyName,
                    tradeName: params.row.tradeName,
                    companyAddress: params.row.companyAddress,
                    contactName: params.row.contactName,
                    contactNumber: params.row.contactNumber,
                    contactEmail: params.row.contactEmail,
                    companyDescription: params.row.companyDescription,
                    isWhiteLabel: params.row.isWhiteLabel,
                    fleetCost : params?.row?.fleetCost,
                    accountName: params.row.accountName,
                    accountNumber: params.row.accountNumber,
                    bankName: params.row.bankName,
                    ifsc: params.row.ifsc,
                    upi: params.row.upi,
                    upiCode: params.row.upiCode,
                  });
                }}
              >
                <EditIcon />
              </button>
            </Tooltip>

            <Tooltip title="Add Fleet Driver">
              <button
                style={{
                  background: "#1AC47D",
                  width: "35px",
                  height: "35px",
                  padding: "5px",
                  borderRadius: "50%",
                  border: "3px solid #1AAD70",
                  marginLeft: "9px",
                }}
                className="text-white py-2 px-3 ml-4 rounded-md"
                onClick={() => handleOpenModal(params)}
              >
                <span>
                  <AddIcon />
                </span>
              </button>
            </Tooltip>

            <Tooltip title="View Fleet Driver">
              <button
                className="text-white py-2 px-3 ml-4 rounded-md"
                style={{ background: "#5096F1" }}
                onClick={() => handleOpenDriverListModal(params)}
              >
                <span className="flex items-center">
                  <VisibilityIcon sx={{ fontSize: "19px" }} />
                </span>
              </button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const [PhoneFilter, setPhoneFilter] = useState<any>("");

  const [dateRangeChipVisible, setDateRangeChipVisible] =
    useState<boolean>(false);
  const [SingleDateChips, setSingleDateFilterChips] = useState<string | null>(
    null
  );

  const [openCustom, setOpenCustom] = useState<boolean>(false);
  const dateRangeChange = (dates: any) => {
    const [start, end] = dates;
    setStartDateFilter(start);
    setEndDateFilter(end);
    setDateRangeChipVisible(!!start && !!end);
    setSingleDateFilterChips(start);
  };

  const handleDateChange = (value: string) => {
    if (value === "Custom") {
      setOpenCustom((prevOpenCustom) => !prevOpenCustom);
      setDateFilterChips([]);
      setDateFilter("");
    } else {
      setOpenCustom(false);
      setDateFilter(value);
      const selectedChip = {
        id: value,
        label:
          value === "Today"
            ? "Today"
            : value === "This Month"
            ? "This Month"
            : value === "This Week"
            ? "This Week"
            : "This Year",
      };
      setDateFilterChips([selectedChip]);
    }
  };

  const handleCustomChange = () => {
    setOpenCustom(!openCustom);
    setDateFilterChips([]);
    setDateFilter("");
  };

  // ! ||--------------------------------------------------------------------------------||
  // ! ||                     Function  Search User To assign fleet driver               ||
  // ! ||--------------------------------------------------------------------------------||
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value.length === 10) {
      setPhoneFilter(value);
    }
    // Basic phone number validation (you can customize this)
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(value)) {
      setError("Invalid phone number");
    } else {
      setError(null);
    }
  };

  const getUsers = async () => {
    const parseQuery = new Parse.Query("_User");
    parseQuery.descending("createdAt");
    parseQuery.include("EV", "RFID", "email");
    parseQuery.limit(5000);
    // parseQuery.notEqualTo("UserType", "Cloud");

    // Phone Filter
    if (PhoneFilter) {
      parseQuery.matches("Phone", new RegExp(PhoneFilter, "i"));
    }

    const result = await parseQuery.find();

    let newRow: any[] = [];
    result.forEach((item, index) => {
      newRow.push({
        sNo: index + 1,
        id: item.id,
        label: item.get("FullName"),
        obj: item,
      });
    });
    setAllUsers(newRow);
  };

  // handleAddDriverToFleet
  const handleAddDriverToFleet = async (data: any) => {
    console.log("Data userIds", data);
    console.log("selectedRowId", selectedFleetId);

    const userId = data?.id;
    const fleetID = selectedRowId || selectedFleetId;
    console.log("userIds", userIds);

    if (userIds.includes(userId)) {
      alert("This Member is already exist in the Fleet.");
      return; // Exit the function early
    }

    try {
      const params = {
        userId: userId, // selected user id
        fleetId: fleetID, // fleet id for user
      };
      Parse.Cloud.run("assign_fleet", params).then(
        (status) => {
          alert("Fleet driver Has Been Added");
          setUserIds((prevUserIds) => {
            // Add new userId to the previous userIds
            return [...prevUserIds, userId];
          });
          getAllFleetData(fleetID);
          getAllFleetDriverData();

          handleCloseModal();
        },
        (error: any) => {
          alert("Failed to Add Fleet driver : " + error.message);
        }
      );
    } catch (error: any) {
      alert("Failed to Add Fleet driver: " + error.message);
    }
  };

  // Widget code
  const [chargeDuration, setChargeDuration] = useState({
    thisMonth: 0,
    lastMonth: 0,
    total: null as string | null, // Ensure type accommodates your expected values
  });

  const secToMinute = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours} hrs, ${minutes} minutes`;
  };
  console.log("active", activeMembers);

  useEffect(() => {
    getWidgetsData();
  }, [userIds, dateFilter, startDateFilter, endDateFilter]);

  const handleDeleteDateChip = (chipId: string) => {
    const updatedChips = dateChips.filter((chip) => chip.id !== chipId);
    setDateFilterChips(updatedChips);
    setDateFilter("");
  };

  const handleSingleDeleteDateChip = (chipId: string) => {
    setStartDateFilter(null);
    setEndDateFilter(null);
    setSingleDateFilterChips(null);
  };

  const handleDateRangeDelete = () => {
    setStartDateFilter(null);
    setEndDateFilter(null);
    setDateRangeChipVisible(false);
  };

  const capitalizeFirstLetterOfEachWord = (str: any) => {
    // return str.replace(/\b\w/g, (char:any) => char.toUpperCase());
    return str
      .toLowerCase()
      .replace(/\b\w/g, (char: any) => char.toUpperCase());
  };

  const userColumns = [
    {
      field: "id",

      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Object ID
        </span>
      ),
      renderCell: (params: any) => {
        return <span className="font-lexend">{params?.row?.id}</span>;
      },
      width: 120,
    },

    {
      field: "name",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Name
        </span>
      ),
      width: 200,
      renderCell: (params: any) => {
        return (
          <span className="font-lexend font-semibold">
            {capitalizeFirstLetterOfEachWord(params?.row?.name)}
          </span>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Status
        </span>
      ),
      renderCell: (params: any) => {
        return !params?.row?.status ? (
          <Skeleton variant="text" width={100} />
        ) : (
          <div className="font-lexend">
            {params?.row?.status === "Inactive" && (
              <span className="font-lexend text-gray-500">
                {" "}
                <DriveEtaIcon sx={{ fontSize: "16px", color: "gray" }} /> On the
                road
              </span>
            )}
            {params?.row?.status === "Active" && (
              <div className="flex items-center">
                <span className="flex items-center">
                  <span>
                    {" "}
                    <svg
                      className=" mr-1 mt-1"
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3937_2347)">
                        <path
                          d="M3.78906 6.75C3.78906 6.35218 3.9471 5.97064 4.2284 5.68934C4.50971 5.40804 4.89124 5.25 5.28906 5.25H13.5391C13.9369 5.25 14.3184 5.40804 14.5997 5.68934C14.881 5.97064 15.0391 6.35218 15.0391 6.75V7.125C15.0391 7.22446 15.0786 7.31984 15.1489 7.39017C15.2192 7.46049 15.3146 7.5 15.4141 7.5C15.5135 7.5 15.6089 7.53951 15.6792 7.60983C15.7496 7.68016 15.7891 7.77554 15.7891 7.875V10.125C15.7891 10.2245 15.7496 10.3198 15.6792 10.3902C15.6089 10.4605 15.5135 10.5 15.4141 10.5C15.3146 10.5 15.2192 10.5395 15.1489 10.6098C15.0786 10.6802 15.0391 10.7755 15.0391 10.875V11.25C15.0391 11.6478 14.881 12.0294 14.5997 12.3107C14.3184 12.592 13.9369 12.75 13.5391 12.75H10.1641M5.28906 16.5V14.25M3.78906 11.25V9.375M6.78906 11.25V9.375M3.03906 11.25H7.53906V12.75C7.53906 13.1478 7.38103 13.5294 7.09972 13.8107C6.81842 14.092 6.43689 14.25 6.03906 14.25H4.53906C4.14124 14.25 3.75971 14.092 3.4784 13.8107C3.1971 13.5294 3.03906 13.1478 3.03906 12.75V11.25Z"
                          stroke="#1AC47D"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3937_2347">
                          <rect
                            width="18"
                            height="18"
                            fill="white"
                            transform="translate(0.789062)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>{" "}
                  <span
                    style={{ color: "#1AC47D" }}
                    className="font-lexend mt-1"
                  >
                    Charging
                  </span>{" "}
                </span>
                <span className="ml-3">
                  {params?.row?.liveSoc != undefined ? (
                    <CircularWithValueLabel
                      Soc={parseInt(params?.row?.liveSoc)}
                    />
                  ) : (
                    <span className="ml-4">-</span>
                  )}
                </span>
              </div>
            )}
          </div>
        );
      },
      width: 150,
      editable: false,
    },

    {
      field: "energy",
      headerName: "Energy",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Energy
        </span>
      ),
      renderCell: (params: any) => {
        const status = params?.row?.status;
        const cost = 18;
        const meterStart = params?.row?.meterStart;

        const energyConsumption = params?.row?.energy?.toFixed(2);
        const ocppEnergy = params?.row?.ocppEnergy;
        return isLoading ? (
          <Skeleton variant="text" width={50} />
        ) : (
          // <span className="font-lexend">{energyConsumption}</span>
          <span className="font-lexend">
            {status === "Active" ? (
              <span>
                {" "}
                {(params.row.ocppEnergy - meterStart / 1000).toFixed(2)}
              </span>
            ) : (
              <span>{energyConsumption}</span>
            )}
          </span>
        );
      },
      width: 150,
      editable: false,
    },

    {
      field: "Cost",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Cost
        </span>
      ),
      width: 130,
      renderCell: (params: any) => {
        console.log("FleetCost data",fleetCost);
        
        const status = params?.row?.status;
        const ChargingCost = params?.row?.energy?.toFixed(2) * fleetCost;
        // const cost = params?.row?.chargeRate;
        const liveCostString = params?.row?.liveCost; // This is your string
        const liveCostNumber = parseFloat(liveCostString); // Convert the string to a number
        const meterStart = params?.row?.meterStart;
        return isLoading ? (
          <Skeleton variant="text" width={50} />
        ) : (
          <span className="font-lexend">
            {status === "Active" ? (
              <span>
                {(
                  (params.row.ocppEnergy - meterStart / 1000) *
                  fleetCost // liveCostNumber
                ).toFixed(2)}
              </span>
            ) : (
              <span>{ChargingCost?.toFixed(2)}</span>
            )}
          </span>
        );
      },
      // Working code
      // renderCell: (params: any) => {
      //   const status = params?.row?.status;
      //   const ChargingCost = params?.row?.cost?.toFixed(2);
      //   // const cost = params?.row?.chargeRate;
      //   const liveCostString = params?.row?.liveCost; // This is your string
      //   const liveCostNumber = parseFloat(liveCostString); // Convert the string to a number
      //   const meterStart = params?.row?.meterStart;
      //   return isLoading ? (
      //     <Skeleton variant="text" width={50} />
      //   ) : (
      //     <span className="font-lexend">
      //       {status === "Active" ? (
      //         <span>
      //           {(
      //             (params.row.ocppEnergy - meterStart / 1000) *
      //             liveCostNumber
      //           ).toFixed(2)}
      //         </span>
      //       ) : (
      //         <span>{ChargingCost}</span>
      //       )}
      //     </span>
      //   );
      // },
      editable: false,
    },
    {
      field: "Edit Fleet",
      renderHeader: () => (
        <span
          style={{
            fontFamily: "lexend",
            fontSize: "16px",
            fontWeight: "600",
            color: "#949597",
          }}
        >
          Actions
        </span>
      ),
      width: 80,
      renderCell: (params: any) => {
        return (
          <>
            <Tooltip title="Remove">
              <button
                className="bg-red-500  border-2 border-red-700 p-1 rounded-full text-white"
                // onClick={() => handleRemoveDriver(params?.id)}
                onClick={() => handleConfirmRemoveDriver(params?.id)}
              >
                <RemoveIcon />
              </button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  console.log("activeMembers.length", activeMembers.length);

  return (
    <div className="booking-table mt-7">
      <DataGrid
        rows={dataRow}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[5]}
        checkboxSelection={false}
        disableSelectionOnClick
        autoHeight
        loading={props.loading}
      />
      <AddEditModal
        show={showAddEditModal}
        type="edit"
        handleClose={() => setShowAddEditModal(false)}
        refresh={() => props.refresh()}
        data={addEditData}
      />

      {/* <AddFleetDriver 
      show={openModal}
        type="edit"
        handleClose={() => setOpenModal(false)}
        refresh={() => props.refresh()}
        data={fleetAddData} 
        getAllFleetData={async () => getAllFleetData(fleetAddData.selectedFleetId)}
        /> */}

<Modal open={showConfirmModal} onClose={handleCloseConfirm}>
        <div
          style={{
            background: "white",
            position: "absolute",
            top: "25%",
            left: "28%",
            width: "330px",
            height: "180px",
          }}
        >
          <div className=" flex flex-col mt-3">
            <h1 className="mx-3 font-lexend text-xl font-medium mb-3">
              Fleet Driver Remove
            </h1>
            <h1 className="flex justify-center mx-3 font-lexend text-lg font-medium text-gray-500">
              Are you sure you want to remove the driver from the fleet?
            </h1>
            <div className="flex justify-around mt-5">
              <Button variant="contained" onClick={handleCloseConfirm}>
                <span className="font-lexend">NO</span>
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleRemoveDriver}
              >
                <span className="font-lexend">YES</span>{" "}
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal open={openModal} onClose={handleCloseModal}>
        <div
          style={{
            background: "white",
            position: "absolute",
            padding: "15px",
            top: "20%",
            left: "20%",
            width: "700px",
            height: "auto",
          }}
        >
          {/* Add your modal content here */}
          <div className="flex justify-between">
            <h3 className="font-lexend text-2xl mt-4 ml-4">Add Fleet Driver</h3>
            <CloseIcon
              sx={{ cursor: "pointer", margin: "15px 15px 0px 0px" }}
              onClick={handleCloseModal}
            />
          </div>
          <div className="flex mt-4">
            <div className="flex">
              <h1 className="font-lexend mr-4 mt-2 ml-4">Search User :</h1>
              <TextField
                id="outlined-basic"
                size="small"
                placeholder="Enter User Phone Number"
                variant="outlined"
                onChange={handlePhoneChange}
                error={Boolean(error)}
                helperText={error}
              />
            </div>

            <div>
              {PhoneFilter.length === 10 && (
                <button
                  className="ml-4 rounded-full"
                  style={{
                    display: "inline",
                    background: "#1AC47D",
                    border: "2px solid #1AAD70",
                    padding: error ? "8px" : "8px",
                  }}
                  onClick={getUsers}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 21L15 15M3 10C3 10.9193 3.18106 11.8295 3.53284 12.6788C3.88463 13.5281 4.40024 14.2997 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.8189 9.08075 17 10 17C10.9193 17 11.8295 16.8189 12.6788 16.4672C13.5281 16.1154 14.2997 15.5998 14.9497 14.9497C15.5998 14.2997 16.1154 13.5281 16.4672 12.6788C16.8189 11.8295 17 10.9193 17 10C17 9.08075 16.8189 8.1705 16.4672 7.32122C16.1154 6.47194 15.5998 5.70026 14.9497 5.05025C14.2997 4.40024 13.5281 3.88463 12.6788 3.53284C11.8295 3.18106 10.9193 3 10 3C9.08075 3 8.1705 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C4.40024 5.70026 3.88463 6.47194 3.53284 7.32122C3.18106 8.1705 3 9.08075 3 10Z"
                      stroke="#ffffff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
          {allUsers.length >= 1 && (
            <div
              style={{
                border: "1px solid lightgray",
                width: "70%",
                margin: "10px 15px",
              }}
              className="h-48 overflow-y-auto max-h-96 mt-4"
            >
              {/* Selected User Details */}

              <TableContainer sx={{ width: "98%", margin: "0 auto" }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontFamily: "lexend" }}>ID</TableCell>
                      <TableCell sx={{ fontFamily: "lexend" }}>Name</TableCell>
                      <TableCell sx={{ fontFamily: "lexend" }}>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allUsers.map((ele: any, index: any) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={{ fontFamily: "lexend", fontWeight: "700" }}
                        >
                          {ele?.id}
                        </TableCell>
                        <TableCell sx={{ fontFamily: "lexend" }}>
                          {ele?.label}
                        </TableCell>
                        <TableCell>
                          <button
                            className="ml-8 flex justify-around items-center w-max  py-1 rounded-full"
                            style={{
                              background: "#1AC47D",
                              border: "2px solid #1AAD70",
                            }}
                            onClick={() => handleAddDriverToFleet(ele)}
                          >
                            <span className="mr-2 ml-5 ">
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.75 9H11.25M9 6.75V11.25M2.25 9C2.25 9.88642 2.42459 10.7642 2.76381 11.5831C3.10303 12.4021 3.60023 13.1462 4.22703 13.773C4.85382 14.3998 5.59794 14.897 6.41689 15.2362C7.23583 15.5754 8.11358 15.75 9 15.75C9.88642 15.75 10.7642 15.5754 11.5831 15.2362C12.4021 14.897 13.1462 14.3998 13.773 13.773C14.3998 13.1462 14.897 12.4021 15.2362 11.5831C15.5754 10.7642 15.75 9.88642 15.75 9C15.75 8.11358 15.5754 7.23583 15.2362 6.41689C14.897 5.59794 14.3998 4.85382 13.773 4.22703C13.1462 3.60023 12.4021 3.10303 11.5831 2.76381C10.7642 2.42459 9.88642 2.25 9 2.25C8.11358 2.25 7.23583 2.42459 6.41689 2.76381C5.59794 3.10303 4.85382 3.60023 4.22703 4.22703C3.60023 4.85382 3.10303 5.59794 2.76381 6.41689C2.42459 7.23583 2.25 8.11358 2.25 9Z"
                                  stroke="white"
                                  stroke-width="1.6"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span>
                            <span style={{ color: "white" }} className="mr-5">
                              <span className="font-lexend ">Add</span>
                            </span>
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </Modal>

      {/* Driver List */}
      <Modal open={openModalDriverList} onClose={handleDriverListCloseModal}>
        <div
          style={{
            background: "white",
            position: "absolute",
            top: "2%",
            left: "6%",
            right: "5%",
            width: "90%",
            height: "94%",
            overflowY: "auto",
          }}
        >
          <div className="transaction_table_container flex">
            <div style={{ width: "100%" }}>
              <div className="flex justify-between mx-6 my-5 ">
                <h2 className="transaction_heading mt-2 font-lexend font-semibold text-2xl">
                  Fleet Drivers
                </h2>

                <div className="flex justify-around items-center">
                  <Tooltip title="Add Fleet Driver">
                    <button
                      style={{
                        padding: "8px 8px",
                      }}
                      className="filter_button bg-green-400 rounded-full border-2 border-green-600"
                      onClick={handleOpenModal}
                    >
                      <AddCircleOutlineIcon
                        sx={{ height: "24px", color: "white" }}
                      />
                    </button>
                  </Tooltip>

                  <Tooltip title="Download Excel Report">
                    <button
                      style={{
                        padding: "11px 13px",
                      }}
                      className="filter_button bg-blue-200 rounded-full border-2 border-blue-300 mx-2"
                      onClick={exportToExcel}
                    >
                      <svg
                        width="16"
                        height="20"
                        viewBox="0 0 16 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 3H3C2.46957 3 1.96086 3.21071 1.58579 3.58579C1.21071 3.96086 1 4.46957 1 5V17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19H13C13.5304 19 14.0391 18.7893 14.4142 18.4142C14.7893 18.0391 15 17.5304 15 17V5C15 4.46957 14.7893 3.96086 14.4142 3.58579C14.0391 3.21071 13.5304 3 13 3H11M5 3C5 2.46957 5.21071 1.96086 5.58579 1.58579C5.96086 1.21071 6.46957 1 7 1H9C9.53043 1 10.0391 1.21071 10.4142 1.58579C10.7893 1.96086 11 2.46957 11 3M5 3C5 3.53043 5.21071 4.03914 5.58579 4.41421C5.96086 4.78929 6.46957 5 7 5H9C9.53043 5 10.0391 4.78929 10.4142 4.41421C10.7893 4.03914 11 3.53043 11 3M5 15V10M8 15V14M11 15V12"
                          stroke="#354052"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </Tooltip>

                  <Tooltip title="Filter">
                    <button
                      className="filter_button rounded-full p-3 border-2 border-black-600"
                      onClick={handleFilterButtonClick}
                    >
                      <FilterIcon />
                    </button>
                  </Tooltip>
                </div>
              </div>

              <div className="filter-chips-container flex flex-wrap ml-4">
                {/* User Filter */}
                {/* Single Date Chip */}
                {dateChips.map((chip) => (
                  <Chip
                    key={chip.id}
                    label={chip.label}
                    onDelete={() => handleDeleteDateChip(chip.id)}
                    variant="outlined"
                    sx={{ marginLeft: "5px", marginBottom: "10px" }}
                  />
                ))}
                {/* Range Date Chip */}
                {dateRangeChipVisible && (
                  <Chip
                    label={`Date Between : ${moment(startDateFilter).format(
                      "DD-MM-YYYY"
                    )} - ${moment(endDateFilter).format("DD-MM-YYYY")}`}
                    onDelete={handleDateRangeDelete}
                    variant="outlined"
                    sx={{ marginLeft: "5px" }}
                  />
                )}
                {/* Handle range Date Chip when deleted Single Date chip shows this prevent that bug */}
                {!dateRangeChipVisible &&
                  SingleDateChips &&
                  startDateFilter && (
                    <Chip
                      label={`Date : ${moment(startDateFilter).format(
                        "DD-MM-YYYY"
                      )}`}
                      onDelete={handleSingleDeleteDateChip}
                      variant="outlined"
                      sx={{ marginLeft: "5px", marginBottom: "10px" }}
                    />
                  )}
              </div>

              {/* Widget code start from here */}

              <div className="flex">
                <div className="ml-2">
                  <Widget type="billedRevenue" data={billedRevenue} />
                </div>
                <div className="ml-2">
                  <Widget type="usage" data={usage} />
                </div>
                <div className="ml-2">
                  {" "}
                  <Widget type="chargeSession" data={chargeSession} />
                </div>
                <div className="ml-2">
                  <Widget type="ChargingDuration" data={chargeDuration} />
                </div>
                <div className="ml-2">
                  <Widget type="FleetDriverCount" data={activeMembers.length} />
                </div>
              </div>

              {/* Table code */}
              <div style={{ width: "96%", margin: "12px auto" }}>
                {activeMembers?.length > 0 ? (
                  <DataGrid
                    rows={activeMembers}
                    columns={userColumns}
                    rowHeight={75}
                    pageSize={100}
                    rowsPerPageOptions={[5]}
                    checkboxSelection={false}
                    disableSelectionOnClick
                    autoHeight
                    hideFooterPagination
                    hideFooter
                  />
                ) : (
                  <h2 className="font-lexend text-lg text-gray-700 border-dotted border-2 border-gray-300 px-2 py-3 text-center">
                    No Fleet Driver Found
                  </h2>
                )}
              </div>
            </div>
            {/* Filter Code */}
            {isFilterVisible && (
              <div
                style={{ width: "330px" }}
                className="filters lg:w-12/12 flex flex-col  border-2 border-black-400"
              >
                <div className="filter_header z-50 sticky top-0 right-0 flex justify-between px-4 py-5 mb-8">
                  <h1 className="font-semibold font-lexend text-lg">Filters</h1>
                  <button
                    style={{
                      background: "#1AC47D",
                      border: "2px solid #18a369",
                      padding: "11px",
                      borderRadius: "50%",
                    }}
                    onClick={handleFilterButtonClick}
                  >
                    <CloseFilterIcon />
                  </button>
                </div>

                <div className="filter_main_container px-4">
                 
                  <div className="-mt-4">
                    <h1 className="font-semibold font-lexend text-lg">Date</h1>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={dateFilter === "Today"}
                        onChange={() => handleDateChange("Today")}
                      />
                      <label htmlFor="" className="font-lexend">
                        Today
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={dateFilter === "This Week"}
                        onChange={() => handleDateChange("This Week")}
                      />
                      <label htmlFor="" className="font-lexend">
                        This Week
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={dateFilter === "This Month"}
                        onChange={() => handleDateChange("This Month")}
                      />
                      <label htmlFor="" className="font-lexend">
                        This Month
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        className="mr-3"
                        checked={dateFilter === "This Year"}
                        onChange={() => handleDateChange("This Year")}
                      />
                      <label htmlFor="" className="font-lexend">
                        This Year
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        className="rounded-checkbox accent-black-500 mr-3"
                        checked={dateFilter === "Custom"}
                        onChange={handleCustomChange}
                      />
                      <label htmlFor="" className="font-lexend">
                        Custom Date
                      </label>
                    </div>
                  </div>

                  {openCustom && (
                    <div className="dateSec mb-4">
                      <h1 className="mt-4 mb-3 font-lexend">Custom</h1>
                      <div className="start_dateContainer mt-4">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <MuiDatePicker
                            label="Start Date"
                            value={startDateFilter}
                            onChange={dateRangeChange}
                            disabled
                            className="mt-4"
                            renderInput={(params) => (
                              <TextField {...params} error={false} />
                            )}
                            inputFormat="DD-MM-YYYY"
                          />
                        </LocalizationProvider>
                      </div>
                      <div className="endDate_container mt-4">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <MuiDatePicker
                            label="End Date"
                            disabled
                            value={endDateFilter}
                            onChange={(item) => {
                              setEndDateFilter(item || "");
                              setDateRangeChipVisible(
                                !!startDateFilter && !!item
                              );
                            }}
                            renderInput={(params) => (
                              <TextField {...params} error={false} />
                            )}
                            inputFormat="DD-MM-YYYY"
                          />
                        </LocalizationProvider>
                      </div>

                      <div className="rangePicker_container mt-4">
                        <DatePicker
                          selected={startDateFilter}
                          onChange={dateRangeChange}
                          startDate={startDateFilter}
                          endDate={endDateFilter}
                          peekNextMonth
                          showMonthDropdown // month dropdown
                          showYearDropdown // year dropdown
                          dropdownMode="select"
                          minDate={new Date(2022, 0, 1)}
                          maxDate={new Date()}
                          selectsRange
                          inline
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default DatabaseTable;
