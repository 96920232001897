import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../components/sidebar";
import "./protectedroutes.scss";
const ProtectedRoute = (props: any) => {
  const { Component } = props;

  const navigate = useNavigate();
  const location = useLocation();


  // useEffect(() => {
  //   const checkAccess = async () => {
  //     const user = Parse.User.current();
  //     console.log("Current User data route", user);

  //     if (!user) {
  //       navigate("/");
  //       return;
  //     }

  //     try {
  //       const session = await Parse.Session.current();
  //       if (!session.id) {
  //         await Parse.User.logOut();
  //         navigate("/");
  //         return;
  //       }

  //       // Normalize roles and current page for case-insensitive comparison
  //       const userRoles = (user.get("RoleAssigned") || []).map((role: string) => role.toLowerCase());
  //       const currentPage = location.pathname.replace("/", "").toLowerCase();
  //       console.log("Normalized userRoles:", userRoles);
  //       console.log("Normalized currentPage:", currentPage);

  //       if (!userRoles.includes(currentPage)) {
  //         alert("You do not have access to this page.");
  //         navigate("/"); // Redirect to a default page
  //       }
  //       if (userRoles.includes("fleetOperator")) {
  //         navigate("/fleet"); // Redirect to the fleet page
  //       }
        
  //     } catch (error) {
  //       console.error("Error checking access:", error);
  //       navigate("/");
  //     }
  //   };

  //   checkAccess();
  // }, [navigate, location]);

  useEffect(() => {
    let user = Parse.User.current();
    console.log("Current User data route  ", user);
    
    if (user) {
      Parse.Session.current().then((session) => {
        if (!session.id) {
          Parse.User.logOut().then(() => {
            navigate("/");
          });
        }
      });
    } else {
      navigate("/");
    }
  }, []);
  return (
    <React.Fragment>
      <div className="side-bar">
        <Sidebar />

        <Component />
      </div>
    </React.Fragment>
  );
};
export default ProtectedRoute;
